import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UsersIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";

import AppointmentsTable from "../components/appointments/AppointmentsTable";
import PageTitle from "../components/common/PageTitle";
import { getPatientById } from "../http/api/patient";
import BasicCard from "../components/common/BasicCard";
import CustomPagination from "../components/common/CustomPagination";
import { setLoaderStatus } from "../redux/loader/slice";
import PatientHeader from "../components/patients/PatientHeader";
import { getAppointmentsPatient } from "../http/api/appointment";

const PatientAppointmentsPage = ({ location, history }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [patient, setPatient] = useState();
  const [appointments, setAppointments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    const getPatient = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getPatientById(id);
        setPatient(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatient();
  }, [dispatch, id]);

  useEffect(() => {
    const getAppointments = async () => {
      try {
        dispatch(setLoaderStatus(true));
        let params = queryString.parse(location.search);
        const { data: {data, current_page, per_page, total} } = await getAppointmentsPatient(id, params);
        setAppointments(data);
        setActivePage(current_page);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getAppointments();
  }, [dispatch, id, location.search]);

  
  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Historial citas medicas" Icon={UsersIcon} />
      <BasicCard>
        {patient && 
          <PatientHeader
            name={patient?.pa_name}
            clinicalHistory={patient?.clinical_history?.ch_code}
            typeDocument={patient?.document_type?.dt_code}
            document={patient?.pa_document}
          />
        }
        <AppointmentsTable appointments={appointments} />
        <CustomPagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onChange={(page) => onChangePagination(page)}
        />
      </BasicCard>
    </div>
  );
};

export default withRouter(PatientAppointmentsPage);
