import React from 'react';
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {FilterIcon} from "@heroicons/react/solid";
import CustomTextInput from "../../common/CustomTextInput";

const PatientOrdersFiltersForm = ({onSubmit, schemaValidation}) => {
  const { register, handleSubmit, formState:{ errors } } = useForm();

  return(
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-2">
        <div className="mb-2 md:mb-0">
          <CustomTextInput
            {...register("orderId", schemaValidation?.filter)}
            type="text"
            name="orderId"
            id="orderId"
            errors={errors}
            placeholder="Buscar"
            label='Numero de la orden'
          />
        </div>
        <div className="self-end mb-2 md:mb-0">
          <button
            type="submit"
            className="btn-secondary-dark"
          >
            <FilterIcon className="-ml-0.5 mr-2 h-4 w-4"/>
            Buscar
          </button>
        </div>
      </div>
    </form>
  )
}

PatientOrdersFiltersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  schemaValidation: PropTypes.object
}

PatientOrdersFiltersForm.defaultProps = {
  schemaValidation: {
    orderId: {required: 'Requerido'},
  }
}

export default PatientOrdersFiltersForm;
