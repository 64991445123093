import React from "react";
import {Controller, useForm} from "react-hook-form";
import CustomTextInput from "../../../common/CustomTextInput";
import NumberFormat from "react-number-format";

const AddQuotaForm = ({onSubmit}) => {
  const { handleSubmit, control, setValue, formState:{ errors } } = useForm();

  return(
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <Controller
          render={({ field }) => <NumberFormat
            {...field}
            thousandSeparator={true}
            customInput={CustomTextInput}
            label="Valor*"
            errors={errors}
            onValueChange={(values) => {
              const {value} = values;
              setValue('value', value)
            }}
          />}
          rules={{
            required: 'Requerido',
            min: { value: 1, message: 'La cuota no puede ser 0' },
          }}
          name="formatValue"
          id="formatValue"
          control={control}
        />
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="btn-secondary-dark"
        >
          Guardar
        </button>
      </div>
    </form>
  );
}

export default AddQuotaForm;
