import {Fragment} from 'react'
import PropTypes from "prop-types";
import {Menu, Transition} from "@headlessui/react";
import DotsVerticalIcon from "@heroicons/react/outline/DotsVerticalIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MenuDropDown = ({options}) => {
  if (!options || options.length < 1) {
    return null
  };
  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-tertiary rounded-full flex items-center text-gray-400 hover:text-gray-600 np">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1 divide-y divide-gray-200">
                {options && options.map((option, index) =>
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block w-full text-left px-4 py-2 text-sm'
                        )}
                        onClick={option.onClick}
                      >
                        {option.text}
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

MenuDropDown.propTypes = {
  options: PropTypes.array
}

MenuDropDown.defaultProps = {
  options: []
}

export default MenuDropDown;
