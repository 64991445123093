import React from 'react';
import PropTypes from "prop-types";
import {TrashIcon} from "@heroicons/react/solid";
import Table from "../../common/Table";

const OrderItemsTable = ({items, onDelete}) => {
  if(items.length < 1) {
    return null;
  }
  const numberFormat = new Intl.NumberFormat();

  const columns = [
    {
      Header: 'Producto',
      accessor: 'productName',
    },
    {
      Header: 'Cantidad',
      accessor: 'quantity'
    },
    {
      Header: 'Valor unidad',
      accessor: 'unitValue',
      Cell: ({ row:{original} }) => `$ ${numberFormat.format(original.unitValue)}`
    },
    {
      Header: 'Subtotal',
      accessor: 'subtotal',
      Cell: ({ row:{original} }) => `$ ${numberFormat.format(original.subtotal)}`
    },
    {
      Header: 'Tipo de descuento',
      accessor: 'discountType'
    },
    {
      Header: 'Descuento',
      accessor: 'discount',
      Cell: ({ row:{original} }) => {
        let value = '';
        if(original.discountType === 'porcentaje'){
          value = `${numberFormat.format(original.discount)} %`;
        } else if(original.discountType === 'valor') {
          value = `$ ${numberFormat.format(original.discount)}`;
        }

        return value
      }
    },
    {
      Header: 'Valor Total',
      accessor: 'total',
      Cell: ({ row:{original} }) => `$ ${numberFormat.format(original.total)}`
    },
    {
      Header: '',
      accessor: '[deleteButton]',
      Cell: ({ row }) => <button type="button" onClick={() => onDelete(row)}>
        <TrashIcon className="h-4 w-4 text-red-600"  aria-hidden="true"/>
      </button>
    }
  ]

  return (
    <div className="table-responsive">
      <Table columns={columns} data={items} />
    </div>
  )

}

OrderItemsTable.propTypes = {
  items: PropTypes.array,
  onDelete: PropTypes.func
}

OrderItemsTable.defaultProps = {
  items: []
}

export default OrderItemsTable;
