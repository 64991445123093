import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import CustomModalContent from "../../common/CustomModalContent";
import { getOrdersPayment } from "../../../http/api/orders";
import PaymentForm from "../payment/PaymentForm";
import PatientPaymentsTable from "./PatientPaymentsTable";
import usePatientBalance from "../../../hooks/usePatientBalance";
import { paymentInvoicePdf } from "../../reports/paymentInvoicePdf";
import { PAYMENT_TREATMENT } from "../../../constants/paymentsCodes";
import PatientPaymentsFiltersForm from "./PatientPaymentsFiltersForm";
import CustomPagination from "../../common/CustomPagination";
import useGetAllSpecialists from "../../../hooks/useGetAllSpecialists";
import useGetAllPaymentsMethods from "../../../hooks/useGetAllPaymentsMethods";
import useGetAllPaymentsConcepts from "../../../hooks/useGetAllPaymentsConcepts";
import { setLoaderStatus } from "../../../redux/loader/slice";
import { successAlert } from "../../../helpers/alerts";
import {
  createExtraordinaryPayment,
  getPaymentPrintData,
  getPaymentsByPatient,
} from "../../../http/api/payment";
import PatientHeader from "../../patients/PatientHeader";
import PermissionWrapper from "../../common/PermissionWrapper";
import { CREATE_PAYMENT } from "../../../constants/permissions";

const numberFormat = new Intl.NumberFormat();

const PatientPayments = ({ patient }) => {
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [payment] = useState({});
  const [payments, setPayments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [patientOrders, setPatientOrders] = useState([]);
  const [orderIdFilter, setOrderIdFilter] = useState();
  const [toggleSearch, setToggleSearch] = useState();

  const dispatch = useDispatch();
  const balance = usePatientBalance(patient.id);
  const paymentMethods = useGetAllPaymentsMethods();
  const paymentConcepts = useGetAllPaymentsConcepts();
  const specialists = useGetAllSpecialists();

  useEffect(() => {
    const getPayments = async () => {
      const params = {
        page: activePage,
        order_id: orderIdFilter,
      };
      try {
        dispatch(setLoaderStatus(true));
        const {
          data: { data, perPage, total },
        } = await getPaymentsByPatient(patient.id, params);
        setPayments(data);
        setItemsCountPerPage(perPage);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPayments(patient.id, orderIdFilter);
  }, [patient, activePage, orderIdFilter, toggleSearch, dispatch]);

  useEffect(() => {
    const getOrders = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data: orders } = await getOrdersPayment(patient.id);
        setPatientOrders(orders);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getOrders(patient.id);
  }, [dispatch, patient]);

  const handleOpenModalPayment = () => setOpenModalPayment(true);
  const handleCloseModalPayment = () => setOpenModalPayment(false);

  const search = (data) => {
    setOrderIdFilter(data.orderId);
  };

  const handlePrint = async (data) => {
    try {
      dispatch(setLoaderStatus(true));
      const { data: printData } = await getPaymentPrintData(data.id);
      paymentInvoicePdf(printData);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  const savePayment = async (data) => {
    try {
      dispatch(setLoaderStatus(true));
      const { message } = await createExtraordinaryPayment({
        order_id: data?.orderId,
        specialist_id: data?.specialistId,
        amount: data?.amount,
        payment_method_id: data?.paymentMethodId,
        payment_concept_id: data?.paymentConceptId,
        observations: data?.observations,
      });
      successAlert(message);
      handleCloseModalPayment();
      setToggleSearch((prevState) => !prevState);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div>
      <div className="pb-4">
        <PatientHeader
          name={patient?.pa_name}
          clinicalHistory={patient?.clinical_history?.ch_code}
          typeDocument={patient?.document_type?.dt_code}
          document={patient?.pa_document}
          balance={numberFormat.format(balance)}
        />
      </div>
      <div className="pb-2 grid grid-cols-1 md:grid-cols-3">
        <div className="self-end md:mb-0 mb-2 col-span-2">
          <PatientPaymentsFiltersForm onSubmit={search} />
        </div>
        <div className="self-end flex justify-end">
          <PermissionWrapper permission={CREATE_PAYMENT}>
            <button
              type="button"
              className="btn-secondary-dark"
              onClick={handleOpenModalPayment}
            >
              Registrar Abono
            </button>
          </PermissionWrapper>
          <CustomModalContent
            open={openModalPayment}
            onClose={handleCloseModalPayment}
            title="Registrar abono"
            closeOnOverlayClick={false}
          >
            <PaymentForm
              payment={payment}
              patient={patient}
              paymentMethods={paymentMethods}
              specialists={specialists}
              patientOrders={patientOrders}
              onSubmit={savePayment}
              paymentConcepts={paymentConcepts.filter(
                (pc) => pc.pc_code === PAYMENT_TREATMENT
              )}
            />
          </CustomModalContent>
        </div>
      </div>
      <PatientPaymentsTable payments={payments} onPrint={handlePrint} />
      <div className="flex justify-center mt-4">
        <CustomPagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onChange={(page) => setActivePage(page)}
        />
      </div>
    </div>
  );
};

PatientPayments.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientPayments;
