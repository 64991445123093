import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";

import PageTitle from "../components/common/PageTitle";
import { DocumentReportIcon } from "@heroicons/react/solid";
import { ReportPatientsForm } from "../components/reports/ReportPatientsForm";
import { getPatientsReport } from "../http/api/reports";
import { setLoaderStatus } from "../redux/loader/slice";

const ReportPatientsPage = () => {
  const dispatch = useDispatch();

  const generateReport = async (formData) => {
    try {
      dispatch(setLoaderStatus(true));
      const params = {
        from: moment(`${formData.from}`)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        to: moment(`${formData.to}`).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        report: formData.report,
      };
      const data = await getPatientsReport(params);
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `report.xlsx`;
      link.click();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Informes" Icon={DocumentReportIcon} />
      <div className="md:grid md:grid-cols-3 md:gap-6 mt-10">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Reporte de pacientes
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              Selecciona la fecha inicial y final y el tipo de reporte que
              deseas generar.
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2 bg-white sm:rounded-md">
          <ReportPatientsForm generateReport={generateReport} />
        </div>
      </div>
    </div>
  );
};

export default ReportPatientsPage;
