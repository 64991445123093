import {useState} from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import {getCurrentConfig, getCurrentUser, getPermissions} from '../../redux/session/selectors';
import { connect } from 'react-redux';
import { logout } from '../../redux/session/thunk';


const DashboardLayout = ({user, config, logout, permissions, children}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} config={config} permissions={permissions} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Header setSidebarOpen={setSidebarOpen} user={user} config={config} />
        <main className="flex-1 relative overflow-y-auto focus:outline-none bg-tertiary">
          {children}
        </main>
        <footer className="hidden md:block" >
          <Footer />
        </footer>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  permissions: getPermissions(state),
  user: getCurrentUser(state),
  config: getCurrentConfig(state)
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => {dispatch(logout())}
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLayout);

