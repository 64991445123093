import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { searchProducts } from "../../http/api/products";
import EntitySelector from "../common/EntitySelector";
import ProductsFiltersForm from "./ProductsFiltersForm";
import CustomPagination from "../common/CustomPagination";
import { setLoaderStatus } from "../../redux/loader/slice";

const numberFormat = new Intl.NumberFormat();

const ProductBrowser = ({ onSelectProduct }) => {
  const [products, setProducts] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [searchBy, setSearchBy] = useState("");

  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Codigo",
      accessor: "pr_code",
    },
    {
      Header: "Producto",
      accessor: "pr_name",
    },
    {
      Header: "Precio",
      accessor: "[pr_value]",
      Cell: ({ row: { original } }) =>
        `$ ${numberFormat.format(original.pr_value)}`,
    },
  ];

  useEffect(() => {
    const search = async () => {
      try {
        dispatch(setLoaderStatus(true));
        let params = { page: activePage };
        if (searchBy !== "" && filter !== "") {
          params[searchBy] = filter;
          const {
            data: { data, per_page, total },
          } = await searchProducts(params);
          setProducts(data);
          setItemsCountPerPage(per_page);
          setTotalItemsCount(total);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    search();
  }, [filter, searchBy, activePage, dispatch]);

  const onSelect = (product) => {
    onSelectProduct(product);
  };

  const onSubmit = async (formData) => {
    setFilter(formData["filter"]);
    setSearchBy(formData["searchBy"]);
  };

  return (
    <>
      <div className="mb-4">
        <ProductsFiltersForm onSubmit={onSubmit} />
      </div>
      {products.length > 0 && (
        <div>
          <EntitySelector
            columns={columns}
            entities={products}
            onSelect={onSelect}
          />
          {totalItemsCount > itemsCountPerPage && (
            <CustomPagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              onChange={(page) => setActivePage(page)}
            />
          )}
        </div>
      )}
    </>
  );
};

ProductBrowser.propTypes = {
  onSelectProduct: PropTypes.func.isRequired,
};

export default ProductBrowser;
