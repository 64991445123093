const Footer = () => {
    return (
      <div className="bg-tertiary">
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col justify-center">
          <img src="/logo.png" alt="Luisa tu asistente" className="w-20"/>
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2021 - Luisa Tu Asistente </p>
        </div>
      </div>
    )
}

export default Footer;
