import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { getToken } from "../redux/session/selectors";
import { connect } from "react-redux";

const LoginRoute = ({ component: Component, token, ...rest }) => {
  const auth = !!token;
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

LoginRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  auth: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  token: getToken(state),
});

export default connect(mapStateToProps)(LoginRoute);
