import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const consultationResumePdf = ({
  logo,
  paName,
  paDocument,
  consultations,
}) => {
  const clinicalHistoryDoc = [];
  const currentDate = new Date();
  const options = { year: "numeric", month: "2-digit", day: "numeric" };
  clinicalHistoryDoc.push({
    text: "Resumen de atenciones",
    style: "header",
    alignment: "center",
  });
  clinicalHistoryDoc.push({
    table: {
      body: [
        [{ text: "Paciente:", bold: true }, paName],
        [{ text: "Documento de identidad:", bold: true }, paDocument],
        [{ text: "No. Historia:", bold: true }, paDocument],
      ],
    },
    style: "marginButtonParagraph",
    layout: "noBorders",
  });
  clinicalHistoryDoc.push({
    text: [
      { text: "Fecha: ", bold: true },
      { text: currentDate.toLocaleDateString("es-CO", options) },
    ],
    style: "marginButtonHalfParagraph",
  });
  consultations.forEach((item) => {
    clinicalHistoryDoc.push({
      text: [{ text: "Fecha: ", bold: true }, { text: item.co_created_at }],
      style: "marginLeft",
    });
    clinicalHistoryDoc.push({
      text: [
        { text: "Procedimiento: ", bold: true },
        { text: item.co_process },
      ],
      style: "marginLeft",
    });
    clinicalHistoryDoc.push({
      text: [
        { text: "Diagnostico: ", bold: true },
        { text: item.co_diagnostic },
      ],
      style: "marginLeft",
    });
    clinicalHistoryDoc.push({
      text: [
        { text: "Motivo de consulta: ", bold: true },
        { text: item.co_reason },
      ],
      style: "marginLeft",
    });
    clinicalHistoryDoc.push({
      text: [
        { text: "Examen Fisico: ", bold: true },
        { text: item.co_physical_exam },
      ],
      style: ["marginConsultationParagraph"],
    });
  });

  const docDefinition = {
    header: [
      {
        image: "logo",
        width: 120,
        height: 100,
        margin: [40, 10, 200, 0],
      },
    ],
    content: clinicalHistoryDoc,
    images: {
      logo,
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "justify",
        margin: [0, 30],
      },
      marginLeft: {
        margin: [8, 0, 0, 0],
      },
      marginConsultationParagraph: {
        margin: [8, 0, 0, 20],
      },
      marginButtonParagraph: {
        margin: [0, 0, 0, 20],
      },
      marginButtonHalfParagraph: {
        margin: [0, 0, 0, 5],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download(`Historia ${paDocument}`);
};
