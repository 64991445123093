import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {FilterIcon} from "@heroicons/react/solid";
import CustomTextInput from "../common/CustomTextInput";

const InsurersFiltersForm = ({onSubmit}) => {
  const { register, handleSubmit} = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2">
        <div className="mb-2 md:mb-0">
          <CustomTextInput
            {...register("searchByString")}
            type="text"
            name="searchByString"
            id="searchByString"
            label="Nombre"
            placeholder="Filtrar por Nombre"
          />
        </div>
        <div className="self-end mb-2 md:mb-0">
          <button type="submit" className="btn-secondary-dark">
            <FilterIcon className="-ml-0.5 mr-2 h-4 w-4" />
            Buscar
          </button>
        </div>
      </div>
    </form>
  );
}

InsurersFiltersForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default InsurersFiltersForm;
