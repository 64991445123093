import PropTypes from "prop-types";
import useCheckPermission from "../../hooks/useCheckPermission";

const PermissionWrapper = ({ permission, children }) => {
  const hasPermission = useCheckPermission(permission);

  if (!hasPermission) {
    return null;
  }

  return children;
};

PermissionWrapper.propTypes = {
  permission: PropTypes.string.isRequired,
};

export default PermissionWrapper;
