import {useState, useEffect} from "react";
import store from "../redux/store";
import {getPermissions} from "../redux/session/selectors";

function useCheckPermission(permissionName) {
  const [match, setMatch] = useState(false);

  useEffect(() => {
    const state = store.getState();
    const permissions = getPermissions(state);

    setMatch(permissions.includes(permissionName))
  }, [permissionName])

  return match;
}

export default useCheckPermission;
