import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Table from "../common/Table";
import useCheckPermission from "../../hooks/useCheckPermission";
import PatientBadgeAlert from "../common/PatientBadgeAlert";
import {
  ACCESS_PATIENT_ORDERS_MODULE,
  ACCESS_PATIENT_PAYMENTS_MODULE,
  SHOW_APPOINTMENTS_PATIENT,
  SHOW_CLINICAL_HISTORY,
} from "../../constants/permissions";

const PatientsTable = ({ patients, onEdit }) => {
  const show_appointments = useCheckPermission(SHOW_APPOINTMENTS_PATIENT);
  const show_clinical_history = useCheckPermission(SHOW_CLINICAL_HISTORY);
  const show_orders = useCheckPermission(ACCESS_PATIENT_ORDERS_MODULE);
  const show_payments = useCheckPermission(ACCESS_PATIENT_PAYMENTS_MODULE);

  const columns = [
    {
      Header: "Nombre",
      accessor: "[name]",
      Cell: ({ row: { original } }) => (
        <>
          {original.pa_name}
          {!!original.clinical_history?.ch_alert && <PatientBadgeAlert />}
        </>
      ),
    },
    {
      Header: "Documento",
      accessor: "pa_document",
    },
    {
      Header: "Correo electrónico",
      accessor: "pa_email",
    },
    {
      Header: "Teléfono",
      accessor: "pa_phone",
    },
    {
      Header: "Estado",
      accessor: "[status]",
      Cell: ({ row: { original } }) => (
        <span
          className={
            original.pa_status === "ACTIVE" ? "badge-green" : "badge-gray"
          }
        >
          {original.pa_status === "ACTIVE" ? "Activado" : "Desactivado"}
        </span>
      ),
    },
    {
      Header: "",
      accessor: "[options]",
      Cell: ({ row: { original } }) => (
        <div className="text-center">
          <button
            className="text-secondary-dark inline-block font-medium"
            onClick={() => onEdit(original)}
          >
            Editar
          </button>
          {show_appointments && (
            <Link
              to={`/patients/${original.id}/appointments`}
              className="text-secondary-dark ml-8 inline-block"
            >
              Citas
            </Link>
          )}
          {show_clinical_history && (
            <Link
              to={`/patients/${original.id}/clinical-history`}
              className="text-secondary-dark ml-8 inline-block"
            >
              Historia
            </Link>
          )}
          <Link
            to={`/patients/${original.id}/odontogram`}
            className="text-secondary-dark ml-8 inline-block"
          >
            Odontograma
          </Link>
          {show_orders && (
            <Link
              to={`/patients/${original.id}/orders`}
              className="text-secondary-dark ml-8 inline-block"
            >
              Ordenes
            </Link>
          )}
          {show_payments && (
            <Link
              to={`/patients/${original.id}/payments`}
              className="text-secondary-dark ml-8 inline-block"
            >
              Abonos
            </Link>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={patients} />
    </div>
  );
};

PatientsTable.propTypes = {
  patients: PropTypes.array,
  onEdit: PropTypes.func,
};

PatientsTable.defaultProps = {
  patients: [],
};

export default PatientsTable;
