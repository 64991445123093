import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import CustomTextInput from "../common/CustomTextInput";
import { setLoaderStatus } from "../../redux/loader/slice";
import { changePassword } from "../../http/api/userSettings";
import { successAlert } from "../../helpers/alerts";

const PasswordForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const password = watch("password", "");

  const changePasswordUser = async (formData) => {
    const data = {
      current_password: formData["currentPassword"],
      password: formData["password"]
    };

    try {
      dispatch(setLoaderStatus(true));
      const { message } = await changePassword(data);
      successAlert(message);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <form onSubmit={handleSubmit(changePasswordUser)}>
      <div className="mb-2">
        <CustomTextInput
          {...register("currentPassword", { required: "Requerido" })}
          type="password"
          name="currentPassword"
          id="currentPassword"
          label="Contraseña actual*"
          errors={errors}
        />
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register("password", {
            required: "Requerido",
            minLength: {
              value: 8,
              message: "Minimo 8 caracteres",
            },
          })}
          type="password"
          name="password"
          id="password"
          label="Nueva contraseña*"
          errors={errors}
        />
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register("repeatPassword", {
            validate: (value) =>
              value === password || "Las contraseñas no coinciden.",
          })}
          type="password"
          name="repeatPassword"
          id="repeatPassword"
          label="Repita la contraseña*"
          errors={errors}
        />
      </div>
      <div className="flex justify-end mt-4">
        <div className="flex justify-end">
          <button type="submit" className="btn-secondary-dark">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PasswordForm;
