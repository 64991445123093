import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import EntitySelector from "../../../common/EntitySelector";
import PatientFilterForm from "../../../patients/PatientFilterForm";
import CustomPagination from "../../../common/CustomPagination";
import { searchPatientsAppointment } from "../../../../http/api/patient";
import { setLoaderStatus } from "../../../../redux/loader/slice";
import PatientBadgeAlert from "../../../common/PatientBadgeAlert";

const PatientBrowser = ({ onSelectPatient }) => {
  const [patients, setPatients] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [searchBy, setSearchBy] = useState("");

  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Nombre",
      accessor: "[pa_name]",
      Cell: ({ row: { original } }) => (
        <>
          {original.pa_name}
          {!!original.clinical_history?.ch_alert && (
            <PatientBadgeAlert />
          )}
        </>
      ),
    },
    {
      Header: "Documento",
      accessor: "pa_document",
    },
  ];

  const onSelect = (data) => {
    onSelectPatient(data);
  };

  const search = async (page = 1, param, value) => {
    try {
      dispatch(setLoaderStatus(true));
      let params = { page };
      if (param && value) {
        params[param] = value;
      }
      const {
        data: { data, per_page, current_page, total },
      } = await searchPatientsAppointment(params);

      setPatients(data);
      setActivePage(current_page);
      setItemsCountPerPage(per_page);
      setTotalItemsCount(total);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  const onSubmit = async (formData) => {
    setFilter(formData["filter"]);
    setSearchBy(formData["searchBy"]);
    await search(activePage, formData["searchBy"], formData["filter"]);
  };

  return (
    <>
      <div className="mb-4">
        <PatientFilterForm onSubmit={onSubmit} />
      </div>
      {patients.length > 0 && (
        <div>
          <EntitySelector
            columns={columns}
            entities={patients}
            onSelect={onSelect}
          />
          {totalItemsCount > itemsCountPerPage && (
            <div className="flex justify-center mt-4">
              <CustomPagination
                totalItemsCount={totalItemsCount}
                onChange={(activePage) => search(activePage, searchBy, filter)}
                activePage={activePage}
                itemsCountPerPage={itemsCountPerPage}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

PatientBrowser.propTypes = {
  onSelectPatient: PropTypes.func,
};

export default PatientBrowser;
