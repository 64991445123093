import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import CustomTextInput from "../../common/CustomTextInput";
import NumberFormat from "react-number-format";

const numberFormat = new Intl.NumberFormat();

const PaymentPlanForm = (props) => {
  const {
    orderId,
    total,
    balance,
    defaultQuotes,
    defaultInitialQuotaValue,
    defaultInitialQuotaPercentage,
    financeValue,
    edit,
    showInitialFee,
    onSubmit,
  } = props;
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const watchInitialQuotaValue = watch("initialQuotaValue", 0);
  const watchInitialQuotaPercentage = watch("initialQuotaPercentage", 0);

  useEffect(() => {
    reset({
      orderId: orderId,
      total: total,
      quotas: defaultQuotes,
      initialQuotaValueFormat: defaultInitialQuotaValue,
      initialQuotaPercentageFormat: defaultInitialQuotaPercentage,
      initialQuotaValue: 0,
      initialQuotaPercentage: 0,
    });
  }, [
    orderId,
    total,
    defaultQuotes,
    defaultInitialQuotaPercentage,
    defaultInitialQuotaValue,
    reset,
  ]);

  useEffect(() => {
    if (watchInitialQuotaPercentage > 0) {
      setValue("initialQuotaValueFormat", 0);
    }
  }, [watchInitialQuotaPercentage, setValue]);

  useEffect(() => {
    if (watchInitialQuotaValue > 0) {
      setValue("initialQuotaPercentageFormat", 0);
    }
  }, [watchInitialQuotaValue, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-y-2 gap-x-3 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <div className="mb-2">
              <CustomTextInput
                {...register("orderId", { required: "Requerido" })}
                type="text"
                name="orderId"
                id="orderId"
                label="Orden N°"
                errors={errors}
                readOnly={true}
              />
            </div>
            <div className="mb-2">
              <Controller
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    thousandSeparator={true}
                    customInput={CustomTextInput}
                    label="Valor total"
                    prefix={"$ "}
                    readOnly={true}
                    onValueChange={(values) => {
                      const { value } = values;
                      setValue("total", value);
                    }}
                  />
                )}
                name="total"
                id="total"
                control={control}
              />
            </div>
            <div className="mb-2">
              <CustomTextInput
                {...register("quotas", {
                  required: "Requerido",
                  min: {
                    value: 1,
                    message: "Mínimo una cuota",
                  },
                })}
                type="text"
                name="quotas"
                id="quotas"
                label="Cantidad de cuotas"
                errors={errors}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="grid grid-cols-3 mb-8 items-end gap-x-1">
              <div className="col-span-2">
                <Controller
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      allowNegative={false}
                      thousandSeparator={true}
                      customInput={CustomTextInput}
                      label="Valor cuota inicial"
                      prefix={"$ "}
                      readOnly={!showInitialFee}
                      onValueChange={(values) => {
                        const { value } = values;
                        setValue("initialQuotaValue", value);
                      }}
                    />
                  )}
                  name="initialQuotaValueFormat"
                  id="initialQuotaValueFormat"
                  control={control}
                />
              </div>
              <div className="col-span-1">
                <Controller
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      allowNegative={false}
                      thousandSeparator={true}
                      customInput={CustomTextInput}
                      suffix={" %"}
                      readOnly={!showInitialFee}
                      onValueChange={(values) => {
                        const { value } = values;
                        setValue("initialQuotaPercentage", value);
                      }}
                    />
                  )}
                  name="initialQuotaPercentageFormat"
                  id="initialQuotaPercentageFormat"
                  control={control}
                />
              </div>
            </div>
            <div className="border py-2 px-4 border-gray-400">
              <div className="font-bold">
                <p>Valor total orden: ${numberFormat.format(total)}</p>
                <p>Saldo orden: ${numberFormat.format(balance)}</p>
                <p>Valor financiado: ${numberFormat.format(financeValue)}</p>
              </div>
            </div>
          </div>
        </div>
        {edit && (
          <div className="mb-2">
            <button type="submit" className="btn-secondary-dark">
              Calcular
            </button>
          </div>
        )}
      </form>
    </>
  );
};

PaymentPlanForm.propTypes = {
  orderId: PropTypes.number.isRequired,
  total: PropTypes.number,
  balance: PropTypes.number,
  defaultQuotes: PropTypes.number,
  defaultInitialQuotaValue: PropTypes.number,
  defaultInitialQuotaPercentage: PropTypes.number,
  financeValue: PropTypes.number,
  showInitialFee: PropTypes.bool,
  edit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

PaymentPlanForm.defaultProps = {
  total: 0,
  balance: 0,
  defaultQuotes: 0,
  defaultInitialQuotaValue: 0,
  defaultInitialQuotaPercentage: 0,
  financeValue: "",
  showInitialFee: true,
  edit: true,
};

export default PaymentPlanForm;
