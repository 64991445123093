import {
  KeyIcon,
} from "@heroicons/react/outline";
import PageTitle from "../components/common/PageTitle";
import { IdentificationIcon } from "@heroicons/react/solid";
import PasswordForm from "../components/userSettings/PasswordForm";

const subNavigation = [
  { name: "Password", href: "#", icon: KeyIcon, current: true }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UserPage = () => {
  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Usuario" Icon={IdentificationIcon} />
      <div className="max-w-screen-xl mx-auto mt-4 pb-6 lg:pb-16">
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className="py-6 lg:col-span-3">
              <nav className="space-y-1">
                {subNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-green-50 border-green-500 text-green-700 hover:bg-green-50 hover:text-green-700"
                        : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                      "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-green-500 group-hover:text-green-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </nav>
            </aside>
            <div className="divide-y divide-gray-200 lg:col-span-9">
              <div className="p-8 lg:p-12">
                <PasswordForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default UserPage;