import { useState, useEffect } from "react";
import { getAllAppointmentsStatus } from "../http/api/appointment";

function useGetAllAppointmentsStatus() {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getAllAppointmentsStatus();
      setStatus(data);
    })();
  }, []);

  return status;
}

export default useGetAllAppointmentsStatus;
