import http from "./axiosInstance";

/**
 * Create user
 * @param data
 * @returns {Promise<any>}
 */
export const createUser = (data) => {
  return http().post("/users", data);
};

/**
 * Update user by id
 *
 * @param data
 * @param user id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateUser = (data, id) => {
  return http().put(`/users/${id}`, data);
};

/**
 * Get all users
 * @returns {Promise<any>}
 */
export const getAllUsers = (params = {}) => {
  return http().get("/users", { params });
};

/**
 * Get User by id
 * @returns {Promise<any>}
 */
export const getUserById = (id) => {
  return http().get(`/users/${id}`);
};

/**
 * Get User by email
 * @returns {Promise<any>}
 */
export const getUserByEmail = (email) => {
  return http().get(`/users/email/${email}`);
};
