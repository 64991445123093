export const gDefault = [
  {
    className:"",
    path:<path className="st0" d="M103.7,218.8l-88.4-90.6c2.4-3,84.8-115.4,274.1-115.4c193.6,0,276.4,113,277.4,114.4L473.9,218
      c-0.8-1.1-71.3-78.4-184.5-78.4C179.1,139.6,104.5,217.7,103.7,218.8z"/>
  },
  {
    className:"",
    path:<path className="st0" d="M375,485.4l98.2,97.8c3-2.4,78.2-74.5,78.2-178.9c0-124.8-76-185.3-77.4-186.3l-98.6,97
      c1.1,0.8,35.8,37.5,35.8,89.3C411.2,452,376.1,484.6,375,485.4z"/>
  },
  {
    className:"",
    path:<path className="st0" d="M202.7,484.6l-98.2,97.8c-3-2.4-78.2-74.5-78.2-178.9c0-124.8,76-185.3,77.4-186.3l98.6,97
      c-1.1,0.8-35.8,37.5-35.8,89.3C166.4,451.2,201.6,483.8,202.7,484.6z"/>
  },
  {
    className:"",
    path:<path className="st0" d="M199.7,315.2l-96-98c2.4-3,81.5-77.5,185.7-77.5c124.6,0,183.5,77,184.5,78.4l-98.6,97
      c-0.8-1.1-38.5-37.7-90.3-37.7C237.5,277.3,200.5,314,199.7,315.2z"/>
  },
  {
    className:"",
    path:<path className="st0" d="M200.8,485.3l-96,98c2.4,3,81.5,77.5,185.7,77.5c124.6,0,183.5-77,184.5-78.4l-98.6-97
      c-0.8,1.1-38.5,37.7-90.3,37.7C238.6,523.2,201.6,486.5,200.8,485.3z"/>
  },
  {
    className:"",
    path:<path className="st0" d="M102.9,582.4L14.5,673c2.4,3,84.8,115.4,274.1,115.4c193.6,0,276.4-113,277.4-114.4l-92.9-90.9
      c-0.8,1.1-71.3,78.4-184.5,78.4C178.2,661.6,103.7,583.5,102.9,582.4z"/>
  },
  {
    className:"",
    path:<path d="M474,228.9c0,0-5.5-5.8-12.4-11.9l104.7-103.3c3.6,3.6,11.4,13,11.4,13L474,228.9z"/>
  },
  {
    className:"",
    path:<path d="M102.9,229.9l-99-103.2l12.4-11.9L115.5,218C115.5,218,104.4,228.3,102.9,229.9z"/>
  },
  {
    className:"",
    path:<path d="M15.8,138.9l-12-12.4c2.4-3,96.4-124.1,285.7-124.1c193.6,0,287.2,122.9,288.2,124.2l-13,12.8
      c-0.8-1.1-90.8-119.8-275.2-119.8C108.9,19.7,16.6,137.8,15.8,138.9z"/>
  },
  {
    className:"",
    path:<path d="M202.4,329.2l-99.5-99.4c0,0,7.7-7.5,12.6-11.8l99.1,98.6C208.8,321.3,202.4,329.2,202.4,329.2z"/>
  },
  {
    className:"",
    path:<path d="M375,327.5c-6.8-7.9-12.1-12.1-12.1-12.1l98.7-98.5c0,0,9,8,12.4,12C474.1,229,375,327.5,375,327.5z"/>
  },
  {
    className:"",
    path:<path d="M116.1,582.9c0,0-9.6-9.1-12.5-11.9l98.7-98.8c4.6,5.9,11.8,12.5,11.8,12.5L116.1,582.9z"/>
  },
  {
    className:"",
    path:<path d="M461.1,584.1l-97.9-97.9c0,0,6.2-5.3,11.9-12.1l98.4,97.9C473.5,571.9,465.5,580.2,461.1,584.1z"/>
  },
  {
    className:"",
    path:<polygon points="566.1,687.5 461,584 473.5,571.9 577.5,674.5 	"/>
  },{
    className:"",
    path:<path d="M15.3,687.5C8.1,680,3.8,674.7,3.8,674.7L103.6,571c4.9,4.2,12.6,11.8,12.6,11.8S15.4,687.6,15.3,687.5z"/>
  },{
    className:"",
    path:<path d="M289.8,798.6c-189.3,0-285.9-123.8-286-123.9l12.3-12.8c0.8,1.1,93.4,119.5,273.6,119.5c184.4,0,275.3-118.7,275.5-118.9
      l12.2,12.1C577.5,674.5,483.4,798.6,289.8,798.6z"/>
  },{
    className:"",
    path:<path d="M91.6,583.4c0,0-72.7-72-72.7-183.7c0-111.7,69.8-180.3,72.8-183.1l12,12.4c-0.7,0.7-67.5,66.6-67.5,170.7
      c0,104.2,66.7,170.6,67.4,171.3C103.6,571,100,574.9,91.6,583.4z"/>
  },
  {
    className:"",
    path:<path className="noHover" d="M485.7,584.1l-12.2-12.2c0.7-0.7,68-68.1,68-172.3c0-104.1-66.8-170.1-67.5-170.8l12.3-12.1c3,2.9,72.5,71.2,72.5,182.9
      C558.7,511.3,488.7,581.2,485.7,584.1z"/>
  },
  {
    className:"",
    path:<path d="M289.2,669.6c-119.5,0-194.5-82.7-197.6-86.2l11.9-12.4c8.1,6.7,74.1,81.3,185.7,81.3c111.6,0,176.7-73.3,184.3-80.4
      l12.3,12.1C482.7,587.5,408.7,669.6,289.2,669.6z"/>
  },{
    className:"",
    path:<path d="M103,229.9c-0.3-0.2-11.8-12-12-12.4c0,0,79.5-85.9,198.9-85.9c119.5,0,193.3,81.7,196.4,85.2L474,228.9
      c-1.8-1.8-72.6-80-184.1-80C178,148.9,107.5,225.9,103,229.9z"/>
  },{
    className:"",
    path:<path fill="white"  stroke-width="10.5px" stroke="black" d="M409 401C409 467.3 355.3 521 289 521C222.7 521 169 467.3 169 401C169 334.7 222.7 281 289 281C355.3 281 409 334.7 409 401Z"/>
  }
]

