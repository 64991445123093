import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import PatientOrdersTable from "./PatientOrdersTable";
import { PlusCircleIcon } from "@heroicons/react/solid";
import CustomModalContent from "../../common/CustomModalContent";
import { getPatientOrders } from "../../../http/api/orders";
import PatientOrdersFiltersForm from "./PatientOrdersFiltersForm";
import Order from "../Order";
import PaymentPlan from "../PaymentPlan";
import CustomPagination from "../../common/CustomPagination";
import { setLoaderStatus } from "../../../redux/loader/slice";
import PermissionWrapper from "../../common/PermissionWrapper";
import { CREATE_ORDER } from "../../../constants/permissions";

const PatientOrders = ({ patient }) => {
  const [openModalOrder, setOpenModalOrder] = useState(false);
  const [openModalPlan, setOpenModalPlan] = useState(false);
  const [order, setOrder] = useState({});
  const [orders, setOrders] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [orderIdFilter, setOrderIdFilter] = useState();
  const [toggleSearch, setToggleSearch] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const getOrders = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const params = {
          page: activePage,
          order_id: orderIdFilter,
        };

        const { data: {data, per_page, current_page, total}} = await getPatientOrders(
          patient.id,
          params
        );
        setOrders(data);
        setActivePage(current_page);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getOrders(patient.id, orderIdFilter);
  }, [patient, activePage, orderIdFilter, dispatch, toggleSearch]);

  const handleOpenModalOrder = () => setOpenModalOrder(true);
  const handleCloseModalOrder = () => setOpenModalOrder(false);
  const handleOpenModalPlan = () => setOpenModalPlan(true);
  const handleCloseModalPlan = () => setOpenModalPlan(false);

  const search = (formData) => {
    setOrderIdFilter(formData.orderId);
  };

  const handleEdit = (data) => {
    setOrder(data);
    handleOpenModalOrder();
  };

  const handleOpenPlan = (data) => {
    setOrder(data);
    handleOpenModalPlan();
  };

  const handleSaveOrder = async () => {
    handleCloseModalOrder();
    setToggleSearch(prevState => !prevState);
  };

  return (
    <div>
      <div className="pb-2 grid grid-cols-1 md:grid-cols-3">
        <div className="self-end md:mb-0 mb-2 col-span-2">
          <PatientOrdersFiltersForm onSubmit={search} />
        </div>
        <div className="self-end flex justify-end">
          <PermissionWrapper permission={CREATE_ORDER} >
            <button
              type="button"
              className="btn-secondary-dark"
              onClick={() => {
                setOrder({});
                handleOpenModalOrder();
              }}
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4 "
                aria-hidden="true"
              />
              Crear
            </button>
          </PermissionWrapper>
          <CustomModalContent
            title="Orden"
            open={openModalOrder}
            onClose={handleCloseModalOrder}
            closeOnOverlayClick={false}
          >
            <Order
              patientId={patient.id}
              orderId={order.id}
              onSaveOrder={handleSaveOrder}
            />
          </CustomModalContent>
          {order.id && (
            <div>
              <CustomModalContent
                open={openModalPlan}
                onClose={handleCloseModalPlan}
                closeOnOverlayClick={false}
              >
                <PaymentPlan
                  orderId={order?.id}
                  onSavePlan={handleCloseModalPlan}
                  onDeletePlan={handleCloseModalPlan}
                  onPayQuota={handleCloseModalPlan}
                />
              </CustomModalContent>
            </div>
          )}
        </div>
      </div>
      <PatientOrdersTable
        orders={orders}
        onEdit={handleEdit}
        onOpenPlan={handleOpenPlan}
      />
      <CustomPagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        onChange={(page) => setActivePage(page)}
      />
    </div>
  );
};

PatientOrders.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientOrders;
