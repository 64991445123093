import React from "react";
import PropTypes from 'prop-types';

const PageTitle = ({ title, Icon, secondaryTitle, components }) => {
  return (
            <div className="flex-1 px-4 flex justify-between mb-2">
              <div className="text-xl font-medium text-secondary-dark">
                <span className="border-b-2 border-secondary-dark py-2">
                  <Icon className="mr-1 h-6 w-6 inline-block align-middle" />
                  <h1 className="inline-block align-middle">{title}</h1>
                </span>
              </div>
              <div className="flex-1 h-14 py-2 pl-2">
                {secondaryTitle}
              </div>
              {
                components.length>0?<>
                  {components.map((row,key)=>{
                    return  <div className="h-14" key={key}>
                              {row}
                            </div>
                  })}
                </>:false
              }
            </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired
}

export default PageTitle;
