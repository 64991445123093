import React, { useState,useEffect } from "react";
import { gDefault } from "./Consts";
import dentsList from './ConstsDents'

/*active es el botón o ícono seleccionado al momento*/
export const One=({active,id,data,setData,row,bkData,allEdentulous,setAllEdentulous,type})=>{

  /*
    hay 2 bloques por convenciones:
    1)  los íconos que indican de forma global y están el las primeras líneas
    2)  Los íconos representados por cuadros en la segunda línea

    En el caso de la primera línea hay restricciones y se establecen en el
    objeto ConstDents.js
    allow:  son con quienes tiene permitido combinar,
            si no está en esta lista no podrá seleccionar nada, no hay evento
    deny:   son el caso contrario al anterior, si selecciono este,
            cuando presione este evento me borra inmediatamente el anterior
            y sobreescribe con este

  */


  const [activeG, setActiveG]           = useState([]);
  const [activeSector, setActiveSector] = useState({});

  /*
    este evento es al diente completo
    determinamos acá los allows primero
  */

  useEffect(()=>{
    if (allEdentulous) {
      if (  (allEdentulous.top.childrens &&
            allEdentulous.top.childrens.desde!==undefined) &&
            (allEdentulous.top.childrens.desde<=row.label && allEdentulous.top.childrens.hasta>=row.label)
          ) {

        handleClick({})
      }
      if (  (allEdentulous.top.adults &&
            allEdentulous.top.adults.desde!==undefined) &&
            (allEdentulous.top.adults.desde<=row.label && allEdentulous.top.adults.hasta>=row.label)) {
        handleClick({})
      }

    }
  },[allEdentulous])

  useEffect(()=>{
    let activeG_  =   []
    if (bkData.activeG && bkData.activeG.length>0) {
      if (bkData.activeG && bkData.activeG[row.label]) {
        bkData.activeG[row.label].map((row,key)=>{
          if (row) {
            let buscar  =   dentsList.find((search)=>search.alias===row.alias)
            buscar.updateAt = row.updateAt;
            return activeG_.push(buscar)
          }else {
            return false
          }
        })
      }
      setActiveG(activeG_)
    }

    if (bkData.activeSector[row.label]) {
      setActiveSector(bkData.activeSector[row.label])
    }
  },[bkData])

  useEffect(()=>{
    let data_ = {...data}
        data_.activeG[row.label]  =   activeG;
        setData(data_)
  },[activeG])

  useEffect(()=>{
    let data_ = {...data}
        data_.activeSector[row.label]  =   activeSector;
        setData(data_)
  },[activeSector])

  const handleClick=(e)=>{
    console.log(active)
    if (active && active.type===2) {



      let insert    =   [...activeG]
      //const result  =   activeG.find(search=>search.alias===active.alias)
      /*comenzamos si no existe, simplemente lo creamos*/

      if(active.deny==='all'){
        setActiveSector([]);
        insert.push(active);
      }



      if(active.alias ==='implante_colocado' || active.alias ==='implante_indicado'){
        setActiveSector([]);
      }

      if (activeG.length===0) {
        if(active.alias==='sellante_colocado' || active.alias==='sellante_indicado' ||
            active.alias==='corona_colocada' || active.alias==='corona_indicada' ||
          active.alias==='endodoncia_buena' || active.alias==='endodoncia_indicada' ||
            active.alias==='carilla_colocada' || active.alias==='carilla_indicada' ||
            active.alias==='nucleo' || active.alias==='nucleo_indicado'||
          active.alias==='provisional_indicado' || active.alias==='Provisional_colocado'||
            active.alias==='implante_indicado' || active.alias==='implante_colocado' || active.alias==='fractura'
        ){
          active.updateAt = new Date();
          insert.push(active)
        }
        else if(activeSector.length===0 ){
          active.updateAt = new Date();
          insert.push(active)
        }
      }
      /*
        Si no está en el arreglo pero ya hay items en el array
        se debe verificar los permitidos y los prohibidos
      */
      else if (activeG.length>0 && active.deny==='all') {
        insert  = [active];
        setActiveSector([]);
      }
      else if (activeG.length>0 && active.deny!=='all') {
        let deny  =  false
        let item  =  false
        let restrict = active.deny;
        /*busco si el botón seleccionado tiene denegación*/
        for (var i = 0; i < active.deny.length; i++) {
          //if (!deny || deny===undefined) {
            for (var i2 = 0; i2 < activeG.length; i2++) {
              deny    =   activeG[i2].deny.includes(active.alias);//active.deny.find(search=>search===activeG[i2].alias)
              item    =   {item:activeG[i2],key:i2};
              if (deny) {
                insert[item.key] = active;
              }
            }
         // }
        }

       if(activeG[0].deny === "all"){
         insert  = [active];
       }



        /*if (deny) {
          insert[item.key] = active;
        }*/

        let allow  =  false

        for (var j = 0; j < active.allow.length; j++) {
          if (!allow || allow===undefined) {
            for (var k = 0; k < activeG.length; k++) {
              allow    =  active.allow.find(search=>search===activeG[k].alias)
            }
          }
        }

        if ((!deny || deny===undefined) && allow) {
          active.updateAt = new Date();
          insert.push(active)
        }

      }
      setActiveG(insert)
    }
  }

  /*Esta función es para los sectores del diente*/
  const handleClickSector=(e,row,key)=>{
    if (active && active.type===1 && row!==undefined) {
      let activeG_      =   [...activeG]
      let activeSector_ =   {...activeSector}
      /*
        determinamos si el activeG que está seleccionado
        tiene resticción con el activado.
        si tiene deny==='all' es sobraescrito por este
      */

      /*primer comprobación que no tenga all*/
      const result_all  =  activeG_.find(search=>search.deny==="all")
      if (result_all) {
        setActiveG([]);
        activeSector_[key]      =   {
          key:key,
          ...row,
          className:active.classNameSelect,
          updateAt: new Date()
        }
        setActiveSector(activeSector_)
      }
      else {
        /*
          si no existe un deny===all hago una búsqueda si está permitido
          este elemento
        */
        const result_deny   =  activeG_.find(search=>search.deny.find(search2=>search2===active.alias))
        const result_allow  =  activeG_.find(search=>search.allow.find(search2=>search2===active.alias))

        if (result_deny===undefined && (result_allow===undefined || result_allow)) {
          //activeSector_.push(active)
          if (!activeSector_[key]) {
            activeSector_[key]      =   {
                                          key:key,
                                          ...row,
                                          className:active.classNameSelect,
                                          updateAt: new Date()
                                        }
          }else if ((activeSector_[key]) || (activeSector_[key] && active.reset)) {
            delete(activeSector_[key])
            activeSector_[key]      =   {
              key:key,
              ...row,
              className:active.classNameSelect,
              updateAt: new Date()
            }
          }
          setActiveSector(activeSector_)
        }
      }
    }
  }

  const Src = ({Resourse,className})=>{
    return <Resourse className={className} />
  }

  return    <div className="container-dentsSVG" onClick={(e)=> {
    handleClick(e);
  }}>
              <svg  className={active && active.src?" dents dentsCursorPointer":" dents " }
                    viewBox="0 0 579 800">

                {gDefault.map((row,key)=>{

                  if (
                        key===8   ||
                        key===15  ||
                        key===16  ||
                        key===17  ||
                        key===18  ||
                        key===19
                      ) {
                    return  <g className={" not_event "} key={key}>
                              {row.path}
                            </g>
                  }else {

                    return  <g className={activeSector[key]!==undefined?activeSector[key].className+"":active.classNameSelectHover} key={key} onClick={(e)=>handleClickSector(e,row,key)}>
                              {row.path}
                            </g>
                  }
                })}

                {activeG.map((row,key)=>{
                  if(activeG[key]['alias']!=="implante_indicado" || activeG[key]['alias']!=="implante_colocado"){
                    return <g style={{zIndex:-100}} key={key}>{<row.active></row.active>}</g>
                  }
                })}

                {activeG.map((row,key)=>{
                  if(activeG[key]['alias']==="implante_indicado" || activeG[key]['alias']==="implante_colocado"){
                    return <g style={{zIndex:-100}} key={key}>{<row.active></row.active>}</g>
                  }
                })}

              </svg>
            </div>

}
