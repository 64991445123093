import {SvgResinaIndicada,SvgResinaColocada} from "./ConstLines"
const secondarySet  = [
  {
    alias:"sector_sano",
    label:"Sector sano",
    className:"frame frame-white",
    classNameSelect:"dentsSVGSano",
    classNameSelectHover:"dentsSVGSanoHover",
    reset:true,
    updateAt: new Date()
  },
  {
    alias:"obturacion",
    label:"Obturación",
    className:"frame frame-blue",
    classNameSelect:"dentsSVGObturacion",
    classNameSelectHover:"dentsSVGObturacionHover",
    updateAt: new Date()
  },
  {
    alias:"caries",
    label:"Caries",
    className:"frame frame-red",
    classNameSelect:"dentsSVGCaries",
    classNameSelectHover:"dentsSVGCariesHover",
    updateAt: new Date()
  },
  {
    alias:"recidiva",
    label:"Recidiva",
    className:"frame frame-border-red",
    classNameSelect:"dentsSVGRecidiva",
    classNameSelectHover:"dentsSVGRecidivaHover",
    updateAt: new Date()
  },
  {
    alias:"resina_colocada",
    label:"Resina colocada",
    className:"frame frame-azulito",
    classNameSelect:"dentsSVGResina",
    classNameSelectHover:"dentsSVGResinaHover",
    updateAt: new Date()
    //svg:<SvgResinaIndicada/>,
  },
  {
    alias:"resina_indicada",
    label:"Resina indicada",
    className:"frame frame-rojito",
    classNameSelect:"dentsSVGResinaIndicada",
    classNameSelectHover:"dentsSVGResinaIndicadaHover",
    updateAt: new Date()
    //svg:<SvgResinaColocada/>,
  },
  {
    alias:"erosion",
    label:"Erosion",
    className:"frame frame-amarillito",
    classNameSelect:"dentsSVGErosion",
    classNameSelectHover:"dentsSVGErosionHover",
    updateAt: new Date()
  },
  {
    alias:"paciente_clinicamente_sano",
    label:"Paciente clínicamente sano",
    className:"frame frame-white",
    classNameSelect:"dentsSVGSano",
    classNameSelectHover:"dentsSVGSanoHover",
    confirm:{
      action:"diente_sano",
      label:"¿Desea confirmar la marcación de paciente clínicamente sano?",
      top:{childrens:{desde:51,hasta:85},adults:{desde:11,hasta:48}}
    }
  },
  {
    alias:"paciente_edentulo",
    label:"Edéntulo Sup",
    className:"frame frame-border-light",
    classNameSelect:"dentsSVGEdentulo",
    classNameSelectHover:"dentsSVGEdentuloHover",
    confirm:{
              action:"diente_ausente",
              label:"¿Desea confirmar la marcación de paciente edéntulo superior?",
              top:{childrens:{desde:51,hasta:65},adults:{desde:31,hasta:48}}
            }
  },
  {
    alias:"paciente_edentulo",
    label:"Edéntulo Inf",
    className:"frame frame-border-light",
    classNameSelect:"dentsSVGEdentulo",
    classNameSelectHover:"dentsSVGEdentuloHover",
    confirm:{
              action:"diente_ausente",
              label:"¿Desea confirmar la marcación de paciente edéntulo inferior?",
              top:{childrens:{desde:71,hasta:85},adults:{desde:11,hasta:28}}
            }
  }

]
export default secondarySet
