import http from "./axiosInstance";

/**
 * Create patient
 *
 * @param data
 * @returns {Promise<any>}
 */
export const createPatient = (data) => {
  return http().post("/patients", data);
};

/**
 * Update patient
 *
 * @param data
 * @param id patient
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updatePatient = (data, id) => {
  return http().put(`/patients/${id}`, data);
};

/**
 * Get patient by id
 * @returns {Promise<any>}
 */
export const getPatientById = (id) => {
  return http().get(`/patients/${id}`);
};

/**
 * Get all users
 * @returns {Promise<any>}
 */
export const getAllPatients = (params = {}) => {
  return http().get("/patients", { params });
};

/**
 * Get patient by id with clinical history
 * @returns {Promise<any>}
 */
export const getPatientClinicalHistory = (id) => {
  return http().get(`/patients/${id}/clinical-history`);
};

/**
 * Search patients
 * @returns {Promise<any>}
 */
export const searchPatientsAppointment = (params) => {
  return http().get("/patients/appointment/search", { params });
};

/**
 * Get patient photo
 * @returns {Promise<any>}
 */
export const getPatientPhoto = (id) => {
  return http().get(`/patients/photo/${id}`);
};

/**
 * Get patient balance
 * @returns {Promise<any>}
 */
export const getPatientBalance = (id) => {
  return http().get(`/patients/${id}/balance`);
};

/**
 * Get patient odontograms
 * @returns {Promise<any>}
 */
 export const getPatientOdontograms = (params, id) => {
  return http().get(`/patients/${id}/odontograms`, { params });
};

export const getPatientOdontogramsLast = (params, id) => {
    return http().get(`/patients/${id}/odontogramsgetlast`, { params });
};

export const getPatientOdontogramsFirst = (params, id) => {
    return http().get(`/patients/${id}/odontogramsgetfirst`, { params });
};

export const getPatientOdontogramNotes = (params, id) => {
    return http().get(`/patients/${id}/odontogramsgetnotes`, { params });
};
