import React from 'react';
import { useForm } from "react-hook-form";
import CustomTextInput from "../common/CustomTextInput";
import {regExpEmail} from "../../helpers/regexp";

const ResetPasswordForm = ({onSubmit}) => {
  const { register, handleSubmit, watch, formState:{ errors } } = useForm();
  const password = watch("password", "");

  return(
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="mb-2">
        <CustomTextInput
          {...register('email', {
            required: 'Requerido',
            pattern: {value: regExpEmail, message: 'Email invalido'},
          })}
          type="text"
          name="email"
          id="email"
          label="Email*"
          errors={errors}
        />
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register('password', {
            required: 'Requerido',
            minLength: {
              value: 8,
              message: "Minimo 8 caracteres"
            }
          })}
          type="password"
          name="password"
          id="password"
          label="Contraseña*"
          errors={errors}
        />
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register('repeatPassword', {
            validate: value =>
              value === password || "Las contraseñas no coinciden."
          })}
          type="password"
          name="repeatPassword"
          id="repeatPassword"
          label="Repita la contraseña*"
          errors={errors}
        />
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Restablecer
        </button>
      </div>
    </form>
  )
}

export default ResetPasswordForm;
