import http from "./axiosInstance";

/**
 * Create consultation
 * @param data
 */
export const createConsultation = (data) => {
  return http().post("/consultations", data);
};

/**
 * update consultation
 * @param data
 */
 export const updateConsultation = (data, id) => {
  return http().put(`/consultations/${id}`, data);
};

/**
 * Get consultation by id
 * @param id
 * @returns {*}
 */
export const getConsultationById = (id) => {
  return http().get(`consultations/${id}`);
};

/**
 * Get all consultations
 * @returns {Promise<any>}
 */
export const getAllConsultations = () => {
  return http().get("/consultations");
};
