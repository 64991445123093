import {ExclamationCircleIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";

const NotFoundPage = () => (

  <div className="h-screen w-full bg-gray-100 flex justify-center">
    <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
      <div className="max-w-md">
        <div className="text-5xl font-dark font-bold">404</div>
        <p
          className="text-2xl md:text-3xl font-light leading-normal"
        >Lo sentimos, no pudimos encontrar esta página. </p>
        <p className="mb-8">Pero no te preocupes, puede encontrar muchas otras cosas en nuestra página de inicio.</p>

        <Link to="/" className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700">Ir al Home</Link>
      </div>
      <div className="max-w-lg">
        <ExclamationCircleIcon className="w-full" />
      </div>
    </div>
  </div>
)

export default NotFoundPage;
