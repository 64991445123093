import React from "react";
import PropTypes from "prop-types";
import {
  ClipboardListIcon,
  CurrencyDollarIcon,
  IdentificationIcon
} from "@heroicons/react/solid";

const PatientHeader = ({ name, clinicalHistory, typeDocument, document, balance }) => {
  return (
    <div className="lg:flex lg:items-center lg:justify-between pb-4">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          { name }
        </h2>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          {clinicalHistory &&
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ClipboardListIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              N° historia: { clinicalHistory }
            </div>
          }
          {typeDocument && 
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <IdentificationIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              { typeDocument } - { document }
            </div>
          }
          {balance && 
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CurrencyDollarIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Saldo: { balance }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

PatientHeader.propTypes = {
  name: PropTypes.string.isRequired,
  clinicalHistory: PropTypes.string,
  typeDocument: PropTypes.string,
  document: PropTypes.string,
  balance: PropTypes.string
};

export default PatientHeader;
