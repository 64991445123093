import http from "./axiosInstance";

/**
 * Create appointment
 *
 * @param data
 * @returns {Promise<any>}
 */
export const createConfig = (data) => {
  return http().post("/config", data);
};

/**
 * Update ConfigPage
 *
 * @param data
 * @param id
 * @returns {Promise<any>}
 */
export const updateConfig = (data, id) => {
  return http().put(`/config/${id}`, data);
};

/**
 * Get current config
 *
 * @returns {Promise<any>}
 */
export const getCurrentConfig = () => {
  return http().get("/config/current");
};

/**
 * Get lists patient
 *
 * @returns {Promise<any>}
 */
export const getListsPatient = () => {
  return http().get("/config/lists/patient");
};
