import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPermissions } from "../redux/session/selectors";
import { menuElementsHome } from "../constants/navigation";

const HomePage = ({ permissions }) => {
  let navigation = menuElementsHome.filter((item) => {
    if (
      permissions &&
      (permissions.includes(item.permission) || item.permission === "")
    ) {
      return item;
    }
  });

  return (
    <div className="py-4 max-w-full sm:max-w-2xl md:max-w-4xl mx-auto px-4 sm:px-6 md:px-8">
      <div className="grid grid-cols-3 gap-6 sm:grid-cols-3">
        {navigation.map((item) => (
          <Link key={item.name} to={item.href}>
            <div className="text-primary rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm text-center hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 hover:bg-secondary">
              <item.icon className="w-9/12 mx-auto" />
              <div className="font-medium text-base">{item.name}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: getPermissions(state),
});

export default connect(mapStateToProps)(HomePage);
