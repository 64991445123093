import http from './axiosInstance';

/**
 * Create specialty
 *
 * @param data
 * @returns {Promise<any>}
 */
export const createSpecialty = (data) => {
  return http().post('/specialties', data);
}

/**
 * Update specialty by id
 *
 * @param data
 * @param specialty id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateSpecialty = (data, id) => {
  return http().put(`/specialties/${id}`, data);
}

/**
 * Get all Specialties
 * @returns {Promise<any>}
 */
export const getAllSpecialties = () => {
  return http().get('/specialties')
}

/**
 * Get Specialties admin
 * @returns {Promise<any>}
 */
export const getSpecialtiesAdmin = (params = {}) => {
  return http().get('/specialties/admin', {params})
}
