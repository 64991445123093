import { useState, useEffect } from "react";
import { getAllSpecialties } from "../http/api/specialty";

function useGetAllSpecialties() {
  const [specialties, setSpecialties] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getAllSpecialties();
      setSpecialties(data);
    })();
  }, []);

  return specialties;
}

export default useGetAllSpecialties;
