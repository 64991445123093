import React from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import CustomErrorMessage from "../common/CustomErrorMessage";
import CustomTextInput from "../common/CustomTextInput";
import CustomTextarea from "../common/CustomTextarea";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const ConsultationForm = ({ agreements, consultation, onSubmit }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  setValue("process", `${consultation?.co_process || ''} ${transcript}`);
  const startListening = () => {
    if (!listening) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.stopListening();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 overflow-hidden col-span-8 px-4 pb-5 sm:pb-6">
          <div>
            <div className="mb-2">
              <label
                htmlFor="agreementId"
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                Convenio*
              </label>
              <select
                {...register("agreementId")}
                id="agreementId"
                name="agreementId"
                defaultValue={consultation?.co_agreement_id}
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                {agreements &&
                  agreements.map((agreement) => (
                    <option key={agreement.value} value={agreement.value}>
                      {agreement.label}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-2 relative">
              <CustomTextarea
                {...register("process", { required: "Requerido" })}
                id="process"
                name="process"
                label="Procedimiento*"
                cols="5"
                defaultValue={consultation?.co_process}
                spellCheck={true}
              />
              <CustomErrorMessage errors={errors} name="process" />
              {browserSupportsSpeechRecognition && (
                <div
                  className="inline-flex absolute top-7 right-0"
                  role="group"
                  aria-label="Button group"
                >
                  <button
                    type="button"
                    className={`inline-flex items-center justify-center w-6 h-6 mr-1 bg-white transition-colors duration-150 rounded-full focus:shadow-outline ${
                      listening ? "text-danger" : "text-black"
                    }`}
                    onClick={startListening}
                  >
                    <svg
                      className="w-4 h-4 fill-current"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-6 h-6 mr-1 text-danger transition-colors duration-150 rounded-full focus:shadow-outline bg-white"
                    onClick={resetTranscript}
                  >
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
            <div className="mb-2">
              <CustomTextarea
                {...register("nextAppointment")}
                id="nextAppointment"
                name="nextAppointment"
                label="Próxima cita"
                cols="5"
                defaultValue={consultation?.co_next_appointment}
                spellCheck={true}
              />
            </div>
            <div className="mb-2">
              <CustomTextarea
                {...register("diagnostic")}
                id="diagnostic"
                name="diagnostic"
                label="Diagnóstico"
                cols="5"
                defaultValue={consultation.co_diagnostic}
                spellCheck={true}
              />
            </div>
            <div className="mb-2">
              <CustomTextarea
                {...register("reason")}
                id="reason"
                name="reason"
                label="Motivo consulta"
                cols="5"
                defaultValue={consultation?.co_reason}
                spellCheck={true}
              />
            </div>
            <div className="mb-2">
              <CustomTextarea
                {...register("currentIllness")}
                id="currentIllness"
                name="currentIllness"
                label="Enfermedad actual"
                cols="5"
                defaultValue={consultation?.co_current_illness}
                spellCheck={true}
              />
            </div>
            <div className="mb-2">
              <CustomTextarea
                {...register("reviewSystem")}
                id="reviewSystem"
                name="reviewSystem"
                label="Revisión por sistema"
                cols="5"
                defaultValue={consultation?.co_review_system}
                spellCheck={true}
              />
            </div>
          </div>
          <div>
            <div className="mb-2">
              <CustomTextarea
                {...register("physicalExam")}
                id="physicalExam"
                name="physicalExam"
                label="Examen fìsico"
                cols="5"
                defaultValue={consultation.co_physical_exam}
                spellCheck={true}
              />
            </div>
            <div className="mb-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
              <div>
                <Controller
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      customInput={CustomTextInput}
                      label="Peso"
                    />
                  )}
                  name="weight"
                  id="weight"
                  control={control}
                  defaultValue={consultation.co_weight}
                />
              </div>
              <div>
                <Controller
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      customInput={CustomTextInput}
                      label="Cintura"
                    />
                  )}
                  name="waist"
                  id="waist"
                  control={control}
                  defaultValue={consultation.co_waist}
                />
              </div>
            </div>
            <div className="mb-3 grid grid-cols-1 gap-2 mt-4 sm:grid-cols-2">
              <div>
                <CustomTextInput
                  {...register("umbilical")}
                  type="text"
                  name="umbilical"
                  id="umbilical"
                  label="Umbilical"
                  defaultValue={consultation.co_umbilical}
                  errors={errors}
                />
              </div>
              <div>
                <CustomTextInput
                  {...register("infraumbilical")}
                  type="text"
                  name="infraumbilical"
                  id="infraumbilical"
                  label="Infraumbilical"
                  defaultValue={consultation.co_infra_umbilical}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="btn-secondary-dark"
          >
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};

export default ConsultationForm;
