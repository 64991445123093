import React from "react";

const ConsultationDetail = ({ consultation }) => {
  const paragraphs = [];
  const items = [];

  const longProperties = {
    'co_process': 'Procedimiento',
    'co_next_appointment': 'Próxima cita',
    'co_diagnostic': 'Diagnóstico',
    'co_reason': 'Motivo consulta',
    'co_current_illness': 'Enfermedad actual',
    'co_review_system': 'Revisión por sistema',
    'co_physical_exam': 'Examenen fisico',
  }
  const shortProperties = {
    'co_weight': 'Peso',
    'co_waist': 'Cintura',
    'co_umbilical': 'Umbilical',
    'co_infra_umbilical': 'Infraumbilical',
  }


  for (const property in longProperties) {
    if (consultation && consultation[property] != null){
      paragraphs.push(
        <div key={longProperties[property]}>
          <h4 className="text-md font-medium text-gray-500">{longProperties[property]}</h4>
          <p className="pt-2 pb-6 text-justify">{consultation[property]}</p>
        </div>
      )
    }
  }

  for (const property in shortProperties) {
    if (consultation && consultation[property] != null){
      items.push(
        <div className="sm:col-span-1" key={shortProperties[property]}>
          <dt className="text-md font-medium text-gray-500">{shortProperties[property]}</dt>
          <dd className="mt-1 text-sm text-gray-900">{consultation[property]}</dd>
        </div>
      )
    }
  }

  return(
    <div>
      {paragraphs}
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        {items}
      </div>
    </div>
  )
}

export default ConsultationDetail;
