import React from "react";
import PropTypes from "prop-types";
import Pagination from 'react-js-pagination';

const CustomPagination = ({totalItemsCount, onChange, activePage, itemsCountPerPage, ...props}) => {
  return <div className="flex justify-center mt-4">
    <Pagination
      totalItemsCount={totalItemsCount}
      onChange={onChange}
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      innerClass="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
      linkClass="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
      activeLinkClass="z-10 bg-tertiary border-secondary-dark text-secondary-dark"
      {...props}
    />
  </div>
}

CustomPagination.propTypes = {
  totalItemsCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number
};

CustomPagination.defaultProps = {
  itemsCountPerPage: 10
}

export default CustomPagination;