import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { FilterIcon } from "@heroicons/react/solid";
import CustomTextInput from "../common/CustomTextInput";
import CustomErrorMessage from "../common/CustomErrorMessage";

const PatientFilterForm = ({ onSubmit, schemaValidation }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const optionsSearch = [
    { key: "search_by_name", value: "Nombre" },
    { key: "search_by_document", value: "Documento" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-2">
        <div className="mb-2 md:mb-0">
          <CustomTextInput
            {...register("filter", schemaValidation?.filter)}
            type="text"
            name="filter"
            id="filter"
            errors={errors}
            placeholder="Buscar"
          />
        </div>
        <div className="mb-2 md:mb-0">
          <select
            {...register("searchBy", schemaValidation?.searchBy)}
            id="searchBy"
            name="searchBy"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">Buscar por</option>
            {optionsSearch.map((item) => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
          <CustomErrorMessage errors={errors} name="searchBy" />
        </div>
        <div className="self-end mb-2 md:mb-0">
          <button type="submit" className="btn-secondary-dark">
            <FilterIcon className="-ml-0.5 mr-2 h-4 w-4" />
            Buscar
          </button>
        </div>
      </div>
    </form>
  );
};

PatientFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  schemaValidation: PropTypes.object,
};

PatientFilterForm.defaultProps = {
  schemaValidation: {
    filter: { required: "Requerido" },
    searchBy: { required: "Requerido" },
  },
};

export default PatientFilterForm;
