import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import PatientOrders from "../components/orders/PatientOrders";
import { getPatientClinicalHistory } from "../http/api/patient";
import PageTitle from "../components/common/PageTitle";
import { UsersIcon } from "@heroicons/react/solid";
import BasicCard from "../components/common/BasicCard";
import { setLoaderStatus } from "../redux/loader/slice";
import PatientHeader from "../components/patients/PatientHeader";

const PatientOrdersPage = () => {
  const [patient, setPatient] = useState({});
  
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getPatient = async (patientId) => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getPatientClinicalHistory(patientId);
        setPatient(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatient(id);
  }, [dispatch, id]);


  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      {patient.id && (
        <>
          <PageTitle title="Ordenes de compra" Icon={UsersIcon} />
          <BasicCard>
            <PatientHeader
              name={patient?.pa_name}
              clinicalHistory={patient?.clinical_history?.ch_code}
              typeDocument={patient?.document_type?.dt_code}
              document={patient?.pa_document}
            />
            <PatientOrders patient={patient} />
          </BasicCard>
        </>
      )}
    </div>
  );
};

export default PatientOrdersPage;