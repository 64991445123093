import React from 'react';
import moment from "moment";
import {statusInfo} from "../../constants/appointments";
import Table from "../common/Table";
import PropTypes from "prop-types";

const AppointmentsTable = ({ appointments }) => {
  const columns = [
    {
      Header: 'Fecha',
      accessor: '[dateFormat]',
      Cell: ({ row:{original} }) => moment(original.ap_from).format('YYYY-MM-DD')
    },
    {
      Header: 'Hora',
      accessor: '[timeFormat]',
      Cell: ({ row:{original} }) => moment(original.ap_from).format('HH:mm')
    },
    {
      Header: 'Profesional',
      accessor: 'specialist.user.name'
    },
    {
      Header: 'Estado',
      accessor: 'status.aps_status',
      Cell: ({ row:{original} }) => <span className={statusInfo[original.status.aps_code].badgeClass}>
        {original.status.aps_status}
      </span>
    }];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={appointments} />
    </div>
  )
}

AppointmentsTable.propTypes = {
  appointments: PropTypes.array,
};

AppointmentsTable.defaultProps = {
  appointments: []
}

export default AppointmentsTable;
