import http from "./axiosInstance";

/**
 * Create appointment
 * @param data
 * @returns {Promise<any>}
 */
export const createAppointment = (data) => {
  return http().post("/appointments", data);
};

/**
 * Update appointment
 * @param data
 * @returns {Promise<any>}
 */
export const updateAppointment = (data, id) => {
  return http().put(`/appointments/${id}`, data);
};

/**
 * Update appointment status
 * @param data
 * @returns {Promise<any>}
 */
export const updateAppointmentStatus = (data, id) => {
  return http().put(`/appointments/${id}/status`, data);
};

/**
 * Get appointment by id
 * @param id
 * @returns {Promise<any>}
 */
export const getAppointmentById = (id) => {
  return http().get(`/appointments/${id}`);
};

/**
 * Get all Appointments
 * @returns {Promise<any>}
 */
export const getAllAppointments = () => {
  return http().get("/appointments");
};

/**
 * Get Appointments schedule
 * @param id
 * @param from
 * @param to
 * @returns {Promise<any>}
 */
export const getAppointmentsSchedule = (id, from, to) => {
  const data = {
    from: from,
    to: to,
  };
  return http().post(`/appointments/specialist/${id}`, data);
};

/**
 * Get Appointments by dates for specialist
 * @returns {Promise<any>}
 */
export const searchConflictAppointment = (
  from,
  to,
  exclude = null,
  specialist = null
) => {
  const params = {
    exclude,
    specialist,
  };
  return http().get(`/appointments/date/${from}/${to}`, { params });
};

/**
 * Get all AppointmentStatus
 * @returns {Promise<any>}
 */
export const getAllAppointmentsStatus = () => {
  return http().get("/appointment-status");
};

/**
 * Get all appointments by patient
 * @returns {Promise<any>}
 */
 export const getAppointmentsPatient = (patientId, params) => {
  return http().get(`/appointments/patient/${patientId}`, { params });
};
