export const Svg1=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <line x1="30%" y1="70%" x2="15%" y2="50%" className={className}/>
              <line x1="30%" y1="75%" x2="88%" y2="10%" className={className}/>
            </svg>
          </div>
}

export const Svg2=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <line x1="50%" y1="-50%" x2="50%" y2="100%" className={className}/>
            </svg>
          </div>
}

export const Svg3=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <line x1="100%" y1="50%" x2="-100%" y2="50%" className={className}/>
            </svg>
          </div>
}

export const Svg4=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <line x1="80%" y1="50%" x2="16%" y2="50%" className={className}/>
            </svg>
          </div>
}

export const Svg5=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <circle cx="19.7" cy="19.4" r="10" stroke="#333" fill="transparent" strokeWidth="7"/>
            </svg>
          </div>
}

export const Svg6=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <text x="12" y="30" fontSize="1.8em" className={className}>S</text>
            </svg>
          </div>
}

export const Svg7=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <text x="12" y="30" fontSize="1.8em" className={className}>P</text>
            </svg>
          </div>
}

export const Svg8=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <circle cx="19.6" cy="19.5" r="8.5" stroke="#f2f2f2" fill="transparent" strokeWidth="6"/>
              <line x1="50%" y1="18%" x2="50%" y2="82%" className={className}/>
            </svg>
          </div>
}

export const Svg9=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <circle cx="19.6" cy="19.5" r="11" stroke="#588BF9" fill="transparent" strokeWidth="3"/>
            </svg>
          </div>
}

export const Svg10=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <circle cx="19.6" cy="19.5" r="11" stroke="#DF5C5C" fill="transparent" strokeWidth="3"/>
            </svg>
          </div>
}

export const Svg11=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <line x1="10%" y1="90%" x2="50%" y2="10%" className={className}/>
              <line x1="90%" y1="90%" x2="50%" y2="10%" className={className}/>
              <line x1="90%" y1="88%" x2="15%" y2="88%" className={className}/>
            </svg>
          </div>
}

export const Svg12=({className})=>{
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <line x1="15%" y1="85%" x2="88%" y2="10%" className={className}/>
              <line x1="88%" y1="85%" x2="12%" y2="10%" className={className}/>
            </svg>
          </div>
}

export const SvgCarillaColocada=({className})=>{
  const color = "#005fbf"
  const strokeWidth = "1.5"
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <g>
               <line x1="20" x2="20" y1="0" y2="7"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="17" x2="17" y1="0" y2="7"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="23" x2="23" y1="0" y2="7"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="26" x2="26" y1="1" y2="8"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="14" x2="14" y1="1" y2="8"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="11" x2="11" y1="2" y2="10"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="29" x2="29" y1="2" y2="10"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
              </g>

            </svg>
          </div>
}

export const SvgCarillaIndicada=({className})=>{
  const color = "#bf0000"
  const strokeWidth = "1.5"
  return  <div className={"dentsSVGSecondary"}>
            <svg height="100%" width="100%">
              <g>
               <line x1="20" x2="20" y1="0" y2="7"  strokeWidth={strokeWidth} stroke={color} fill="none" />
               <line x1="17" x2="17" y1="0" y2="7"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="23" x2="23" y1="0" y2="7"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="26" x2="26" y1="1" y2="8"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="14" x2="14" y1="1" y2="8"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="11" x2="11" y1="2" y2="10"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
               <line x1="29" x2="29" y1="2" y2="10"  strokeWidth={strokeWidth} stroke={color} fill="none"/>
              </g>
            </svg>
          </div>
}

export const SvgNucleoIndicado=({className})=>{
  const color = "#bf0000"
  const strokeWidth = "1.5"
  return  <div className={"dentsSVGSecondary"}>
            <svg height="40" width="100">
              <path d="m19.43083,20.99817l7.2333,15l-13.99994,0l6.76664,-13l0,0z" stroke="#000" fill={color} strokeWidth={strokeWidth}/>
            </svg>
          </div>
}

export const SvgNucleoColocado=({className})=>{
  const color = "green"
  const strokeWidth = "1.5"
  return  <div className={"dentsSVGSecondary"}>
            <svg height="40" width="100">
              <path d="m19.43083,20.99817l7.2333,15l-13.99994,0l6.76664,-13l0,0z" stroke="#000" fill={color} strokeWidth={strokeWidth}/>
            </svg>
          </div>
}

export const SvgFractura=({className})=>{
    const color = "black"
    const strokeWidth = "1.5"
    return  <div className={"dentsSVGSecondary"}>
        <svg height="40" width="100">
            <path d="m19.43083,20.99817l7.2333,15l-13.99994,0l6.76664,-13l0,0z" stroke="#000" fill={color} strokeWidth={strokeWidth}/>
        </svg>
    </div>
}


