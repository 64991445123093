import {createAsyncThunk} from '@reduxjs/toolkit';
import {authLogin, authLogout} from "../../http/api/session";

export const login = createAsyncThunk(
  'Session/login',
  async (user,{ rejectWithValue }) => {
    try {
      const { data } = await authLogin(user);
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err)
    }
  }
)

export const logout = createAsyncThunk(
  'Session/logout',
  async() => {
    return await authLogout();
  }
)
