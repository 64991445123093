import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import CustomTextInput from "../../common/CustomTextInput";
import CustomErrorMessage from "../../common/CustomErrorMessage";
import CustomTextarea from "../../common/CustomTextarea";
import NumberFormat from "react-number-format";
import PermissionWrapper from "../../common/PermissionWrapper";
import { CREATE_PAYMENT } from "../../../constants/permissions";

const numberFormat = new Intl.NumberFormat();

const PaymentForm = (props) => {
  const {
    patient,
    payment,
    patientOrders,
    paymentMethods,
    paymentConcepts,
    specialists,
    onSubmit,
  } = props;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("patientId", patient.id);
  }, [patient, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <CustomTextInput
            {...register("patient", { required: "Requerido" })}
            type="text"
            name="patient"
            id="patient"
            label="Paciente"
            errors={errors}
            defaultValue={patient?.pa_name}
            readOnly={true}
          />
        </div>
        <div className="mb-2">
          <Controller
            render={({ field }) => (
              <NumberFormat
                {...field}
                label="Valor a pagar"
                thousandSeparator={true}
                prefix="$ "
                customInput={CustomTextInput}
                defaultValue="0"
                errors={errors}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue("amount", value);
                }}
              />
            )}
            name="ammount"
            id="ammount"
            control={control}
            rules={{
              required: "Requerido",
            }}
          />
        </div>
        <div className="mb-2">
          <label
            htmlFor="specialistId"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Profesional
          </label>
          <select
            {...register("specialistId")}
            id="specialistId"
            name="specialistId"
            defaultValue={payment?.orp_specialist_id}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">-- Seleccione --</option>
            {specialists &&
              specialists.map((specialist) => (
                <option key={specialist?.id} value={specialist?.id}>
                  {specialist?.user?.name}
                </option>
              ))}
          </select>
          <CustomErrorMessage errors={errors} name="specialistId" />
        </div>
        <div className="mb-2">
          <label
            htmlFor="paymentMethodId"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Forma de pago*
          </label>
          <select
            {...register("paymentMethodId", { required: "Requerido" })}
            id="paymentMethodId"
            name="paymentMethodId"
            defaultValue={payment?.orp_payment_method_id}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">-- Seleccione --</option>
            {paymentMethods &&
              paymentMethods.map((method) => (
                <option key={method?.id} value={method?.id}>
                  {method?.pm_name}
                </option>
              ))}
          </select>
          <CustomErrorMessage errors={errors} name="paymentMethodId" />
        </div>
        <div className="mb-2">
          <label
            htmlFor="paymentConceptId"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Concepto*
          </label>
          <select
            {...register("paymentConceptId", { required: "Requerido" })}
            id="paymentConceptId"
            name="paymentConceptId"
            defaultValue={payment?.orp_payment_concept_id}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">-- Seleccione --</option>
            {paymentConcepts &&
              paymentConcepts.map((concept) => (
                <option key={concept?.id} value={concept?.id}>
                  {concept?.pc_name}
                </option>
              ))}
          </select>
          <CustomErrorMessage errors={errors} name="paymentConceptId" />
        </div>
        <div className="mb-2">
          <label
            htmlFor="paymentOrderId"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Orden afectada*
          </label>
          <select
            {...register("orderId", { required: "Requerido" })}
            id="orderId"
            name="orderId"
            defaultValue={payment?.orp_order_id}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">-- Seleccione --</option>
            {patientOrders &&
              patientOrders.map((order) => {
                const balance =
                  Number(order?.items_sum_ori_total) -
                  Number(order?.payments_sum_orp_amount);
                return (
                  <option key={order?.id} value={order?.id}>
                    {`${order?.id} --> Saldo pendiente: $${numberFormat.format(
                      balance
                    )}`}
                  </option>
                );
              })}
          </select>
          <CustomErrorMessage errors={errors} name="paymentOrderId" />
        </div>
        <div className="mb-2">
          <CustomTextarea
            {...register("observations")}
            id="observations"
            name="observations"
            label="Observaciones"
            defaultValue={payment?.orp_observations}
          />
        </div>
        <div className="mb-2">
          <PermissionWrapper permission={CREATE_PAYMENT} >
            <button type="submit" className="btn-secondary-dark">
              Crear
            </button>
          </PermissionWrapper>
        </div>
      </form>
    </>
  );
};

PaymentForm.propTypes = {
  patient: PropTypes.object.isRequired,
  payment: PropTypes.object,
  ordersPatient: PropTypes.array,
  paymentMethods: PropTypes.array,
  paymentConcepts: PropTypes.array,
  specialists: PropTypes.array,
  onSubmit: PropTypes.func,
};

PaymentForm.defaultProps = {
  ordersPatient: [],
  paymentMethods: [],
  paymentConcepts: [],
  specialists: [],
};

export default PaymentForm;
