export const adultTeenth=[
  [
    {
      label:18,
    },
    {
      label:17,
    },
    {
      label:16,
    },
    {
      label:15,
    },
    {
      label:14,
    },
    {
      label:13,
    },
    {
      label:12,
    },
    {
      label:11,
    },
  ],
  [
    {
      label:21,
    },
    {
      label:22,
    },
    {
      label:23,
    },
    {
      label:24,
    },
    {
      label:25,
    },
    {
      label:26,
    },
    {
      label:27,
    },
    {
      label:28,
    },
  ],
  [
    {
      label:48,
    },
    {
      label:47,
    },
    {
      label:46,
    },
    {
      label:45,
    },
    {
      label:44,
    },
    {
      label:43,
    },
    {
      label:42,
    },
    {
      label:41,
    },
  ],
  [
    {
      label:31,
    },
    {
      label:32,
    },
    {
      label:33,
    },
    {
      label:34,
    },
    {
      label:35,
    },
    {
      label:36,
    },
    {
      label:37,
    },
    {
      label:38,
    },
  ]

]

export const childishTeenth=[
  [
    {
      label:55,
    },
    {
      label:54,
    },
    {
      label:53,
    },
    {
      label:52,
    },
    {
      label:51,
    },
  ],[
    {
      label:61,
    },
    {
      label:62,
    },
    {
      label:63,
    },
    {
      label:64,
    },
    {
      label:65,
    },
  ],[
    {
      label:85,
    },
    {
      label:84,
    },
    {
      label:83,
    },
    {
      label:82,
    },
    {
      label:81,
    },
  ],[
    {
      label:71,
    },
    {
      label:72,
    },
    {
      label:73,
    },
    {
      label:74,
    },
    {
      label:75,
    },
  ]
]
