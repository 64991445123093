import React, { useEffect, useState } from "react";
import UserForm from "../components/users/UserForm";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import CustomModalContent from "../components/common/CustomModalContent";
import { createUser, getAllUsers, updateUser } from "../http/api/user";
import UsersTable from "../components/users/UsersTable";
import { IdentificationIcon, PlusCircleIcon } from "@heroicons/react/solid";
import PageTitle from "../components/common/PageTitle";
import UserFiltersForm from "../components/users/UserFiltersForm";
import GenericButton from "../components/common/GenericButton";
import useCheckPermission from "../hooks/useCheckPermission";
import BasicCard from "../components/common/BasicCard";
import CustomPagination from "../components/common/CustomPagination";
import useGetAllRoles from "../hooks/useGetAllRoles";
import useGetAllSpecialties from "../hooks/useGetAllSpecialties";
import { setLoaderStatus } from "../redux/loader/slice";
import { successAlert } from "../helpers/alerts";
import {
  CREATE_USER_PER,
  EDIT_USER_PER,
  SHOW_USER_PER,
} from "../constants/permissions";

const UsersPage = ({ location, history }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const createUserPermission = useCheckPermission(CREATE_USER_PER);
  const editUserPermission = useCheckPermission(EDIT_USER_PER);
  const showUserPermission = useCheckPermission(SHOW_USER_PER);
  const roles = useGetAllRoles();
  const specialties = useGetAllSpecialties();

  useEffect(() => {
    const getUsers = async () => {
      let params = queryString.parse(location.search);
      try {
        dispatch(setLoaderStatus(true));

        const {
          data: { data, per_page, current_page, total },
        } = await getAllUsers(params);
        setUsers(data);
        setActivePage(current_page);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getUsers();
  }, [dispatch, location.search, toggleSearch]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setUser({});
    setOpen(true);
  };
  const handleEdit = (user) => {
    setUser(user);
    setOpen(true);
  };

  const filterSearch = (filterData) => {
    let params = queryString.parse(location.search);
    params.search_by_string = filterData["searchByString"];
    delete params.page;
    //params.search_by_specialty = filterData["searchBySpecialty"];
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });

    setToggleSearch((prevState) => !prevState);
  };

  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const saveUser = async (formData) => {
    const data = {
      name: formData["name"],
      email: formData["email"],
      password: formData["password"],
      doctor: formData["doctor"],
      specialty: formData["specialtyId"],
      role: formData["roleId"],
      status: formData["status"],
    };

    try {
      dispatch(setLoaderStatus(true));
      if (!user.id) {
        const { message } = await createUser(data);
        successAlert(message);
      } else {
        data.id = user.id;
        const { message } = await updateUser(data, user.id);
        successAlert(message);
      }
      handleClose();
      setToggleSearch((prevState) => !prevState);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Usuarios" Icon={IdentificationIcon} />
      <BasicCard>
        <div className="pb-4 grid grid-cols-1 md:grid-cols-3">
          <div className="self-end md:mb-0 mb-2 col-span-2">
            <UserFiltersForm onSubmit={filterSearch} />
          </div>
          <div className="self-end flex justify-end">
            <GenericButton onClick={handleOpen} render={createUserPermission}>
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Crear
            </GenericButton>
            <CustomModalContent
              open={open}
              onClose={handleClose}
              title={user.id ? "Actualizar Usuario" : "Crear Usuario"}
              closeOnOverlayClick={false}
            >
              <div className="w-full md:w-72">
                <UserForm
                  editPermission={editUserPermission}
                  onSubmit={saveUser}
                  specialties={specialties}
                  user={user}
                  roles={roles}
                />
              </div>
            </CustomModalContent>
          </div>
        </div>
        <UsersTable
          users={users}
          showEdit={showUserPermission}
          onEdit={handleEdit}
        />
        <CustomPagination
          totalItemsCount={totalItemsCount}
          onChange={(page) => onChangePagination(page)}
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
        />
      </BasicCard>
    </div>
  );
};

export default withRouter(UsersPage);
