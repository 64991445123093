import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UsersIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";
import {createOdontogram, saveOdontogramNotes, updateOdontogramNotes} from "../http/api/odontogram";
import {
  getPatientOdontogramNotes,
  getPatientOdontograms,
  getPatientOdontogramsFirst,
  getPatientOdontogramsLast
} from "../http/api/patient";
import PageTitleDouble from "../components/common/PageTitleDouble";
import CustomCard from "../components/odontogram/CustomCard";
import CustomModalContent from "../components/common/CustomModalContent";
import { setLoaderStatus } from "../redux/loader/slice";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { successAlert } from "../helpers/alerts";
import OdontogramTable from "../components/odontogram/OdontogramTable";

import FormNotes from "../components/odontogram/FormNotes";
import CustomPagination from "../components/common/CustomPagination";
import InfoNotes from "../components/odontogram/InfoNotes";
import advertencia from "../assets/advertencia.svg";

const PatientOdontogramPage = ({ location, history }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [patientName, setPatientName] = useState([]);
  const [patientID, setPatientID] = useState({});
  const [odontogramForm, setOdontogramForm] = useState(false);
  const [odontogramFormNotes, setOdontogramFormNotes] = useState(false);
  const [optionSelect, setOptionSelect] = useState(false);
  const [data, setData] = useState({ adult: {activeG: [], activeSector: {}}, child:{activeG: [], activeSector: {}}, id: id });
  const [dataType, setDataType] = useState({ activeG: [], activeSector: {}, id: id });
  const [type, setType] = useState("adultTeenth");
  const [disableEdit, setDisableEdit] = useState(false);
  const [odontoNotes] = useState([]);
  const [activeNotes, setActiveNotes] = useState(false);
  const [bkData, setBkData] = useState({ adult: {activeG: [], activeSector: {}}, child:{activeG: [], activeSector: {}}, id: id });
  const [bkDataEdit, setBkDataEdit] = useState({ adult: {activeG: [], activeSector: {}}, child:{activeG: [], activeSector: {}}, id: id });
  const [reset, setReset] = useState(false);
  const [odontograms, setOdontograms] = useState([]);
  const [odontogramsNotesInfo] = useState([]);
  const [edit, setEdit] = useState(false);
  const [allowSendData, setAllowSendData] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const tab = '\u00A0';

  useEffect(() => {
    if (optionSelect && !allowSendData) {
      setAllowSendData(true);
    }
  }, [optionSelect]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    const getPatient = async () => {
      try {
        dispatch(setLoaderStatus(true));
        let params = queryString.parse(location.search);
        const {
          data: { pa_name, odontograms},
        } = await getPatientOdontograms(params, id);
        setPatientName(pa_name);
        setOdontograms(odontograms.data);
        setActivePage(odontograms.current_page);
        setItemsCountPerPage(odontograms.per_page);
        setTotalItemsCount(odontograms.total);
        setPatientID(id);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatient();
  }, [dispatch, id, location.search]);

  useEffect(() => {
  const getOdntoReference = async () => {
    try {
      dispatch(setLoaderStatus(true));
      let params = queryString.parse(location.search);
      const {
        data: { odontograms },
      } = await getPatientOdontogramsLast(params, id);
      const {
        data: { odontograms_first },
      } = await getPatientOdontogramsFirst(params, id);
      if(odontograms_first[0]!=null){
        setBkData(JSON.parse(odontograms[0].json));
        setBkDataEdit(JSON.parse(odontograms[0].json));
      }
      if(odontograms_first[0] !== odontograms[0]){
        odontogramsNotesInfo.push(odontograms_first[0]);
        odontogramsNotesInfo.push(odontograms[0]);
      }else {
        odontogramsNotesInfo.push(odontograms_first[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };
    getOdntoReference();
  }, [ id ]);


  useEffect(() => {
    const getOdntoNotes = async () => {
      try {
        dispatch(setLoaderStatus(true));
        let params = queryString.parse(location.search);
        const {
          data: { notas },
        } = await getPatientOdontogramNotes(params, id);
       
        odontoNotes.push(notas);
        setActiveNotes(notas.notes)

      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };
    getOdntoNotes();
  }, [ id ]);


  const handleOpenModalPatient = () => {
    setOdontogramForm(true);
  };

  const handleOpenModalNotes = () => {
    setOdontogramFormNotes(true);
  };

  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const handleCloseModalPatient = () => setOdontogramForm(false);
  const handleCloseModalNotes = () => setOdontogramFormNotes(false);

  const save = async () => {
    if (!allowSendData) {
      return false;
    }
    dispatch(setLoaderStatus(true));
    try {
      console.log('type',type);
      if(data.child.activeG.length===0 && Object.keys(data.child.activeSector).length===0){
        data.child = bkData.child
      }

      const { message } = await createOdontogram(data);
      successAlert(message);
      setOptionSelect(false);
      setReset(true);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoaderStatus(false));
      setTimeout(function() {
        window.location.reload();
      }, 2000);
    }
  };

  const saveNotes = async (formData) => {
    const data = {
      id: formData["id"],
      oc_odontograms_id: formData["oc_odontograms_id"],
      comment: formData["comment"],
      notes: formData["notes"]
    };
    try {
      dispatch(setLoaderStatus(true));
      if (odontoNotes[0] === null) {
        delete data.id;
        const { message } = await saveOdontogramNotes(data);
        successAlert(message);
      } else {
        const { message } = await updateOdontogramNotes(data);
        successAlert(message);
      }

    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
      setTimeout(function() {
        window.location.reload();
      }, 2000);
    }
  };

  const handleEdit = async (patient) => {};

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitleDouble
        title="Historial odontogramas"
        Icon={UsersIcon}
        secondaryTitle={
          <>
            <b>Paciente </b>
            {patientName}
          </>
        }
        components={[
           activeNotes ? (<button type="button" className="btn-alert" onClick={handleOpenModalNotes}>
             <img src={advertencia} width="20" height="20" style={{display:'inline-block'}}/>
                 {tab} Ver notas importantes
            </button>) : "",
          <button type="button" className="btn-secondary-dark" onClick={save}>
            {!edit ? "Guardar Odontograma" : "Guardar Odontograma"}
          </button>,
          <DotsVerticalIcon
            className="-ml-0.5 mr-2 w-8 ml-3 cursor-pointer "
            size={34}
            aria-hidden="true"
            onClick={handleOpenModalPatient}
          />,
        ]}
      />
      <CustomModalContent
        open={odontogramForm}
        onClose={handleCloseModalPatient}
        title={"  Opciones Odontograma"}
        closeOnOverlayClick={false}
      >
        <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <FormNotes
              setEdit={setEdit}
              selections={bkData}
              setData={setBkData}
              odontograms={odontogramsNotesInfo}
              onEdit={handleEdit}
              setDisableEdit={setDisableEdit}
              jsonEdit={bkDataEdit}
              odontoNotes={odontoNotes[0]}
              onSubmit={saveNotes}
              idPacient={patientID}
          />
        </div>
      </CustomModalContent>

      <CustomModalContent
          open={odontogramFormNotes}
          onClose={handleCloseModalNotes}
          title={"  Notas importantes del paciente"}
          closeOnOverlayClick={true}
      >
        <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <InfoNotes
              odontoNotes={odontoNotes[0]}
              onSubmit={handleCloseModalNotes}
              openEdit={handleOpenModalPatient}
          />
        </div>
      </CustomModalContent>
      {!reset ? (
        <div className="pb-4">
          <CustomCard
            allowSendData={allowSendData}
            setAllowSendData={setAllowSendData}
            active={optionSelect}
            bkData={bkData}
            setBkData={setBkData}
            setActive={setOptionSelect}
            data={data}
            setData={setDataType}
            setType={setType}
            displayNone={disableEdit}
          />
        </div>
      ) : (
        false
      )}
        <OdontogramTable
          setEdit={setEdit}
          selections={bkData}
          setData={setBkData}
          odontograms={odontograms}
          onEdit={handleEdit}
          setDisableEdit={setDisableEdit}
          jsonEdit={bkDataEdit}
        />
        <CustomPagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              onChange={(page) => onChangePagination(page)}
        />
    </div>
  );
};

export default withRouter(PatientOdontogramPage);
