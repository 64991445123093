import { useState, useEffect } from "react";
import { getAllPaymentsConcepts } from "../http/api/paymentConcepts";

function useGetAllPaymentsConcepts() {
  const [paymentConcepts, setPaymentConcepts] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getAllPaymentsConcepts();
      setPaymentConcepts(data);
    })();
  }, []);

  return paymentConcepts;
}

export default useGetAllPaymentsConcepts;
