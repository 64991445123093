export const discountTypes = [
  {
    key: 'porcentaje',
    name: 'Porcentaje'
  },
  {
    key: 'valor',
    name: 'Valor'
  }
]
