import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const TabsHeader = ({tabs}) => {
    return (
        <>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    defaultValue={tabs.find((tab) => tab.current).name}
                    onChange={(e) => {
                        tabs.forEach((item) => {
                            item.current = false
                        })
                        tabs.find((item) => item.name === e.target.value).current = true;
                    }}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <a
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                    tab.current
                                        ? 'border-secondary-dark text-secondary-dark'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                    'group inline-flex items-center pt-4 pb-2 px-1 border-b-2 font-medium text-sm'
                                )}
                                aria-current={tab.current ? 'page' : undefined}
                                onClick={() => {
                                    tabs.forEach((item) => {
                                        item.current = false
                                    })
                                    tabs.find((item) => item.name === tab.name).current = true;
                                }}
                            >
                                <tab.icon
                                    className={classNames(
                                        tab.current ? 'text-secondary-dark' : 'text-gray-400 group-hover:text-gray-500',
                                        '-ml-0.5 mr-2 h-5 w-5'
                                    )}
                                    aria-hidden="true"
                                />
                                <span>{tab.name}</span>
                            </a>
                        ))}
                    </nav>
                </div>
            </div>
        </>
    )
}

export default TabsHeader;
