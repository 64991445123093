import { useState, useEffect } from "react";
import { getAllPaymentsMethods } from "../http/api/paymentMethods";

function useGetAllPaymentsMethods() {
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getAllPaymentsMethods();
      setPaymentMethods(data);
    })();
  }, []);

  return paymentMethods;
}

export default useGetAllPaymentsMethods;
