import { createSlice } from "@reduxjs/toolkit";
import { login, logout } from "./thunk";

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    config: null,
    token: null,
    user: null,
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { user, access_token, config } = action.payload;
      state.user = user;
      state.token = access_token;
      state.config = config;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user = null;
      state.token = null;
      state.config = null;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.user = null;
      state.token = null;
      state.config = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.user = null;
      state.token = null;
      state.config = null;
    });
  },
});

export default sessionSlice.reducer;
