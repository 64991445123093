import { createSelector } from '@reduxjs/toolkit';

export const getPermissions = createSelector(
  state => state.session.user?.allPermissionsNames,
  v => v
);

export const getCurrentUser = createSelector(
  state => state.session.user,
  v => v
);

export const getCurrentConfig = createSelector(
  state => state.session.config,
  v => v
);

export const getToken = createSelector(
  state => state.session.token,
  v => v
);
