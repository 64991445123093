export const statusInfo = {
  CREATED: {
    color: '#3B82F6',
    badgeClass: 'badge-blue'
  },
  CONFIRMED: {
    color: '#10B981',
    badgeClass: 'badge-green'
  },
  CANCELLED: {
    color: '#991B1B',
    badgeClass: 'badge-red'
  },
  RESERVED: {
    color: '#9CA3AF',
    badgeClass: 'badge-gray'
  }
};
