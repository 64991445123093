import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import PaymentPlanForm from "../PaymentPlanForm";
import { createOrderPaymentPlan } from "../../../../http/api/orderPaymentPlan";
import PaymentPlanNewTable from "./PaymentPlanNewTable";
import { validatePaymentsByOrderId } from "../../../../http/api/orders";
import AddQuotaForm from "./AddQuotaForm";
import CustomModalContent from "../../../common/CustomModalContent";
import { setLoaderStatus } from "../../../../redux/loader/slice";
import { successAlert } from "../../../../helpers/alerts";
import PermissionWrapper from "../../../common/PermissionWrapper";
import { CREATE_PAYMENT_PLAN_ORDER } from "../../../../constants/permissions";

const PaymentPlanNew = ({ order, onSavePlan }) => {
  const [showInitialFee, setShowInitialFee] = useState(true);
  const [complete, setComplete] = useState(false);
  const [openModalAddQuota, setOpenModalAddQuota] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const [plan, setPlan] = useState({
    id: null,
    orderId: null,
    initialQuotaValue: 0,
    initialQuotaPercentage: 0,
    quotasNumber: 0,
    financeValue: 0,
    quotas: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setPlan((prevState) => ({
      ...prevState,
      orderId: order.id,
    }));
  }, [order]);

  useEffect(() => {
    const getShowInitialFee = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await validatePaymentsByOrderId(order.id);
        setShowInitialFee(data <= 0);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    if (order?.id) {
      getShowInitialFee();
    }
  }, [dispatch, order]);

  useEffect(() => {
    const arrayQuotas = [...plan.quotas];
    const lastItem = arrayQuotas.pop();
    setComplete(lastItem?.balance === 0);
  }, [plan]);

  const handleOpenModalAddQuota = () => setOpenModalAddQuota(true);
  const handleCloseModalAddQuota = () => setOpenModalAddQuota(false);

  const addQuota = (data) => {
    let arrayQuotas = [...plan.quotas];
    const newQuota = {
      quotaIndex: 0,
      quotaValue: Number(data.value),
      balance: 0,
    };

    arrayQuotas.splice(currentIndex + 1, 0, newQuota);
    calculateQuotas(arrayQuotas);
    handleCloseModalAddQuota();
  };

  const removeQuota = (rowQuota) => {
    let arrayQuotas = [...plan.quotas];
    arrayQuotas = arrayQuotas.filter(
      (quota) => quota.quotaIndex !== rowQuota.quotaIndex
    );

    calculateQuotas(arrayQuotas);
  };

  const calculateQuotas = (arrayQuotas) => {
    let i = 1;
    let balance = Number(plan.financeValue);
    arrayQuotas.map((item) => {
      balance -= Number(item.quotaValue);
      item.quotaIndex = i;
      item.balance = balance;
      i++;
    });

    setPlan((prevState) => ({
      ...prevState,
      quotas: arrayQuotas,
    }));
  };

  const generatePlan = (data) => {
    let financing;
    const arrayQuotas = [];
    const { initialQuotaPercentage, initialQuotaValue, quotas } = data;

    if (initialQuotaPercentage > 0) {
      financing =
        Number(order.balance) -
        (Number(order.balance) * Number(initialQuotaPercentage)) / 100;
    } else if (initialQuotaValue > 0) {
      financing = Number(order.balance) - Number(initialQuotaValue);
    } else {
      financing = Number(order.balance);
    }

    let balance = financing;
    const quotaValue = financing / quotas;
    const limit = Number(quotas) + 1;
    for (let i = 1; i < limit; i++) {
      balance -= Number(quotaValue);
      arrayQuotas.push({
        quotaIndex: i,
        quotaValue: quotaValue,
        balance,
      });
    }

    setPlan((prevState) => ({
      ...prevState,
      financeValue: financing,
      initialQuotaValue: Number(initialQuotaValue),
      initialQuotaPercentage: Number(initialQuotaPercentage),
      quotasNumber: Number(quotas),
      quotas: arrayQuotas,
    }));
  };

  const savePlan = async (plan) => {
    try {
      dispatch(setLoaderStatus(true));
      if (complete) {
        const { message } = await createOrderPaymentPlan(plan);
        successAlert(message);
        //if prop onSavePlan exist
        if (onSavePlan) {
          onSavePlan();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <>
      {order.id && (
        <div>
          <PaymentPlanForm
            orderId={order.id}
            total={Number(order.items_sum_ori_total)}
            balance={Number(order.balance)}
            defaultQuotes={plan.quotasNumber}
            defaultInitialQuotaPercentage={plan.initialQuotaPercentage}
            defaultInitialQuotaValue={plan.initialQuotaValue}
            financeValue={Number(plan.financeValue)}
            showInitialFee={showInitialFee}
            onSubmit={generatePlan}
          />

          {plan.quotas.length > 0 && (
            <>
              <hr className="border-1 border-gray-400 mt-4 mb-4" />
              <PaymentPlanNewTable
                quotas={plan.quotas}
                onRemove={removeQuota}
                onAdd={(index) => {
                  setCurrentIndex(index);
                  handleOpenModalAddQuota();
                }}
              />
              <CustomModalContent
                title="Agregar cuota"
                open={openModalAddQuota}
                onClose={handleCloseModalAddQuota}
                closeOnOverlayClick={false}
              >
                <AddQuotaForm onSubmit={addQuota} />
              </CustomModalContent>
              <PermissionWrapper permission={CREATE_PAYMENT_PLAN_ORDER}>
                <button
                  type="button"
                  className={`btn-secondary-dark ${
                    !complete && "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={() => savePlan(plan)}
                  disabled={!complete}
                >
                  Guardar
                </button>
              </PermissionWrapper>
            </>
          )}
        </div>
      )}
    </>
  );
};

PaymentPlanNew.propTypes = {
  order: PropTypes.object.isRequired,
  onSavePlan: PropTypes.func,
};

export default PaymentPlanNew;
