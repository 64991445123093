import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
  ACCESS_CONFIGURATION_MODULE,
  ACCESS_INSURER_MODULE,
  ACCESS_MEDIA_MODULE,
  ACCESS_PATIENT_MODULE,
  ACCESS_PATIENT_ORDERS_MODULE,
  ACCESS_PATIENT_PAYMENTS_MODULE,
  ACCESS_PATIENT_REPORTS_MODULE,
  ACCESS_PRODUCT_MODULE,
  ACCESS_REASON_MODULE,
  ACCESS_SCHEDULE_MODULE,
  ACCESS_SPECIALTY_MODULE,
  ACCESS_USER_MODULE,
  GENERATE_APPOINTMENTS_REPORT,
  SHOW_APPOINTMENTS_PATIENT,
  SHOW_CLINICAL_HISTORY,
} from "../constants/permissions";
import LoginPage from "../pages/LoginPage";
import DashboardLayoutRoute from "./DashboardLayoutRoute";
import HomePage from "../pages/HomePage";
import Users from "../pages/UsersPage";
import AppointmentsPage from "../pages/AppointmentsPage";
import OdontogramPage from "../pages/OdontogramPage";
import PatientsPage from "../pages/PatientsPage";
import PatientAppointmentsPage from "../pages/PatientAppointmentsPage";
import ClinicalHistoryPage from "../pages/ClinicalHistoryPage";
import ClinicalHistoryResumePage from "../pages/ClinicalHistoryResumePage";
import ReportAppointmentsPage from "../pages/ReportAppointmentsPage";
import ReportPatientsPage from "../pages/ReportPatientsPage";
import SpecialtiesPage from "../pages/SpecialtiesPage";
import InsurersPage from "../pages/InsurersPage";
import MediasPage from "../pages/MediasPage";
import ReasonsConsultationPage from "../pages/ReasonsConsultationPage";
import ConfigPage from "../pages/ConfigPage";
import NotFoundPage from "../pages/NotFoundPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import LoginRoute from "./LoginRoute";
import ProductsPage from "../pages/ProductsPage";
import PatientOrdersPage from "../pages/PatientOrdersPage";
import PatientPaymentsPage from "../pages/PatientPaymentsPage";
import OverlayLoadingSpinner from "../components/common/OverlayLoadingSpinner";
import { loaderStatusSelector } from "../redux/loader/selectors";
import UserPage from "../pages/UserPage";

const TenantRoutes = () => {
  const loaderStatus = useSelector(loaderStatusSelector);
  let { tenantId } = useParams();

  return (
    <>
      <OverlayLoadingSpinner active={loaderStatus} />
      <Router basename={tenantId}>
        <Switch>
          <LoginRoute path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />

          <DashboardLayoutRoute path="/" exact component={HomePage} />

          <DashboardLayoutRoute
            path="/user/settings"
            exact
            component={UserPage}
          />

          <DashboardLayoutRoute
            path="/users"
            exact
            component={Users}
            permission={ACCESS_USER_MODULE}
          />

          <DashboardLayoutRoute
            path="/appointments"
            exact
            component={AppointmentsPage}
            permission={ACCESS_SCHEDULE_MODULE}
          />

          <DashboardLayoutRoute
            path="/patients"
            exact
            component={PatientsPage}
            permission={ACCESS_PATIENT_MODULE}
          />
          <DashboardLayoutRoute
            path="/patients/:id/appointments"
            exact
            component={PatientAppointmentsPage}
            permission={SHOW_APPOINTMENTS_PATIENT}
          />
          <DashboardLayoutRoute
            path="/patients/:id/odontogram"
            exact
            component={OdontogramPage}
          />
          <DashboardLayoutRoute
            path="/patients/:id/clinical-history"
            exact
            component={ClinicalHistoryPage}
            permission={SHOW_CLINICAL_HISTORY}
          />
          <DashboardLayoutRoute
            path="/patients/:id/clinical-history/resume"
            exact
            component={ClinicalHistoryResumePage}
            permission={SHOW_CLINICAL_HISTORY}
          />
          <DashboardLayoutRoute
            path="/patients/:id/orders"
            exact
            component={PatientOrdersPage}
            permission={ACCESS_PATIENT_ORDERS_MODULE}
          />
          <DashboardLayoutRoute
            path="/patients/:id/payments"
            exact
            component={PatientPaymentsPage}
            permission={ACCESS_PATIENT_PAYMENTS_MODULE}
          />

          <DashboardLayoutRoute
            path="/report-appointments"
            exact
            component={ReportAppointmentsPage}
            permission={GENERATE_APPOINTMENTS_REPORT}
          />
          <DashboardLayoutRoute
            path="/report-patients"
            exact
            component={ReportPatientsPage}
            permission={ACCESS_PATIENT_REPORTS_MODULE}
          />

          <DashboardLayoutRoute
            path="/products"
            exact
            component={ProductsPage}
            permission={ACCESS_PRODUCT_MODULE}
          />
          <DashboardLayoutRoute
            path="/specialties"
            exact
            component={SpecialtiesPage}
            permission={ACCESS_SPECIALTY_MODULE}
          />
          <DashboardLayoutRoute
            path="/insurers"
            exact
            component={InsurersPage}
            permission={ACCESS_INSURER_MODULE}
          />
          <DashboardLayoutRoute
            path="/medias"
            exact
            component={MediasPage}
            permission={ACCESS_MEDIA_MODULE}
          />
          <DashboardLayoutRoute
            path="/reasons-consultation"
            exact
            component={ReasonsConsultationPage}
            permission={ACCESS_REASON_MODULE}
          />

          <DashboardLayoutRoute
            path="/config"
            exact
            component={ConfigPage}
            permission={ACCESS_CONFIGURATION_MODULE}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </>
  );
};

export default TenantRoutes;
