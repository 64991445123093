import React from 'react';
import moment from "moment";
import Table from "../common/Table";
import PropTypes from "prop-types";

const OdontogramTable = ({ odontograms, selections , setData ,setEdit, setDisableEdit, jsonEdit}) => {

  const onEdit=(data)=>{
    if (data.json) {
      try {
        let json = JSON.parse(data.json)
        let actualJson = jsonEdit;
        if (json) {
          if (JSON.stringify(json) === JSON.stringify(actualJson)) {
            setDisableEdit(false)
          }else {
            setDisableEdit(true)
          }
          setEdit(true)
          setData(json)
        }else{
          setEdit(false)
        }
      } catch (e) {
        setEdit(false)
        console.log(e);
      }
    }
  }

  const columns = [
    {
      Header: 'Fecha',
      accessor: '[dateFormat]',
      Cell: ({ row:{original} }) => moment(original.created_at).format('DD-MM-YYYY H:m:s')
    },
    {
      Header: '',
      accessor: '[options]',
      Cell: ({row: {original}}) => <div className="text-center">
        <button className="text-secondary-dark inline-block font-medium" onClick={() => onEdit(original)}>
          Ver
        </button>
      </div>
    }
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={odontograms} />
    </div>
  )
}

OdontogramTable.propTypes = {
  odontograms: PropTypes.array,
};

OdontogramTable.defaultProps = {
  odontograms: []
}

export default OdontogramTable;
