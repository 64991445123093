import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const appointmentReportPdf = (data) => {
  const appointmentReportDoc = [];
  const currentDate = new Date();
  const options = { year: "numeric", month: "2-digit", day: "numeric" };
  appointmentReportDoc.push({
    text: "Reporte General de Citas",
    style: "header",
    alignment: "center",
  });
  appointmentReportDoc.push({
    text: [{ text: currentDate.toLocaleDateString("es-CO", options) }],
  });
  data &&
    data.length > 0 &&
    data.forEach((d) => {
      if (d.appointments.length > 0) {
        appointmentReportDoc.push({
          text: [{ text: d.user.name, bold: true }],
          margin: [0, 15, 0, 5],
        });
        const body = [
          [
            { text: "Fecha", bold: true },
            { text: "Hora", bold: true },
            { text: "Duración", bold: true },
            { text: "Paciente", bold: true },
            { text: "Doc Id", bold: true },
            { text: "Teléfono", bold: true },
            { text: "Descripción", bold: true },
            { text: "Estado", bold: true },
          ],
        ];

        d.appointments.forEach((a) => {
          body.push([
            a.date,
            a.time,
            a.length,
            a.patient?.pa_name ? a.patient.pa_name : "",
            a.patient?.pa_document ? a.patient.pa_document : "",
            a.patient?.pa_phone ? a.patient.pa_phone : "",
            a.ap_description ? a.ap_description : "",
            a.status?.aps_status ? a.status.aps_status : "",
          ]);
        });

        appointmentReportDoc.push({
          table: {
            body,
            headerRows: 1,
          },
          layout: "noBorders",
        });
      }
    });

  const docDefinition = {
    header: [
      {
        image: "logo",
        width: 120,
        height: 100,
        margin: [40, 10, 200, 0],
      },
    ],
    pageOrientation: 'landscape',
    content: appointmentReportDoc,
    images: {
      logo: data?.logo,
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "justify",
        margin: [0, 30],
      },
      marginLeft: {
        margin: [8, 0, 0, 0],
      },
      marginConsultationParagraph: {
        margin: [8, 0, 0, 20],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download("Reporte general de citas");
};
