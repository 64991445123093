import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const paymentPlanPdf = (order, plan, tempQuotas, logo) => {
  const numberFormat = new Intl.NumberFormat();
  const quotas = tempQuotas.map((quota) => [
    quota.quotaIndex,
    `$ ${numberFormat.format(quota.quotaValue)}`,
    `$ ${numberFormat.format(quota.balance)}`,
    quota.quotaStatus === 1 ? '' : 'Cancelada'
  ]);
  let initialQuota = 0;
  if(plan.opp_initial_quota_percentage !== 0) {
    initialQuota = `${plan.opp_initial_quota_percentage} %`;
  }else if(plan.opp_initial_quota_value !== 0) {
    initialQuota = `$ ${numberFormat.format(plan.opp_initial_quota_value)}`;
  }
  const paymentPlanDoc = [];

  paymentPlanDoc.push({
    text: "PLAN DE PAGOS",
    style: "header",
    alignment: "center",
  });

  paymentPlanDoc.push({
    alignment: "justify",
    columns: [
      {
        layout: "noBorders",
        table: {
          body: [
            [{ text: "Orden Nº: ", bold: true }, order.id],
            [
              { text: "Valor Cuota Inicial: ", bold: true },
              initialQuota
            ],
            [
              { text: "Valor total: ", bold: true },
              `$ ${numberFormat.format(order.items_sum_ori_total)}`,
            ],
            [
              { text: "Cantidad de cuotas: ", bold: true },
              plan.opp_quotas_number,
            ],
          ],
        },
      },
      {
        table: {
          body: [
            [{ text: "Valor Total Orden: ", bold: true }, `$ ${numberFormat.format(order.items_sum_ori_total)}`],
            [
              { text: "Saldo Orden: ", bold: true },
              `$ ${numberFormat.format(order.balance)}`,
            ],
            [
              { text: "Valor Financiado: ", bold: true },
              `$ ${numberFormat.format(plan.opp_finance_value)}`,
            ],
          ],
        },
      },
    ],
  });

  paymentPlanDoc.push({
    margin: [0, 30, 0, 0],
    table: {
      headerRows: 1,
      widths: ['*', '*', '*', '*'],
      body: [
        [{text: 'Couta Numero', bold: true}, {text: 'Valor de la cuota', bold: true}, {text: 'Saldo Posterior Pago', bold: true}, {text: 'Estado', bold: true}],
        ...quotas
      ]
    }
  });

  const docDefinition = {
    header: [
      {
        image: "logo",
        width: 120,
        height: 100,
        margin: [40, 10, 200, 0],
      },
    ],
    content: paymentPlanDoc,
    images: {
      logo: logo,
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "justify",
        margin: [0, 30],
      },
      marginLeft: {
        margin: [8, 0, 0, 0],
      },
      marginConsultationParagraph: {
        margin: [8, 0, 0, 20],
      },
      marginButtonParagraph: {
        margin: [0, 0, 0, 20],
      },
      marginButtonHalfParagraph: {
        margin: [0, 0, 0, 5],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download(`Plan de pagos ${order.id}`);
};
