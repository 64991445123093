import { useState, useEffect } from "react";
import { getAllAgreements } from "../http/api/agreement";

function useAgreementsSelect() {
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getAllAgreements();

      //Structure data react select
      const newArray = data.map((item) => {
        return { value: item.id, label: item.ag_name };
      });

      setAgreements(newArray);
    })();
  }, []);

  return agreements;
}

export default useAgreementsSelect;
