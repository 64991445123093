import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import BasicCard from "../components/common/BasicCard";
import PageTitle from "../components/common/PageTitle";
import { setLoaderStatus } from "../redux/loader/slice";
import useCheckPermission from "../hooks/useCheckPermission";
import InsurerForm from "../components/insurers/InsurerForm";
import GenericButton from "../components/common/GenericButton";
import InsurersTable from "../components/insurers/InsurersTable";
import CustomPagination from "../components/common/CustomPagination";
import CustomModalContent from "../components/common/CustomModalContent";
import { IdentificationIcon, PlusCircleIcon } from "@heroicons/react/solid";
import InsurersFiltersForm from "../components/insurers/InsurersFiltersForm";
import { CREATE_INSURER_PER, EDIT_INSURER_PER } from "../constants/permissions";
import { successAlert } from "../helpers/alerts";
import {
  createInsurer,
  getInsurersAdmin,
  updateInsurer,
} from "../http/api/insurers";

const InsurersPage = ({ location, history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [insurer, setInsurer] = useState({});
  const [toggle, setToggle] = useState(false);
  const [insurers, setInsurers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);

  const createInsurerPermission = useCheckPermission(CREATE_INSURER_PER);
  const editInsurerPermission = useCheckPermission(EDIT_INSURER_PER);

  useEffect(() => {
    const getInsurers = async () => {
      let params = queryString.parse(location.search);
      try {
        dispatch(setLoaderStatus(true));
        const {
          data: { data, per_page, current_page, total },
        } = await getInsurersAdmin(params);
        setInsurers(data);
        setActivePage(current_page);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getInsurers();
  }, [dispatch, location.search, toggle]);

  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    setInsurer({});
    setOpen(true);
  };

  const handleEdit = (insurer) => {
    setInsurer(insurer);
    setOpen(true);
  };

  const filterSearch = (filterData) => {
    let params = queryString.parse(location.search);
    delete params.page;
    params.search_by_string = filterData["searchByString"];
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });

    setToggle((prevState) => !prevState);
  };

  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const saveInsurer = async (formData) => {
    const data = {
      name: formData["name"],
      description: formData["description"],
      status: formData["status"],
    };

    try {
      dispatch(setLoaderStatus(true));
      if (!insurer.id) {
        const { message } = await createInsurer(data);
        successAlert(message);
      } else {
        data.id = insurer.id;
        const { message } = await updateInsurer(data, insurer.id);
        successAlert(message);
      }

      handleClose();
      setToggle((prevState) => !prevState);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Aseguradoras" Icon={IdentificationIcon} />
      <BasicCard>
        <div className="pb-4 grid grid-cols-1 md:grid-cols-3">
          <div className="self-end md:mb-0 mb-2 col-span-2">
            <InsurersFiltersForm onSubmit={filterSearch} />
          </div>
          <div className="self-end flex justify-end">
            <GenericButton
              onClick={handleOpen}
              render={createInsurerPermission}
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Crear
            </GenericButton>
            <CustomModalContent
              open={open}
              onClose={handleClose}
              title={
                insurer.id ? "Actualizar Aseguradora" : "Crear Aseguradora"
              }
              closeOnOverlayClick={false}
            >
              <div className="w-full md:w-72">
                <InsurerForm
                  insurer={insurer}
                  editPermission={editInsurerPermission}
                  onSubmit={saveInsurer}
                />
              </div>
            </CustomModalContent>
          </div>
        </div>

        <InsurersTable insurers={insurers} onEdit={handleEdit} />
        <CustomPagination
          totalItemsCount={totalItemsCount}
          onChange={(page) => onChangePagination(page)}
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
        />
      </BasicCard>
    </div>
  );
};

export default withRouter(InsurersPage);
