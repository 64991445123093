import http from "./axiosInstance";

/**
 * Get cities by state code
 * 
 * @returns {Promise<any>}
 */
export const getCitiesByState = (stateCode) => {
  return http().get(`/cities/state/${stateCode}`);
};
