const strokeWidth=80
export const Svg01=({stroke,fill})=>{
  return    <>
              <line strokeWidth={strokeWidth}
                    y2="556.00439"
                    x2="132.16591"
                    y1="126.66908"
                    x1="561.50122"
                    stroke={stroke?stroke:"blue" }
                    fill={fill?fill:"none"}/>
              <line transform="rotate(-20.1682, 85.499, 490.671)"
                    y2="573.57887"
                    x2="116.86539"
                    y1="407.76263"
                    x1="54.13266"
                    strokeWidth={strokeWidth?strokeWidth:"60"}
                    stroke={stroke?stroke:"blue" }
                    fill={fill?fill:"none"}/>
            </>

}

export const Svg02=({stroke,fill})=>{
  return    <>
              <line strokeWidth={strokeWidth}
                    transform="rotate(-38, 100, 418.67)"
                    y2="833.33808"
                    x2="6.16566"
                    y1="204.00276"
                    x1="505.50096"
                    stroke={stroke?stroke:"green" }
                    fill={fill?fill:"none"}/>
            </>

}


export const Svg03=({stroke,fill})=>{
  return    <>
              <line strokeWidth={strokeWidth}
                    y2="680.00439"
                    x2="20.16591"
                    y1="126.66908"
                    x1="561.50122"
                    stroke={stroke?stroke:"red" }
                    fill={fill?fill:"none"}/>

              <line strokeWidth={strokeWidth}
                    y2="680.00439"
                    x2="561.16591"
                    y1="126.66908"
                    x1="20.50122"
                    stroke={stroke?stroke:"red" }
                    fill={fill?fill:"none"}/>
            </>

}

export const Svg04=({stroke,fill})=>{
  return    <>
              <line strokeWidth={strokeWidth}
                    transform="rotate(-38, 100, 418.67)"
                    y2="833.33808"
                    x2="6.16566"
                    y1="204.00276"
                    x1="505.50096"
                    stroke={stroke?stroke:"#000" }
                    fill={fill?fill:"none"}/>
            </>

}

export const Svg05=({stroke,fill})=>{
  return    <>
              <ellipse  ry="200"
                        rx="200"
                        cy="400"
                        cx="290"
                        strokeWidth={strokeWidth+50}
                        stroke="black"
                        fill="none"/>
            </>

}


export const Svg06=({stroke,fill})=>{
  return    <>
              <line strokeWidth={strokeWidth}
                    transform="rotate(0, 100, 300)"
                    y2="400"
                    x2="10"
                    y1="400"
                    x1="600"
                    stroke={stroke?stroke:"#000" }
                    fill={fill?fill:"none"}/>
            </>

}

export const Svg07=({stroke,fill})=>{
  return    <>
              <text textAnchor="start"
                    fontSize="700"
                    y="640"
                    x="100"
                    strokeWidth="15"
                    stroke="white"
                    filter="drop-shadow( 61px 61px 61px #000)"
                    fill="blue">S</text>
            </>

}

export const Svg08=({stroke,fill})=>{
  return    <>
              <text textAnchor="start"
                    fontSize="700"
                    y="640"
                    x="100"
                    strokeWidth="15"
                    stroke="white"
                    filter="drop-shadow( 61px 61px 61px #000)"
                    fill="red">S</text>
            </>

}

export const Svg09=({stroke,fill})=>{
  return    <>
              <text textAnchor="start"
                    fontSize="700"
                    y="640"
                    x="100"
                    strokeWidth="15"
                    stroke="white"
                    filter="drop-shadow( 61px 61px 61px #000)"
                    cursor="pointer"
                    fill="blue">P</text>
      </>

}

export const Svg110=({stroke,fill})=>{
  return    <>
      <text textAnchor="start"
            fontSize="700"
            y="640"
            x="100"
            strokeWidth="15"
            stroke="white"
            filter="drop-shadow( 31px 31px 31px #000)"
            fill="red">P</text>
            </>

}

export const Svg111=({stroke,fill})=>{
  return    <>
              <line transform="rotate(136, 270, 400)"
                    strokeWidth={strokeWidth}
                    y2="580"
                    x2="80"
                    y1="200"
                    x1="440"
                    stroke="blue"
                    filter="drop-shadow( 3px 4px 5px #000)"
                    fill="none"/>
            </>

}

export const Svg112=({stroke,fill})=>{
  return    <>
              <line transform="rotate(136, 270, 400)"
                    strokeWidth={strokeWidth}
                    y2="580"
                    x2="80"
                    y1="200"
                    x1="440"
                    stroke="red"
                    fill="none"/>
            </>

}

export const Svg113=({stroke,fill})=>{
  return    <>
              <ellipse  ry="250"
                        rx="250"
                        cy="400"
                        cx="290"
                        strokeWidth={strokeWidth-20}
                        stroke="#588BF9"
                        fill="none"
                        filter="drop-shadow( 3px 3px 31px #FFFFFF)"
                      />
            </>

}

export const Svg114=({stroke,fill})=>{
  return    <>
              <ellipse  ry="250"
                        rx="250"
                        cy="400"
                        cx="290"
                        strokeWidth={strokeWidth-20}
                        stroke="#DF5C5C"
                        filter="drop-shadow( 31px 31px 31px #FFFFFF)"
                        fill="none"/>
            </>

}

export const Svg115=({stroke,fill})=>{
  return    <>
              <path   d="m284.6975,158.99816l246.96665,439.00001l-477.99997,0l231.03332,-439.00001z"
                      strokeWidth={strokeWidth}
                      stroke="#050d66"
                      filter="drop-shadow( 0px 10px 40px #FFFFFF)"
                      fill="none"/>
            </>

}

export const Svg116=({stroke,fill})=>{
  return    <>
              <path   d="m284.6975,158.99816l246.96665,439.00001l-477.99997,0l231.03332,-439.00001z"
                      strokeWidth={strokeWidth}
                      stroke="#7f1d1d"
                      filter="drop-shadow( 0px 10px 40px #FFFFFF)"
                      fill="none"/>
            </>

}

export const Svg117=({stroke,fill})=>{
  return    <>
              <line strokeWidth="40"  y2="157" x2="51.5" y1="95" x1="50.5" stroke="blue" fill="none"/>



              <line strokeWidth="40"  y2="188.33337" x2="131.49833" y1="45.33473" x1="130.49833" stroke="blue" fill="none"/>



              <line strokeWidth="40"  y2="158.33587" x2="211.49166" y1="15.33723" x1="210.49166" stroke="blue" fill="none"/>



              <line strokeWidth="40"  y2="148.3367" x2="291.485" y1="5.33806" x1="290.485" stroke="blue" fill="none"/>



              <line strokeWidth="40"  y2="158.33587" x2="371.47833" y1="15.33723" x1="370.47833" stroke="blue" fill="none"/>



              <line strokeWidth="40"  y2="194.99948" x2="451.47166" y1="52.00084" x1="450.47166" stroke="blue" fill="none"/>



              <line strokeWidth="40"  y2="163.33545" x2="531.46499" y1="94.99726" x1="530.46499" stroke="blue" fill="none"/>
            </>

}


export const Svg118=({stroke,fill})=>{
  return    <>
              <line strokeWidth="40"  y2="157" x2="51.5" y1="95" x1="50.5" stroke="red" fill="none"/>



              <line strokeWidth="40"  y2="188.33337" x2="131.49833" y1="45.33473" x1="130.49833" stroke="red" fill="none"/>



              <line strokeWidth="40"  y2="158.33587" x2="211.49166" y1="15.33723" x1="210.49166" stroke="red" fill="none"/>



              <line strokeWidth="40"  y2="148.3367" x2="291.485" y1="5.33806" x1="290.485" stroke="red" fill="none"/>



              <line strokeWidth="40"  y2="158.33587" x2="371.47833" y1="15.33723" x1="370.47833" stroke="red" fill="none"/>



              <line strokeWidth="40"  y2="194.99948" x2="451.47166" y1="52.00084" x1="450.47166" stroke="red" fill="none"/>



              <line strokeWidth="40"  y2="163.33545" x2="531.46499" y1="94.99726" x1="530.46499" stroke="red" fill="none"/>
            </>

}


export const Svg119=({stroke,fill})=>{
  return    <>
              <path   strokeWidth={strokeWidth-50}
                      fill="green"
                      stroke="#000"
                      d="m287.64749,527.99816l140.01664,234.99999l-270.99994,0l130.98331,-234.99999z"/>
            </>

}

export const Svg169=({stroke,fill})=>{
    return    <>
        <path   strokeWidth={strokeWidth-50}
                fill="black"
                stroke="#000"
                d="m287.64749,527.99816l140.01664,234.99999l-270.99994,0l130.98331,-234.99999z"/>
    </>

}


export const Svg120=({stroke,fill})=>{
  return    <>
              <path   strokeWidth={strokeWidth-50}
                      fill="red"
                      stroke="#000"
                      d="m287.64749,527.99816l140.01664,234.99999l-270.99994,0l130.98331,-234.99999z"/>
            </>

}
// copiar
export const SvgResinaIndicada=({stroke,fill})=>{
  const color = "#84b0e2"
  const strokeWidth = "1.5"
  return    <svg width="20" height="20" preserveAspectRatio="xMinYMin slice" overflow="visible">
               <g id="Layer_1">
                <line x2="5" y1="5" y2="1" x1="1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="10" y1="10" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="15" y1="15" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="6.1" x1="6.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="12.1" x1="12.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
               </g>
               <g id="Layer_2" transform="rotate(-90,10,10)">
                <line x2="5" y1="5" y2="1" x1="1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="10" y1="10" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="15" y1="15" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="6.1" x1="6.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="12.1" x1="12.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
               </g>
               <rect height="20" width="20" y="0" x="0" strokeWidth="2" stroke="#000000" fill="none"/>
              </svg>

}

export const SvgResinaColocada=({stroke,fill})=>{
  const color = "#bf0000"
  const strokeWidth = "1.5"
  return    <svg width="20" height="20" preserveAspectRatio="xMinYMin slice" overflow="visible">
               <g id="Layer_1">
                <line x2="5" y1="5" y2="1" x1="1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="10" y1="10" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="15" y1="15" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="6.1" x1="6.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="12.1" x1="12.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
               </g>
               <g id="Layer_2" transform="rotate(-90,10,10)">
                <line x2="5" y1="5" y2="1" x1="1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="10" y1="10" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="15" y1="15" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="1.1" x1="1.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="6.1" x1="6.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
                <line x2="20" y1="20" y2="12.1" x1="12.1" strokeWidth={strokeWidth} stroke={color} fill="none"/>
               </g>
               <rect height="20" width="20" y="0" x="0" strokeWidth="2" stroke="#000000" fill="none"/>
              </svg>

}
