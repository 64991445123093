import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getPatientClinicalHistory } from "../http/api/patient";
import PageTitle from "../components/common/PageTitle";
import { UsersIcon } from "@heroicons/react/solid";
import PatientPayments from "../components/orders/PatientPayments";
import BasicCard from "../components/common/BasicCard";
import { setLoaderStatus } from "../redux/loader/slice";

const PatientPaymentsPage = () => {
  const { id } = useParams();
  const [patient, setPatient] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const getPatient = async (patientId) => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getPatientClinicalHistory(patientId);
        setPatient(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatient(id);
  }, [dispatch, id]);

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      {patient.id && (
        <>
          <PageTitle title="Abonos del paciente" Icon={UsersIcon} />
          <BasicCard>
            <PatientPayments patient={patient} />
          </BasicCard>
        </>
      )}
    </div>
  );
};

export default PatientPaymentsPage;
