import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Table from "./Table";

const EntitySelector = ({columns, entities, onSelect, msgButton}) => {
  const [entity, setEntity] = useState({});
  columns.push({
    Header: '',
    accessor: '[radio]',
    Cell: ({ row:{original} }) => <input type="radio" name="patientSelect" onClick={() => setEntity(original)}/>
  })

  return (
    <div>
      <div className="table-responsive">
        <Table columns={columns} data={entities} />
      </div>

      <div className="mt-3 flex justify-end">
        <button
          type="button"
          className="btn-secondary-dark"
          onClick={() => onSelect(entity)}
        >
          {msgButton}
        </button>
      </div>
    </div>
  )
}

EntitySelector.propTypes = {
  columns: PropTypes.array.isRequired,
  entities: PropTypes.array,
  onSelect: PropTypes.func,
  msgButton: PropTypes.string
};

EntitySelector.defaultProps = {
  msgButton: 'Seleccionar'
}

export default EntitySelector;
