import http from "./axiosInstance";

/**
 * Create Insurer
 *
 * @param data
 * @returns {Promise<any>}
 */
export const createInsurer = (data) => {
  return http().post("/insurers", data);
};

/**
 * Update Insurer by id
 *
 * @param data
 * @param specialty id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateInsurer = (data, id) => {
  return http().put(`/insurers/${id}`, data);
};

/**
 * Get all Insurers
 * @returns {Promise<any>}
 */
export const getAllInsurers = () => {
  return http().get("/insurers");
};

/**
 * Get Insurers admin
 * @returns {Promise<any>}
 */
export const getInsurersAdmin = (params = {}) => {
  return http().get("/insurers/admin", { params });
};
