import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { getPatientById } from "../../../http/api/patient";
import useAgreementsSelect from "../../../hooks/useAgreementsSelect";
import OrderForm from "./OrderForm";
import { setLoaderStatus } from "../../../redux/loader/slice";
import { successAlert } from "../../../helpers/alerts";
import {
  createOrder,
  getOrderTotal,
  updateOrder,
} from "../../../http/api/orders";

const Order = ({ patientId, orderId, onSaveOrder }) => {
  const [currentOrderId, setCurrentOrderId] = useState(orderId);
  const [patient, setPatient] = useState({});
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);

  const dispatch = useDispatch();
  const agreements = useAgreementsSelect();

  useEffect(() => {
    const getPatientData = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getPatientById(patientId);
        setPatient(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatientData();
  }, [dispatch, patientId]);

  useEffect(() => {
    const getOrderData = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data: orderData } = await getOrderTotal(currentOrderId);
        setOrder(orderData);

        const items = orderData.items.map((item) => {
          return {
            productId: item.ori_product_id,
            productName: item.product.pr_name,
            unitValue: item.ori_unit_value,
            quantity: item.ori_quantity,
            subtotal: item.ori_subtotal,
            discountType: item.ori_discount_type,
            discountApplied: item.ori_discount_applied,
            discount: item.ori_discount,
            total: item.ori_total,
          };
        });
        setOrderItems(items);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    if (currentOrderId) {
      getOrderData(currentOrderId);
    }
  }, [dispatch, currentOrderId]);

  const saveOrder = async (data) => {
    const items = data.items.map((item) => {
      return {
        product_id: item.productId,
        unit_value: item.unitValue,
        quantity: item.quantity,
        subtotal: item.subtotal,
        discount_type: item.discountType,
        discount: item.discount || 0,
        discount_applied: item.discountApplied,
        total: item.total,
      };
    });

    const formatData = {
      patient_id: data?.patientId,
      agreement_id: data?.agreementId,
      insurer_id: data?.insurerId,
      concept: data?.concept,
      recommendations: data?.recommendations,
      items,
    };

    try {
      dispatch(setLoaderStatus(true));
      if (order.id) {
        const { message } = await updateOrder(order.id, formatData);
        successAlert(message);
      } else {
        const { message, data: createdOrder } = await createOrder(formatData);
        setCurrentOrderId(createdOrder.id);
        successAlert(message);
      }

      if (onSaveOrder) {
        onSaveOrder();
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <OrderForm
      order={order}
      orderItems={orderItems}
      patient={patient}
      agreements={agreements}
      onSubmit={saveOrder}
    />
  );
};

Order.propTypes = {
  patientId: PropTypes.number.isRequired,
  orderId: PropTypes.number,
  onSaveOrder: PropTypes.func,
};

export default Order;
