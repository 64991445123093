import React, { useState } from "react";import {useForm} from "react-hook-form";

import CustomTextarea from "../common/CustomTextarea";


const InfoNotes = ({ odontoNotes, onSubmit, openEdit}) => {

    const tab = '\u00A0';

    return (
        <div>
                <div className="mb-3">
                    {<CustomTextarea
                        id="description"
                        name="comment"
                        placeholder="Ingrese aqui las notas importantes a tener en cuenta"
                        defaultValue={odontoNotes?.comment}
                        disabled="disabled"
                    />}
                </div>
            <p>Fecha última modificación:  {odontoNotes?.updated_at}</p>
            <br></br>
                <div className="mb-3">
                   <button onClick={onSubmit} className="btn-secondary-dark">
                        Ok entiendo
                    </button>
                    {tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}
                    {tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}{tab}
                    <button className="btn-alert" onClick={openEdit}>Modificar nota</button>
                </div>

        </div>

    );
}
export default InfoNotes;