import http from "./axiosInstance";

/**
 * Create odontogram
 * @param data
 * @returns {Promise<any>}
 */
export const createOdontogram = (data) => {
  return http().post("/odontogram", data);
};

/**
 * Update odontogram by id
 *
 * @param data
 * @param user id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateOdontogram = (data, id) => {
  return http().put(`/odontogram/${id}`, data);
};

/**
 * Get odontogram by id
 *
 * @param user id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOdontogram = (id) => {
  return http().get(`/odontogram/get/${id}`);
};

/**
 * Get list odontograms by user
 *
 * @param data
 * @param user id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOdontograms = (id) => {
  return http().get(`/odontogram/getAll/${id}`);
};

export const saveOdontogramNotes = (data) => {
  return http().post("/odontogramnotes", data);
};

export const updateOdontogramNotes = (data) => {
  return http().put("/updateodontogramnotes", data);
};


