import React from 'react';
import PropTypes from "prop-types";
import Table from "../common/Table";

const ReasonsConsultationTable = ({ reasons, onEdit }) => {
  const columns = [
    {
      Header: "Motivo",
      accessor: "rc_name",
    },
    {
      Header: "Estado",
      accessor: "rc_status",
      Cell: ({ row: { original } }) => (
        <span className={original.rc_status ? "badge-green" : "badge-gray"}>
          {original.rc_status ? "Activado" : "Desactivado"}
        </span>
      ),
    },
    {
      Header: "",
      accessor: "[editButton]",
      Cell: ({ row: { original } }) => (
        <div className="text-center">
          <button
            className="text-secondary-dark inline-block font-medium"
            onClick={() => onEdit(original)}
          >
            Editar
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={reasons} />
    </div>
  )
}

ReasonsConsultationTable.propTypes = {
  reasons: PropTypes.array,
  onEdit: PropTypes.func,
};

ReasonsConsultationTable.defaultProps = {
  reasons: []
}

export default ReasonsConsultationTable;
