import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ChevronLeftIcon, PencilAltIcon } from "@heroicons/react/solid";
import { getPatientClinicalHistory } from "../http/api/patient";
import ConsultationsList from "../components/clinicalHistory/ConsultationsList";
import CustomModalContent from "../components/common/CustomModalContent";
import ConsultationForm from "../components/consultations/ConsultationForm";
import { createConsultation, updateConsultation } from "../http/api/consultation";
import ConsultationDetail from "../components/clinicalHistory/ConsultationDetails";
import { consultationResumePdf } from "../components/reports/consultationResumePdf";
import MessageBox from "../components/common/MessageBox";
import useAgreementsSelect from "../hooks/useAgreementsSelect";
import { setLoaderStatus } from "../redux/loader/slice";
import { successAlert } from "../helpers/alerts";
import { getCurrentConfig } from "../redux/session/selectors";
import PermissionWrapper from "../components/common/PermissionWrapper";
import { CREATE_CONSULTATION, EDIT_CONSULTATION } from "../constants/permissions";

const ClinicalHistoryPage = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [patient, setPatient] = useState({});
  const [consultation, setConsultation] = useState({});
  const [consultations, setConsultations] = useState([]);
  const [toggleSearch, setToggleSearch] = useState(false);

  const dispatch = useDispatch();
  const config = useSelector(getCurrentConfig);
  const agreements = useAgreementsSelect();

  useEffect(() => {
    const getPatient = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getPatientClinicalHistory(id);
        setPatient(data);
        setConsultations(data.clinical_history?.consultations);
        setConsultation(data.clinical_history?.consultations[0]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatient();
  }, [dispatch, id, toggleSearch]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const saveConsultation = async (formData) => {
    const data = {
      clinical_history_id: patient.clinical_history?.id,
      agreement_id: formData["agreementId"],
      process: formData["process"],
      next_appointment: formData["nextAppointment"],
      diagnostic: formData["diagnostic"],
      reason: formData["reason"],
      current_illness: formData["currentIllness"],
      review_system: formData["reviewSystem"],
      physical_exam: formData["physicalExam"],
      weight: formData["weight"],
      waist: formData["waist"],
      umbilical: formData["umbilical"],
      infra_umbilical: formData["infraumbilical"],
    };

    try {
      dispatch(setLoaderStatus(true));
      if(consultation?.id){
        const { message, data: updatedData } = await updateConsultation(data, consultation?.id);
        setConsultation(updatedData)
        successAlert(message);
      } else {
        const { message } = await createConsultation(data);
        setToggleSearch((prevState) => !prevState);
        successAlert(message);
      }
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  const generateReport = () => {
    try {
      dispatch(setLoaderStatus(true));
      consultationResumePdf({
        logo: config?.urlLogo,
        paName: patient.pa_name,
        paDocument: patient.pa_document,
        consultations: patient?.clinical_history?.consultations,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <>
      <div className="flex-shrink-0 bg-white border-b border-gray-200 py-4 px-2">
        <span className="relative z-0 inline-flex shadow-sm">
          <PermissionWrapper permission={CREATE_CONSULTATION}>
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500-500 focus:border-blue-500"
              onClick={() => {
                handleOpen();
                setConsultation({});
              }}
            >
              Crear
            </button>
          </PermissionWrapper>
          <Link
            to={`/patients/${patient.id}/clinical-history/resume`}
            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500-500 focus:border-blue-500"
          >
            Ver
          </Link>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500-500 focus:border-blue-500"
            onClick={() => generateReport()}
          >
            Imprimir
          </button>
        </span>
        <CustomModalContent
          open={open}
          onClose={handleClose}
          title="Crear consulta"
          closeOnOverlayClick={false}
        >
          <div className="w-full">
            <ConsultationForm
              agreements={agreements}
              consultation={consultation}
              onSubmit={saveConsultation}
            />
          </div>
        </CustomModalContent>
      </div>
      {consultations.length < 1 && (
        <MessageBox
          message={`El paciente ${patient.pa_name} no registra consultas.`}
        />
      )}
      {consultations.length > 0 && (
        <div className="h-screen flex overflow-hidden bg-white">
          <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
            <div className="flex-1 relative z-0 flex overflow-hidden">
              <aside className="hidden xl:flex xl:flex-col flex-shrink-0 w-72 border-r border-gray-200">
                <div className="px-6 pt-6 pb-4 border-b border-gray-200">
                  <h1 className="text-xl font-medium text-secondary-dark">
                    Historia clínica
                  </h1>
                  <h2 className="text-md font-medium text-gray-600">
                    {patient.pa_name}
                  </h2>
                </div>
                <nav
                  className="flex-1 min-h-0 overflow-y-auto"
                  aria-label="Consultas"
                >
                  <div className="relative">
                    <ConsultationsList
                      selectedId={consultation?.id}
                      consultations={consultations}
                      onClick={setConsultation}
                    />
                  </div>
                </nav>
              </aside>

              <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                <nav
                  className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden"
                  aria-label="Breadcrumb"
                >
                  <button className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                    <ChevronLeftIcon
                      className="-ml-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <div>
                      <h1 className="text-lg font-medium text-secondary-dark">
                        Historia clínica
                      </h1>
                      <h2 className="text-md font-medium text-gray-600">
                        {patient.pa_name}
                      </h2>
                    </div>
                  </button>
                </nav>
                <article>
                  <div className="flex justify-end pt-4 pr-4 cursor-pointer">
                    <PermissionWrapper permission={EDIT_CONSULTATION}>
                      <PencilAltIcon onClick={handleOpen} className="-ml-0.5 mr-2 h-4 w-4" />
                    </PermissionWrapper>
                  </div>
                  <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <ConsultationDetail consultation={consultation} />
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClinicalHistoryPage;
