import http from "./axiosInstance";

/**
 * Create extraordinary Payment
 */
export const createExtraordinaryPayment = (data) => {
  return http().post(`/order-payments/extraordinary-pay`, data);
}

/**
 * Create quota payment
 */
export const createPaymentQuota = (data, quotaId) => {
  return http().post(`/order-payments/payment-quota/${quotaId}`, data);
}

/**
 * Get Payments By Patient
 */
export const getPaymentsByPatient = (patientId, params) => {
  return http().get(`/order-payments/patient/${patientId}`, {params});
}

/**
 * Get payment information for print
 *
 * @param paymentId
 * @returns {*}
 */
export const getPaymentPrintData = (paymentId) => {
  return http().get(`/order-payments/${paymentId}/print`);
}
