import React from "react";
import Table from "../common/Table";
import PropTypes from "prop-types";

const InsurersTable = ({ insurers, onEdit }) => {
  const columns = [
    {
      Header: "Aseguradora",
      accessor: "in_name",
    },
    {
      Header: "Estado",
      accessor: "in_status",
      Cell: ({ row: { original } }) => (
        <span className={original.in_status ? "badge-green" : "badge-gray"}>
          {original.in_status ? "Activado" : "Desactivado"}
        </span>
      ),
    },
    {
      Header: "",
      accessor: "[editButton]",
      Cell: ({ row: { original } }) => (
        <div className="text-center">
          <button
            className="text-secondary-dark inline-block font-medium"
            onClick={() => onEdit(original)}
          >
            Editar
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={insurers} />
    </div>
  );
};

InsurersTable.propTypes = {
  insurers: PropTypes.array,
  onEdit: PropTypes.func,
};

InsurersTable.defaultProps = {
  insurers: [],
};

export default InsurersTable;
