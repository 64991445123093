import http from "./axiosInstance";

/**
 * Create Payment
 */
export const createOrderPaymentPlan = (data) => {
  return http().post("/order-payments/plan", data);
};

/**
 * Get Active Plan By Order
 *
 * @param orderId
 * @returns {*}
 */
export const getActivePlanByOrder = (orderId) => {
  return http().get(`/order-payments/plan/order/${orderId}`);
};

/**
 * Update plan status
 *
 * @param planId
 * @param data
 * @returns {*}
 */
export const updateStatusPlan = (planId, status) => {
  const data = {
    status,
  };
  return http().put(`/order-payments/plan/${planId}/status`, data);
};
