import { useState, useEffect } from "react";
import { getAllRoles } from "../http/api/role";

function useGetAllRoles() {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getAllRoles();
      setRoles(data);
    })();
  }, []);

  return roles;
}

export default useGetAllRoles;
