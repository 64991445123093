import React, { useState } from "react";
import dentsList from './ConstsDents'
import secondarySet from './ConstsDentsSecondary'
import {adultTeenth,childishTeenth} from '../../constants/teeth'
import {ReactComponent as ReactLogo} from '../../assets/diente_contorno_ negro_fondos_blancos.svg'
import { RefreshIcon } from "@heroicons/react/solid";
import CustomModalContent from "../../components/common/CustomModalContent";
import './css.css';

import {One} from '../../components/odontogram/Dents'

const classStyle=[
  "col-span-2 text-center border-b-2 border-r-2 border-dark-600 pb-5",
  "col-span-2 text-center pb-5 border-b-2 border-dark-600",
  "col-span-2 text-center border-r-2 border-dark-600 pb-5",
  "col-span-2 text-center pb-5",
]



const SvgDent=({Src,className})=>{
  return  <div className="container-dentsSVG">
            {
              Src?<>
                {<Src className={className}/>}
              </>:false
            }
            <ReactLogo className="dents dentsSVG"/>
          </div>
}
const tab = '\u00A0';
const Options=({setOdontogramAlert,handleSetAllEdentulous})=>{
  return  <div className="text-center">
            <div className="btn-secondary-dark cursor m-1" onClick={handleSetAllEdentulous}>Si</div>
            <div className="btn-secondary-dark cursor m-1" onClick={()=>setOdontogramAlert(true)}>No</div>
          </div>
}

const App=({active,setActive,data,setData,bkData,setBkData,displayNone,setType})=>{
  const [switchOdontogram, setSwitchOdontogram] = useState("adultTeenth");
  const [odontogramAlert, setOdontogramAlert] = useState({value:false,label:""});
  const [allEdentulous, setAllEdentulous] = useState(false);

 let [updateAt, setUpdateAt] = useState("");

  const handleSelect=(row,type,src)=>{
    if (row.confirm) {
      const result  =   dentsList.find((search)=>search.alias===row.confirm.action)
      setActive({...result,type:2,src:result.src})
      return setOdontogramAlert({value:true,label:row.confirm.label,...row.confirm});
    }
    if (row.label===active.label) {
      setActive(false)
    }else {
      setActive({...row,type:type,src:src})
    }

  }
  const handleCloseModal = () => setOdontogramAlert({value:false,label:""});
  const handleSetAllEdentulous = () => {
    setAllEdentulous({...odontogramAlert})
    handleCloseModal()
  }



  return  <>
            <CustomModalContent
              open={odontogramAlert.value}
              onClose={handleCloseModal}
              title={odontogramAlert.label}
              children={<Options setOdontogramAlert={setOdontogramAlert} handleSetAllEdentulous={handleSetAllEdentulous}/>}
              closeOnOverlayClick={false}
            />
            <div className="grid grid-flow-row-dense grid-cols-1 bg-indigo-200 rounded-t-lg">
              <div className=" p-2 ">
                Convenciones / Simbología odontograma
              </div>
            </div>
            <div className="pl-2 pr-2 mb-5">
              <div className="bg-white filter drop-shadow" style={displayNone ? {'display':'none'} : {'display':'block'}}>

                <div className="grid grid-flow-row-dense grid-cols-5 ">
                  {dentsList.map((row,key)=>{
                    return  <div className={active && active.label===row.label?"flex cursor-pointe hover active":"flex cursor-pointer hover"} key={key} onClick={()=>handleSelect(row,2,row.src)}>
                              <div className={"flex-initial pt-2"}>
                                <SvgDent Src={row.src} className={row.className}/>
                              </div>
                              <div className="flex-1 pt-3 text-xs">
                                {row.label}
                              </div>
                            </div>
                  })}
                </div>
                <div className="grid grid-flow-row-dense grid-cols-7">
                  {secondarySet.map((row,key)=>{
                    return <div className={active && active.label===row.label?"flex cursor-pointer hover active":"flex cursor-pointer hover"} key={key} onClick={()=>handleSelect(row,1)}>
                              <div className={row.className+" flex-initial pt-2 pb-2 "} >
                                {row.svg?row.svg:<div></div>}
                                {row.label}
                              </div>
                            </div>
                  })}
                </div>
              </div>
            </div>

            <div className="grid grid-flow-row-dense grid-cols-2 bg-indigo-200 p-2 rounded-t-lg">
              <div>
                Odontograma {switchOdontogram!=='adultTeenth'?"Infantil":"Adulto"}
              </div>
              <div className="text-right ">
                <div onClick={()=>{setSwitchOdontogram(switchOdontogram==='adultTeenth'?'childishTeenth':'adultTeenth'); setType(switchOdontogram==='adultTeenth'?'childishTeenth':'adultTeenth')} } className="cursor-pointer">
                  <RefreshIcon
                    className="h-5 w-5  cursor-pointer float-right mr-3"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
            <div className="pl-2 pr-2 ">
              <div className="bg-white filter drop-shadow pb-5 pt-5">
                {switchOdontogram!=='adultTeenth'?<>
                  <div className="grid grid-flow-row-dense grid-cols-4 pb-3">

                    { childishTeenth.map((row,key)=>{
                      return  <div className={classStyle[key]} key={key}>
                                <div className="grid grid-flow-row-dense grid-cols-5 ">
                                    {row.map((row2,key2)=>{
                                      return  <div className="text-center" key={key2}>
                                          <a style={ { cursor:"pointer"}} onClick={()=> {
                                              let fechas = [];
                                              let arrayFechas = [];
                                              if(bkData.child.activeSector[row2.label]){
                                                  Object.values( bkData.child.activeSector[row2.label]).forEach( element => fechas.push(element.updateAt));
                                              }
                                              if(bkData.child.activeG[row2.label]){
                                                  if(bkData.child.activeG[row2.label].length > 0){
                                                      fechas.push(bkData.child.activeG[row2.label][0].updateAt);
                                                  }
                                              }
                                              if(fechas.length > 0){
                                                  arrayFechas = fechas.map((fechaEJ) => new Date(fechaEJ) );
                                                  var maxChild = new Date(Math.max.apply(null,arrayFechas));
                                                  setUpdateAt("Diente "+ row2.label +" actualizado " + maxChild.toLocaleDateString() + " - " + maxChild.toLocaleTimeString())
                                              }else{
                                                  setUpdateAt("El Diente no ha sido editado");
                                              }

                                          }}>{row2.label}</a>
                                                <center>
                                                  <One allEdentulous={allEdentulous} setAllEdentulous={setAllEdentulous} keyId={key} id={key2} bkData={bkData.child} setBkData={setBkData} active={active} data={data.child} setData={setData} row={row2} type="child"/>
                                                </center>
                                              </div>
                                    })}
                                </div>
                              </div>

                    })}
                  </div>
                </>:<>
                  <div className="grid grid-flow-row-dense grid-cols-4 pb-3">
                    {adultTeenth.map((row,key)=>{
                      return  <div className={classStyle[key]} key={key}>
                                <div className="grid grid-flow-row-dense grid-cols-8 ">
                                    {row.map((row2,key2)=>{
                                      //  dentsSVG  //
                                      return  <div className="text-center" key={key2}>
                                                <a style={ { cursor:"pointer"}} onClick={()=> {
                                                    let fechas = [];
                                                    let arrayFechas = [];
                                                    if(bkData.adult.activeSector[row2.label]){
                                                    Object.values( bkData.adult.activeSector[row2.label]).forEach( element => fechas.push(element.updateAt));
                                                    }
                                                    if(bkData.adult.activeG[row2.label]){
                                                        if(bkData.adult.activeG[row2.label].length > 0){
                                                            fechas.push(bkData.adult.activeG[row2.label][0].updateAt);
                                                        }
                                                    }
                                                    if(fechas.length > 0){
                                                        arrayFechas = fechas.map((fechaEJ) => new Date(fechaEJ) );
                                                        var maxAdult = new Date(Math.max.apply(null,arrayFechas));
                                                        setUpdateAt("Diente "+ row2.label +" actualizado " + maxAdult.toLocaleDateString() + " - " + maxAdult.toLocaleTimeString())
                                                    }else{
                                                        setUpdateAt("El Diente no ha sido editado");
                                                    }

                                                }}>{row2.label}</a>
                                                <center>
                                                  <One allEdentulous={allEdentulous} setAllEdentulous={setAllEdentulous} keyId={key} id={key2} bkData={bkData.adult} setBkData={setBkData} active={active} data={data.adult} setData={setData} row={row2} type="adult"/>
                                                </center>
                                              </div>
                                    })}
                                </div>
                              </div>

                    })}
                  </div>
                </>}
                  <div>{tab}{tab}{tab}{tab}<strong style={{color: "#5a75dd"}}>{updateAt ? updateAt : "" }</strong></div>
              </div>

            </div>
          </>

}

export default App
