import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const paymentInvoicePdf = (data) => {
  const numberFormat = new Intl.NumberFormat();

  const {
    payment_id,
    specialist,
    order_id,
    amount,
    amount_letters,
    balance_before_payment,
    balance_after_payment,
    payment_concept,
    payment_method,
    patient_balance,
    observation,
    config,
    date
  } = data;
  const content = [];

  content.push({
    columns: [
      {
        stack: [
          config.cf_name
        ],
        alignment: 'left',
      },
      {
        image: config.urlLogo,
        width: 150
      }
    ],
    style: 'header'
  })

  content.push({
    columns: [
      [
        {
          text: [
            {text: 'Fecha de Expedición: ', bold: true},
            date,
          ],
          margin: [0, 0, 0, 20]
        },
        {
          text: [
            {text: 'Profesional: ', bold: true},
            specialist ? specialist : ''
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Orden de Compra No: ', bold: true},
            order_id
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Saldo Antes del Abono: $', bold: true},
            numberFormat.format(balance_before_payment)
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Valor Pagado o Abonado: $', bold: true},
            numberFormat.format(amount)
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Valor en Letras: ', bold: true},
            `${amount_letters} Mtce.`
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Saldo Despues de Abono: $', bold: true},
            numberFormat.format(balance_after_payment)
          ],
          margin: [0, 0, 0, 5]
        }
      ],
      [
        {
          text: `FACTURA DE VENTA N° ${payment_id}`,
          bold: true,
          style: 'textCenter',
          margin: [0, 0, 0, 5]
        },
        {
          columns: [
            {
              text: [
                {text: 'Nit: ', bold: true},
                config?.cf_nit
              ]
            },
            {
              text: [
                {text: 'Teléfono: ', bold: true},
                config?.cf_phone
              ]
            }
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Dirección: ', bold: true},
            config?.cf_address
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Concepto: ', bold: true},
            payment_concept
          ],
          margin: [0, 0, 0, 5]
        },
        {
          text: [
            {text: 'Forma de pago: ', bold: true},
            payment_method
          ]
        }
      ],
    ],
    columnGap: 10
  });

  content.push({
    text: [
      {text: 'VALOR TOTAL PENDIENTE POR PAGAR(Incluye todas las ordenes): $', bold: true},
      numberFormat.format(patient_balance)
    ]
  })

  content.push({
    text: '____________________________________________________________________________________________________________',
    margin: [0, 0, 0, 10]
  })

  content.push({
    text: [
      {text: 'OBSERVACIÓN: ', bold: true},
      observation
    ],
    margin: [0, 0, 0, 40]
  })

  content.push({
    columns: [
      [
        {text: 'Firma profesional tratante', bold: true, margin: [0, 0, 0, 10]},
        {text: 'CC o Nit ___________________________________________', bold: true},
      ],
      [
        {text: 'Firma paciente', bold: true, margin: [0, 0, 0, 10]},
        {text: 'CC o Nit ___________________________________________', bold: true},
      ]
    ]
  })

  content.push({
    text: config?.cf_invoice_footer,
    bold: true,
    margin: [0, 40, 0, 0],
    style: 'footer'
  })


  const docDefinition = {
    content: content,
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'left',
        margin: [0, 10, 0, 40]
      },
      footer: {
        alignment: 'center',
        fontSize: 8,
      },
      textCenter: {
        alignment: 'center'
      }
    },
    defaultStyle: {
      fontSize: 10
    }
  }
  pdfMake.createPdf(docDefinition).download(`FACTURA DE VENTA ${payment_id}`);

}
