import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Select from "react-select";

import CustomTextInput from "../../common/CustomTextInput";
import CustomErrorMessage from "../../common/CustomErrorMessage";
import CustomTextarea from "../../common/CustomTextarea";
import OrderItemsTable from "./OrderItemsTable";
import { discountTypes } from "../../../constants/discountTypes";
import OrderItemForm from "./OrderItemForm";
import CustomModalContent from "../../common/CustomModalContent";
import PermissionWrapper from "../../common/PermissionWrapper";
import { CREATE_ORDER, EDIT_ORDER } from "../../../constants/permissions";

const numberFormat = new Intl.NumberFormat();

const OrderForm = ({ patient, order, orderItems, agreements, onSubmit }) => {
  const [openModalItem, setOpenModalItem] = useState(false);
  const [items, setItems] = useState(orderItems);
  const [total, setTotal] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    reset({
      patientId: patient?.id,
      patient: patient?.pa_name,
      concept: order?.or_concept,
      recommendations: order.or_recommendations,
    });
    setValue("agreementId", 1);
  }, [patient, order, reset, setValue]);

  useEffect(() => {
    setItems(orderItems);
  }, [orderItems]);

  useEffect(() => {
    const t = items.reduce((n, { total }) => Number(n) + Number(total), 0);
    setTotal(t);
  }, [items]);

  const handleOpenModalItem = () => setOpenModalItem(true);
  const handleCloseModalItem = () => setOpenModalItem(false);

  const deleteItem = (row) => {
    const itemsFilter = items.filter(
      (_item, index) => Number(index) !== Number(row.id)
    );
    setItems(itemsFilter);
  };

  const addItem = (data) => {
    setItems([data, ...items]);
    handleCloseModalItem();
  };

  const submit = (formData) => {
    formData.items = items;
    onSubmit(formData);
  };

  return (
    <>
      <CustomModalContent
        open={openModalItem}
        onClose={handleCloseModalItem}
        title="Item Orden"
        closeOnOverlayClick={false}
      >
        <OrderItemForm discountTypes={discountTypes} onSubmit={addItem} />
      </CustomModalContent>
      <form onSubmit={handleSubmit(submit)}>
        <div className="grid grid-cols-1 gap-y-2 gap-x-8 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <div className="grid grid-cols-1 gap-y-2 gap-x-3 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <CustomTextInput
                  {...register("patient", { required: "Requerido" })}
                  type="text"
                  name="patient"
                  id="patient"
                  label="Paciente"
                  errors={errors}
                  readOnly={true}
                />
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="agreementId"
                  className="mb-1 block text-sm font-medium text-gray-700"
                >
                  Convenio*
                </label>
                <Controller
                  name="agreementId"
                  control={control}
                  render={({ field: { onChange, value }, ref }) => {
                    return (
                      <Select
                        inputRef={ref}
                        options={agreements}
                        value={agreements.find((c) => c.id === value)}
                        onChange={(val) => onChange(val.value)}
                      />
                    );
                  }}
                />
                <CustomErrorMessage errors={errors} name="agreementId" />
              </div>
              <div className="sm:col-span-6">
                <CustomTextInput
                  {...register("concept", { required: "Requerido" })}
                  type="text"
                  name="concept"
                  id="concept"
                  label="Concepto*"
                  errors={errors}
                />
              </div>
              <div className="sm:col-span-6">
                <CustomTextarea
                  {...register("recommendations")}
                  id="recommendations"
                  name="recommendations"
                  label="Recomendaciones"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="border-1 border-gray-400 my-4" />
        <div className="mb-2">
          <button
            type="button"
            className="btn-secondary-dark mb-1"
            onClick={handleOpenModalItem}
          >
            Nuevo item
          </button>
          <OrderItemsTable items={items} onDelete={deleteItem} />
        </div>
        {items.length > 0 && (
          <div className="flex justify-between mt-6">
            <div>
            <PermissionWrapper
              permission={order?.id ? EDIT_ORDER : CREATE_ORDER}
            >
              <button type="submit" className="btn-secondary-dark">
                Guardar
              </button>
            </PermissionWrapper>
            </div>
            <div className="text-right font-bold">
              Valor Total Tratamiento: $ {numberFormat.format(total)}
            </div>
          </div>
        )}
      </form>
    </>
  );
};

OrderForm.propTypes = {
  patient: PropTypes.object.isRequired,
  order: PropTypes.object,
  orderItems: PropTypes.array,
  agreements: PropTypes.array,
  onSubmit: PropTypes.func,
};

OrderForm.defaultProps = {
  agreements: [],
};

export default OrderForm;
