import React from 'react';
import {CalendarIcon} from "@heroicons/react/solid";

const SpecialistSelect = ({specialists, onChange}) => {

  const change = (event) => {
    const specialist = specialists[event.target.value];
    onChange(specialist);
  }

  return (
    <>
      <div className="text-xl font-medium text-secondary-dark">
        <span className="border-b-2 border-secondary-dark py-2">
          <CalendarIcon className="mr-1 h-6 w-6 inline-block align-middle"/>
          <h1 className="inline-block align-middle">Agenda</h1>
        </span>
      </div>
      <div className="my-4">
        <label htmlFor="location" className="inline-block text-sm font-medium text-gray-700 mr-2">
          Profesional
        </label>
        <select
          id="location"
          name="location"
          className="mt-1 inline-block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md mr-2"
          defaultValue="Canada"
          onChange={change}
        >
          <option>-- Seleccione --</option>
          {specialists && specialists.map((specialist, index) =>
            <option key={specialist.id} value={index}>
              {specialist.user.name}
            </option>
          )}
        </select>
      </div>
    </>
  )
}

export default SpecialistSelect;
