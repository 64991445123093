import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import CustomTextInput from "../common/CustomTextInput";
import CustomTextarea from "../common/CustomTextarea";

const InsurerForm = ({insurer, editPermission, onSubmit}) => {
  const { register, handleSubmit, reset,  formState:{ errors } } = useForm();

  useEffect(() => {
    reset();
  }, [insurer, reset]);

  return(
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <CustomTextInput
          {...register('name', {required: 'Requerido'})}
          type="text"
          name="name"
          id="name"
          label="Nombre*"
          errors={errors}
          defaultValue={insurer?.in_name}
        />
      </div>
      <div className="mb-2">
        <CustomTextarea
          {...register('description', {required: 'Requerido'})}
          id="description"
          name="description"
          label="Descripción*"
          defaultValue={insurer?.in_description}
          errors={errors}
        />
      </div>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <input
            {...register('status')}
            type="checkbox"
            id="status"
            name="status"
            defaultChecked={insurer?.in_status}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="status" className="ml-2 block text-sm text-gray-900">
            Activo
          </label>
        </div>
      </div>
      <div className="flex justify-end">
        {((editPermission && insurer?.id) || !insurer?.id) &&
          <button
            type="submit"
            className="btn-secondary-dark"
          >
            Guardar
          </button>
        }
      </div>
    </form>
  );
}

InsurerForm.propTypes = {
  insurer: PropTypes.object,
  editPermission: PropTypes.bool,
  onSubmit: PropTypes.func
};

InsurerForm.defaultProps = {
  editPermission: true
}

export default InsurerForm;
