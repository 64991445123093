import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PageTitle from "../components/common/PageTitle";
import { setLoaderStatus } from "../redux/loader/slice";
import ConfigForm from "../components/config/ConfigForm";
import { AdjustmentsIcon } from "@heroicons/react/solid";
import useCheckPermission from "../hooks/useCheckPermission";
import { CREATE_CONFIG_PER, EDIT_CONFIG_PER } from "../constants/permissions";
import { successAlert } from "../helpers/alerts";
import {
  createConfig,
  getCurrentConfig,
  updateConfig,
} from "../http/api/config";

const ConfigPage = () => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState({});

  useEffect(() => {
    (async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getCurrentConfig();
        setConfig(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    })();
  }, [dispatch]);

  const createConfigPermission = useCheckPermission(CREATE_CONFIG_PER);
  const editConfigPermission = useCheckPermission(EDIT_CONFIG_PER);

  const saveConfig = async (formData) => {
    const saveData = {
      name: formData["name"],
      logo: formData["logoDataUrl"],
      nit: formData["nit"],
      phone: formData["phone"],
      address: formData["address"],
      invoice_footer: formData["invoiceFooter"],
    };

    try {
      dispatch(setLoaderStatus(true));
      if (config && config.id) {
        const { message } = await updateConfig(saveData, config.id);
        successAlert(message);
      } else {
        const { message } = await createConfig(saveData);
        successAlert(message);
      }

      const { data } = await getCurrentConfig();
      setConfig(data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Configurar Consultorio" Icon={AdjustmentsIcon} />
      <div className="md:grid md:grid-cols-3 md:gap-6 mt-10">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Configuración del consultorio
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              Actualiza la información de tu consultorio
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2 bg-white sm:rounded-md">
          <ConfigForm
            config={config}
            createPermission={createConfigPermission}
            editPermission={editConfigPermission}
            onSubmit={saveConfig}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigPage;
