import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import sessionSlice from './session/slice';
import loaderSlice from './loader/slice';
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const reducers = combineReducers({
  session: sessionSlice,
  loader: loaderSlice
})

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['session'],
  version: 5,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
});

export default store
