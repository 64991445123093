import http from "./axiosInstance";

/**
 * User login
 * @param data
 * @returns {Promise<any>}
 */
export const authLogin = (data) => {
  return http().post("/login", data);
};

/**
 * Logout request
 * @returns {Promise<any>}
 */
export const authLogout = () => {
  return http().post("/logout");
};

/**
 * Forgot Password request
 * @returns {Promise<any>}
 */
export const forgotPassword = (data) => {
  return http().post("/password/email", data);
};

/**
 * Reset Password request
 * @returns {Promise<any>}
 */
export const resetPassword = (data) => {
  return http().post("/password/reset", data);
};
