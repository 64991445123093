import React from 'react';
import moment from "moment";

const ConsultationsList = ({consultations, onClick, selectedId}) => {
  return (
    <ul className="relative z-0 divide-y divide-gray-200">
      {consultations && consultations.map((consultation) => (
        <li key={consultation.id} className={`${selectedId === consultation.id && 'bg-gray-100'}`}>
          <div className="relative px-5 py-4 items-center space-x-3">
            <div className="min-w-0">
              <button onClick={() => onClick(consultation)}>
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{moment(consultation.co_created_at).format('YYYY-MM-DD')}</p>
              </button>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default ConsultationsList;
