import React from 'react';
import Table from "../common/Table";
import PropTypes from "prop-types";

const UsersTable = ({ users, showEdit, onEdit }) => {
  const columns = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Rol',
      accessor: 'roles[0].name',
    },
    {
      Header: 'Especialidad',
      accessor: 'specialist.specialties[0].spc_name',
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row:{original} }) => <span className={original.status ? 'badge-green' : 'badge-gray'}>
        {original.status ? 'Activado' : 'Desactivado'}
      </span>
    },
    {
      Header: '',
      accessor: '[editButton]',
      Cell: ({ row:{original} }) => <div className="text-center">
        {showEdit &&
          <button className="text-secondary-dark inline-block font-medium" onClick={() => onEdit(original)}>
            Editar
          </button>
        }
      </div>
    }];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={users} />
    </div>
  )
}

UsersTable.propTypes = {
  users: PropTypes.array,
  showEdit: PropTypes.bool,
  onEdit: PropTypes.func
}

UsersTable.defaultProps = {
  showEdit: true
}

export default UsersTable;
