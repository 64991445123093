import React from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";
import { XCircleIcon } from "@heroicons/react/outline";
import MenuDropDown from "./MenuDropDown";
import advertencia from "../../assets/advertencia.svg";

const CustomModalContent = ({title, open, onClose, optionsMenu, ...props}) => {
  const closeIcon = <XCircleIcon className="w-7 text-secondary-dark" />;

  const viewAlert = false;

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{
        modal: "bg-tertiary border-2 rounded-2xl border-tertiary",
        closeButton: "top-0 right-0",
      }}
      closeIcon={closeIcon}
      {...props}
    >
      <div className="pt-4">
        <div className="flex justify-between content-center">

          { title && !viewAlert && (
              <div>
                <div className="font-bold text-xl text-primary">
                  {title === "  Opciones Odontograma" || title === "  Notas importantes del paciente" ? (
                  <img src={advertencia} width="35" height="35" style={{display:'inline-block'}}/>
                  ) : ""}
                  {title}
                </div>
              </div>

          )}
          <MenuDropDown options={optionsMenu} />
        </div>
        <div className="pt-2">{props.children}</div>
      </div>
    </Modal>
  );
};

CustomModalContent.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  optionsMenu: PropTypes.array,
};

export default CustomModalContent;
