import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import PaymentPlanForm from "../PaymentPlanForm";
import PaymentPlanTable from "./PaymentPlanTable";
import MenuDropDown from "../../../common/MenuDropDown";
import { updateStatusPlan } from "../../../../http/api/orderPaymentPlan";
import { CANCELLED } from "../../../../constants/planStatus";
import CustomModalContent from "../../../common/CustomModalContent";
import PaymentQuotaForm from "../../payment/PaymentQuotaForm";
import { createPaymentQuota } from "../../../../http/api/payment";
import { setLoaderStatus } from "../../../../redux/loader/slice";
import useGetAllSpecialists from "../../../../hooks/useGetAllSpecialists";
import useGetAllPaymentsMethods from "../../../../hooks/useGetAllPaymentsMethods";
import { successAlert } from "../../../../helpers/alerts";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import { EDIT_PAYMENT_PLAN_ORDER } from "../../../../constants/permissions";
import { paymentPlanPdf } from "../../../reports/paymentPlanPdf";
import { getCurrentConfig } from "../../../../redux/session/selectors";

const PaymentPlanEdit = ({ order, plan, onDeletePlan, onPayQuota }) => {
  const config = useSelector(getCurrentConfig);
  const [planOptions, setPlanOptions] = useState([]);
  const [openModalPaymentQuote, setOpenModalPaymentQuota] = useState(false);
  const [quotaToPay, setQuotaToPay] = useState({});
  const [tempQuotas, setTempQuotas] = useState([]);

  const dispatch = useDispatch();
  const specialists = useGetAllSpecialists();
  const paymentMethods = useGetAllPaymentsMethods();
  const edit_plan_permission = useCheckPermission(EDIT_PAYMENT_PLAN_ORDER);

  useEffect(() => {
    const updatePlanStatus = async (planId) => {
      try {
        dispatch(setLoaderStatus(true));
        const { message } = await updateStatusPlan(planId, CANCELLED);
        successAlert(message);
        //if prop onDeletePlan exist
        if (onDeletePlan) {
          onDeletePlan();
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    const getPlan = (plan) => {
      let pay = true;
      let balance = Number(plan.opp_finance_value);
      const quotas = plan.quotas.map((quota) => {
        balance -= Number(quota.oppq_quota_value);
        let payButton = pay && quota.oppq_status === 1;

        if (quota.oppq_status === 1) {
          pay = false;
        }
        return {
          id: quota.id,
          quotaIndex: quota.oppq_quota_index,
          quotaValue: quota.oppq_quota_value,
          quotaStatus: quota.oppq_status,
          balance: balance,
          payButton,
        };
      });
      setTempQuotas(quotas);

      const deletePlanOption = {
        text: "Eliminar plan de pagos",
        onClick: () => {
          updatePlanStatus(plan.id);
        },
      };

      const printPlanOption = {
        text: "Imprimir plan de pagos",
        onClick: () => {
          paymentPlanPdf(order, plan, quotas, config?.urlLogo);
        },
      };

      if(edit_plan_permission){
        setPlanOptions((prevState) => [...prevState, printPlanOption, deletePlanOption]);
      }
    };
    
    setPlanOptions([]);
    getPlan(plan);
  }, [config?.urlLogo, dispatch, edit_plan_permission, onDeletePlan, order, plan]);

  const handleCloseModalPaymentQuota = () => setOpenModalPaymentQuota(false);
  const handleOpenModalPaymentQuota = () => setOpenModalPaymentQuota(true);
  const handlePaymentQuota = (quota) => {
    setQuotaToPay(quota);
    handleOpenModalPaymentQuota();
  };

  const savePaymentQuota = async (data) => {
    const paymentData = {
      order_id: order.id,
      specialist_id: data?.specialistId,
      payment_method_id: data?.paymentMethodId,
      payment_concept_id: 3,
      amount: quotaToPay?.quotaValue,
      observations: data?.observations,
    };

    try {
      dispatch(setLoaderStatus(true));
      const { message } = await createPaymentQuota(paymentData, quotaToPay?.id);
      successAlert(message);
      handleCloseModalPaymentQuota();

      if (onPayQuota) {
        await onPayQuota();
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between content-center mb-4">
          <div className="font-bold text-xl text-primary">Plan de pagos</div>
          <MenuDropDown options={planOptions} />
        </div>

        <PaymentPlanForm
          orderId={order.id}
          total={Number(order.items_sum_ori_total)}
          balance={Number(order.balance)}
          defaultQuotes={plan.opp_quotas_number}
          defaultInitialQuotaPercentage={plan.opp_initial_quota_percentage}
          defaultInitialQuotaValue={plan.opp_initial_quota_value}
          financeValue={Number(plan.opp_finance_value)}
          showInitialFee={false}
          edit={!plan?.id}
        />
        {tempQuotas.length > 0 && (
          <>
            <hr className="border-1 border-gray-400 mt-4 mb-4" />
            <PaymentPlanTable
              quotas={tempQuotas}
              onPaymentQuote={handlePaymentQuota}
            />
          </>
        )}

        <CustomModalContent
          open={openModalPaymentQuote}
          onClose={handleCloseModalPaymentQuota}
          title={"Pagar Cuota"}
          closeOnOverlayClick={false}
        >
          <PaymentQuotaForm
            paymentMethods={paymentMethods}
            specialists={specialists}
            onSubmit={savePaymentQuota}
          />
        </CustomModalContent>
      </div>
    </>
  );
};

PaymentPlanEdit.propTypes = {
  order: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  onDeletePlan: PropTypes.func,
  onPayQuota: PropTypes.func,
};

export default PaymentPlanEdit;
