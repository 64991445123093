import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import TimeField from "react-simple-timefield";
import { useForm, Controller } from "react-hook-form";

import CustomTextInput from "../common/CustomTextInput";
import CustomModalContent from "../common/CustomModalContent";
import PatientBrowser from "./schedule/patientBrowser/PatientBrowser";
import { SearchIcon } from "@heroicons/react/solid";
import CustomErrorMessage from "../common/CustomErrorMessage";
import { ErrorMessage } from "@hookform/error-message";
import { successAlert } from "../../helpers/alerts";
import { setLoaderStatus } from "../../redux/loader/slice";
import useGetAllSpecialists from "../../hooks/useGetAllSpecialists";
import useGetAllAppointmentsStatus from "../../hooks/useGetAllAppointmentsStatus";
import useCheckPermission from "../../hooks/useCheckPermission";
import { CREATE_APPOINTMENT, EDIT_APPOINTMENT } from "../../constants/permissions";
import {
  createAppointment,
  searchConflictAppointment,
  updateAppointment,
} from "../../http/api/appointment";
import PatientBadgeAlert from "../common/PatientBadgeAlert";

const AppointmentForm = ({ initialValues, onSaveAppointment }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const specialists = useGetAllSpecialists();
  const status = useGetAllAppointmentsStatus();
  const createPermission = useCheckPermission(CREATE_APPOINTMENT);
  const editPermission = useCheckPermission(EDIT_APPOINTMENT);

  const [patient, setPatient] = useState({});
  const [open, setOpen] = useState(false);
  const watchReserved = watch("reserved", initialValues?.reserved);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    setPatient(initialValues.patient);
  }, [initialValues]);

  useEffect(() => {
    setValue("specialistId", initialValues.specialistId);
  }, [initialValues.specialistId, setValue, specialists]);

  const onSelectPatient = (data) => {
    setValue("name", data.pa_name);
    setValue("document", data.pa_document);
    setValue("phone", data.pa_phone);
    setPatient(data);
    handleClose();
  };

  const reserve = () => {
    setValue("name", "");
    setValue("document", "");
    setValue("phone", "");
    setPatient({});
  };

  const saveAppointment = async (formData) => {
    let selectStatus = {};
    const date = moment(formData.date).format("YYYY-MM-DD");
    const from = `${date} ${formData.from}`;
    const to = `${date} ${formData.to}`;
    const data = {
      patient_id: patient?.id,
      specialist_id: formData.specialistId,
      from: from,
      to: to,
      sms_notifications: formData.smsNotifications,
      description: formData.description,
    };

    try {
      dispatch(setLoaderStatus(true));
      if (initialValues?.id) {
        const { message } = await updateAppointment(data, initialValues?.id);
        successAlert(message);
      } else {
        if (formData.reserved) {
          selectStatus = status.find((item) => item.aps_code === "RESERVED");
        } else {
          selectStatus = status.find((item) => item.aps_code === "CREATED");
        }
        data.status = selectStatus.id;
        const { message } = await createAppointment(data);
        successAlert(message);
      }

      if (onSaveAppointment) {
        onSaveAppointment(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <>
      <CustomModalContent
        title="Buscar Paciente"
        open={open}
        onClose={handleClose}
        closeOnOverlayClick={false}
      >
        <div className="w-full md:max-w-7xl">
          <PatientBrowser onSelectPatient={onSelectPatient} />
        </div>
      </CustomModalContent>
      <form onSubmit={handleSubmit(saveAppointment)}>
        {!!initialValues?.patient?.clinical_history?.ch_alert && 
          <div className="mb-2">
            <PatientBadgeAlert />
          </div>
        }
        {!initialValues?.id && (
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <input
                {...register("reserved")}
                type="checkbox"
                id="reserved"
                name="reserved"
                defaultChecked={initialValues?.reserved}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                onClick={() => reserve()}
              />
              <label
                htmlFor="reserved"
                className="ml-2 block text-sm text-gray-900"
              >
                Marque si desea reservar este espacio
              </label>
            </div>
          </div>
        )}
        {!watchReserved && !initialValues?.reserved && (
          <>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <input
                  {...register("smsNotifications")}
                  type="checkbox"
                  id="smsNotifications"
                  name="smsNotifications"
                  defaultChecked={initialValues?.smsNotifications}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="smsNotifications"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Notificar por SMS
                </label>
              </div>
            </div>
            <div className="mb-2">
              <label
                htmlFor="name"
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                Paciente
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <CustomTextInput
                  {...register("name", { required: "Requerido" })}
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={initialValues.patient?.pa_name}
                  onClick={handleOpen}
                  readOnly={true}
                />
                <span
                  onClick={handleOpen}
                  className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
                >
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
              <CustomErrorMessage errors={errors} name="name" />
            </div>
            <div className="mb-2">
              <CustomTextInput
                {...register("document")}
                type="text"
                name="document"
                id="document"
                label="Documento"
                errors={errors}
                defaultValue={initialValues.patient?.pa_document}
                readOnly={true}
              />
            </div>
            <div className="mb-2">
              <CustomTextInput
                {...register("phone")}
                type="text"
                name="phone"
                id="phone"
                label="Teléfono"
                errors={errors}
                defaultValue={initialValues.patient?.pa_phone}
                readOnly={true}
              />
            </div>
          </>
        )}
        <div className="mb-2">
          <label
            htmlFor="specialistId"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Especialista
          </label>
          <select
            {...register("specialistId", { required: "Requerido" })}
            id="specialistId"
            name="specialistId"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">-- Seleccione --</option>
            {specialists &&
              specialists.map((specialist) => (
                <option key={specialist.id} value={specialist.id}>
                  {specialist.user.name}
                </option>
              ))}
          </select>
          <CustomErrorMessage errors={errors} name="specialistId" />
        </div>
        <div className="mb-2">
          <label
            htmlFor="date"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Fecha
          </label>
          <Controller
            name="date"
            control={control}
            rules={{
              validate: {
                checkRange: async (value) => {
                  const date = moment(value).format("YYYY-MM-DD");
                  const { from, to, specialistId } = getValues();
                  const dateFrom = moment(`${date} ${from}`)
                    .add(1, "minutes")
                    .format("YYYY-MM-DD HH:mm");
                  const dateTo = moment(`${date} ${to}`)
                    .subtract(1, "minutes")
                    .format("YYYY-MM-DD HH:mm");
                  const { data: appointment } = await searchConflictAppointment(
                    dateFrom,
                    dateTo,
                    initialValues.id,
                    specialistId
                  );
                  return !appointment || "Agenda ocupada";
                },
              },
            }}
            defaultValue={new Date(initialValues.date)}
            render={({ field }) => (
              <DatePicker
                onChange={(e) => field.onChange(e)}
                selected={field.value}
                placeholderText="Select date"
                popperPlacement="top-start"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            )}
          />
        </div>
        <div className="mb-2">
          <div className="inline-block mr-2">
            <Controller
              name="from"
              control={control}
              defaultValue={initialValues.from}
              render={({ field }) => (
                <TimeField
                  {...field}
                  onChange={field.onChange}
                  input={<input type="text" />}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md w-16"
                />
              )}
            />
          </div>
          <div className="inline-block">
            <Controller
              name="to"
              control={control}
              defaultValue={initialValues.to}
              render={({ field }) => (
                <TimeField
                  {...field}
                  onChange={field.onChange}
                  input={<input type="text" />}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md w-16"
                />
              )}
            />
          </div>
        </div>
        <div className="mb-2">
          <label
            htmlFor="description"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Descripción
          </label>
          <textarea
            {...register("description")}
            id="description"
            name="description"
            className="w-full rounded-md border-gray-300"
            placeholder="Descripción"
            defaultValue={initialValues.description}
          />
        </div>
        <div className="flex justify-between">
          <div>
            {errors.date?.type === "checkRange" && (
              <ErrorMessage
                errors={errors}
                name="date"
                render={({ message }) => (
                  <span className="badge-large-rounded-red py-2">
                    {message}
                  </span>
                )}
              />
            )}
          </div>
          <div>
            {((createPermission && !initialValues?.id) || (editPermission && initialValues?.id)) &&
              <button type="submit" className="btn-secondary-dark">
                Guardar
              </button>
            }
          </div>
        </div>
      </form>
    </>
  );
};

export default AppointmentForm;