import moment from "moment";
export const patientValidations = {
    documentTypeId: {
        required: 'El tipo de documento es requerido'
    },
    document: {
        required: 'El número de documento es requerido',
        minLength: {
            value: 6,
            message: 'El valor ingresado es muy corto (min: 6)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    name: {required: 'El nombre es requerido'},
    birthday: {required: 'Requerido'},
    maritalStatusId: {
        required: 'El estado civil es requerido',
    },
    occupation: {
        required: 'La ocupación es requerido',
    },
    insurerId: {
        required: 'La EPS es requerido',
    },
    bondingTypeId: {
        required: 'El tipo de vinculación es requerido',
    },
    residencePhone: {
        minLength: {
            value: 7,
            message: 'El valor ingresado es muy corto (min: 7)'
        },
        maxLength: {
            value: 12,
            message: 'El valor ingresado es muy largo (max: 12)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    residenceAddress: {
        required: 'Dirección de residencia requerida'
    },
    residenceNeighborhood: {},
    residenceStateId: {},
    residenceCityId: {},
    phone: {
        minLength: {
            value: 10,
            message: 'El valor ingresado es muy corto (min: 10)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    email: {
        // required: 'El correo electrónico es requerido',
        email: 'No es una correo electrónico de valida'
    },
    businessAddress: {},
    businessPhone: {
        minLength: {
            value: 6,
            message: 'El valor ingresado es muy corto (min: 6)'
        },
        maxLength: {
            value: 12,
            message: 'El valor ingresado es muy largo (max: 12)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    reasonConsultationId: {},
    mediaId: {},
    referredFrom: {},
    referredPhone: {
        minLength: {
            value: 7,
            message: 'El valor ingresado es muy corto (min: 7)'
        },
        maxLength: {
            value: 12,
            message: 'El valor ingresado es muy largo (max: 12)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    familyPlaceId: {},
    sportId: {},
    companionDocument: {
        minLength: {
            value: 6,
            message: 'El valor ingresado es muy corto (min: 6)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    companionName: {},
    companionPhone: {
        minLength: {
            value: 7,
            message: 'El valor ingresado es muy corto (min: 7)'
        },
        maxLength: {
            value: 12,
            message: 'El valor ingresado es muy largo (max: 12)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    companionAddress: {},
    companionRelationship: {},
    motherName: {},
    motherDocument: {
        minLength: {
            value: 6,
            message: 'El valor ingresado es muy corto (min: 6)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    motherPhone: {
        minLength: {
            value: 7,
            message: 'El valor ingresado es muy corto (min: 7)'
        },
        maxLength: {
            value: 12,
            message: 'El valor ingresado es muy largo (max: 12)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    motherOccupation: {},
    fatherName: {},
    fatherDocument: {
        minLength: {
            value: 6,
            message: 'El valor ingresado es muy corto (min: 6)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    fatherPhone: {
        minLength: {
            value: 7,
            message: 'El valor ingresado es muy corto (min: 7)'
        },
        maxLength: {
            value: 12,
            message: 'El valor ingresado es muy largo (max: 12)'
        },
        pattern: {
            value: /^[0-9]*$/,
            message: 'Solo son permitidos números'
        }
    },
    fatherOccupation: {},
    photo: {
        // required: 'La foto es requerida'
    },
    status: {required: 'El estado es requerido'},
    history: {required: 'Debe seleccionar un odontograma'}
}

export function formatPatientData(data) {
    return {
        document_type_id: data['documentTypeId'],
        document: data['document'],
        name: data['name'],
        marital_status_id: data['maritalStatusId'],
        blood_type_id: data['bloodTypeId'],
        occupation: data['occupation'],
        insurer_id: data['insurerId'],
        bonding_type_id: data['bondingTypeId'],
        residence_phone: data['residencePhone'],
        residence_address: data['residenceAddress'],
        residence_city_id: data['residenceCityId'],
        residence_neighborhood: data['residenceNeighborhood'],
        phone: data['phone'],
        email: data['email'],
        business_phone: data['businessPhone'],
        business_address: data['businessAddress'],
        reason_consultation_id: data['reasonConsultationId'],
        media_id: data['mediaId'],
        referred_from: data['referredFrom'],
        referred_phone: data['referredPhone'],
        family_place_id: data['familyPlaceId'],
        sport_id: data['sportId'],
        companion_document: data['companionDocument'],
        companion_name: data['companionName'],
        companion_phone: data['companionPhone'],
        companion_address: data['companionAddress'],
        companion_relationship: data['companionRelationship'],
        mother_name: data['motherName'],
        mother_document: data['motherDocument'],
        mother_phone: data['motherPhone'],
        mother_occupation: data['motherOccupation'],
        father_name: data['fatherName'],
        father_document: data['fatherDocument'],
        father_phone: data['fatherPhone'],
        father_occupation: data['fatherOccupation'],
        photo: data['photo'],
        status: data['status'],
        birthday: moment(data['birthday']).format('YYYY-MM-DD'),
        diseases: data['diseases'],
        medicines: data['medicines'],
        observation: data['observation'],
        alert: data['alert'],
    }
}
