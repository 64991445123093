import {
  AcademicCapIcon,
  AdjustmentsIcon,
  AnnotationIcon,
  BriefcaseIcon,
  CalendarIcon,
  ClipboardIcon,
  ClipboardListIcon,
  DocumentReportIcon,
  HomeIcon,
  IdentificationIcon,
  OfficeBuildingIcon,
  SpeakerphoneIcon,
  UsersIcon,
} from "@heroicons/react/solid";
import {
  ACCESS_CONFIGURATION_MODULE,
  ACCESS_INSURER_MODULE,
  ACCESS_MEDIA_MODULE,
  ACCESS_PATIENT_MODULE,
  ACCESS_PATIENT_REPORTS_MODULE,
  ACCESS_PRODUCT_MODULE,
  ACCESS_REASON_MODULE,
  ACCESS_SCHEDULE_MODULE,
  ACCESS_SPECIALTY_MODULE,
  ACCESS_USER_MODULE,
  GENERATE_APPOINTMENTS_REPORT,
} from "./permissions";

export const menuElements = [
  { name: "Inicio", href: "/", icon: HomeIcon, permission: "" },
  {
    name: "Agenda",
    href: "/appointments",
    icon: CalendarIcon,
    permission: ACCESS_SCHEDULE_MODULE,
  },
  {
    name: "Pacientes",
    href: "/patients",
    icon: UsersIcon,
    permission: ACCESS_PATIENT_MODULE,
  },
  {
    name: "Usuarios",
    href: "/users",
    icon: IdentificationIcon,
    permission: ACCESS_USER_MODULE,
  },
  {
    name: "Productos",
    href: "/products",
    icon: ClipboardListIcon,
    permission: ACCESS_PRODUCT_MODULE,
  },
  {
    name: "Informes",
    icon: DocumentReportIcon,
    href: "/reports",
    permission: "",
    children: [
      {
        name: "Citas",
        href: "/report-appointments",
        icon: ClipboardIcon,
        permission: GENERATE_APPOINTMENTS_REPORT,
      },
      {
        name: "Pacientes",
        href: "/report-patients",
        icon: UsersIcon,
        permission: ACCESS_PATIENT_REPORTS_MODULE,
      },
    ],
  },
  {
    name: "Configuración",
    icon: AdjustmentsIcon,
    href: "/config",
    permission: "",
    children: [
      {
        name: "Consultorio",
        href: "/config",
        icon: OfficeBuildingIcon,
        permission: ACCESS_CONFIGURATION_MODULE,
      },
      {
        name: "Especialidades",
        href: "/specialties",
        icon: AcademicCapIcon,
        permission: ACCESS_SPECIALTY_MODULE,
      },
      {
        name: "Aseguradoras",
        href: "/insurers",
        icon: BriefcaseIcon,
        permission: ACCESS_INSURER_MODULE,
      },
      {
        name: "Medios de comunicación",
        href: "/medias",
        icon: SpeakerphoneIcon,
        permission: ACCESS_MEDIA_MODULE,
      },
      {
        name: "Motivos de consulta",
        href: "/reasons-consultation",
        icon: AnnotationIcon,
        permission: ACCESS_REASON_MODULE,
      },
    ],
  },
];

export const menuElementsHome = [
  {
    name: "Agenda",
    href: "/appointments",
    icon: CalendarIcon,
    permission: ACCESS_SCHEDULE_MODULE,
  },
  {
    name: "Pacientes",
    href: "/patients",
    icon: UsersIcon,
    permission: ACCESS_PATIENT_MODULE,
  },
  {
    name: "Usuarios",
    href: "/users",
    icon: IdentificationIcon,
    permission: ACCESS_USER_MODULE,
  },
  {
    name: "Productos",
    href: "/products",
    icon: ClipboardListIcon,
    permission: ACCESS_PRODUCT_MODULE,
  },
  {
    name: "Consultorio",
    href: "/config",
    icon: OfficeBuildingIcon,
    permission: ACCESS_CONFIGURATION_MODULE,
  },
  {
    name: "Especialidades",
    href: "/specialties",
    icon: AcademicCapIcon,
    permission: ACCESS_SPECIALTY_MODULE,
  },
  {
    name: "Aseguradoras",
    href: "/insurers",
    icon: BriefcaseIcon,
    permission: ACCESS_INSURER_MODULE,
  },
  {
    name: "Medios de comunicación",
    href: "/medias",
    icon: SpeakerphoneIcon,
    permission: ACCESS_MEDIA_MODULE,
  },
  {
    name: "Motivos de consulta",
    href: "/reasons-consultation",
    icon: AnnotationIcon,
    permission: ACCESS_REASON_MODULE,
  },
];
