import React, { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { NavLink as Link } from "react-router-dom";
import { menuElements } from "../../constants/navigation";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Sidebar = ({ sidebarOpen, setSidebarOpen, config, permissions }) => {
  let navigation = menuElements.filter((item) => {
    if (
      permissions &&
      (permissions.includes(item.permission) || item.permission === "")
    ) {
      if (item.children) {
        let count = 0;
        item.children = item.children.filter((subItem) => {
          if (
            permissions.includes(subItem.permission) ||
            subItem.permission === ""
          ) {
            count++;
            return subItem;
          }
        });
        if (count < 1) {
          return null;
        }
      }
      return item;
    }
  });

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex justify-center items-center px-4">
                <img
                  className="w-3/4 max-h-24"
                  src={config?.urlLogo}
                  alt={config?.cf_name}
                />
              </div>
              <div className="mt-6 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) =>
                    !item.children ? (
                      <Link
                        key={item.name}
                        to={item.href}
                        activeClassName="border-solid text-primary bg-secondary"
                        className={
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        }
                        exact
                      >
                        <item.icon
                          className={classNames("text-primary", "mr-4 h-6 w-6")}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure
                        as="div"
                        key={item.name}
                        className="space-y-1"
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md">
                              <item.icon
                                className={classNames(
                                  "text-primary",
                                  "mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              <span className="flex-1">{item.name}</span>
                              <svg
                                className={classNames(
                                  open
                                    ? "text-gray-400 rotate-90"
                                    : "text-gray-300",
                                  "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  d="M6 6L14 10L6 14V6Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {item.children.map((subItem) => (
                                <Link
                                  key={subItem.name}
                                  to={subItem.href}
                                  activeClassName="border-solid text-primary bg-secondary"
                                  className={
                                    "pl-5 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                  }
                                  exact
                                >
                                  <subItem.icon
                                    className={classNames(
                                      "text-primary",
                                      "mr-3 h-6 w-6"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {subItem.name}
                                </Link>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )
                  )}
                </nav>
              </div>
              <div className="flex-shrink-0 flex p-1 block md:hidden">
                <div className="container mx-auto flex items-center sm:flex-row flex-col justify-center">
                  <img
                    src="/logo.png"
                    alt="Luisa tu asistente"
                    className="w-20"
                  />
                  <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
                    © 2021 - Luisa Tu Asistente{" "}
                  </p>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-1 pb-4 bg-white overflow-y-auto">
            <div className="flex items-center justify-center flex-shrink-0 px-4">
              <img
                className="w-3/4 max-h-24"
                src={config?.urlLogo}
                alt={config?.cf_name}
              />
            </div>
            <div className="mt-6 flex-grow flex flex-col">
              <nav className="flex-1 px-2 bg-white space-y-1">
                {navigation.map((item) =>
                  !item.children ? (
                    <Link
                      key={item.name}
                      to={item.href}
                      activeClassName="border-solid text-primary bg-secondary"
                      className={
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      }
                      exact
                    >
                      <item.icon
                        className={classNames("text-primary", "mr-3 h-6 w-6")}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md">
                            <item.icon
                              className={classNames(
                                "text-primary",
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span className="flex-1">{item.name}</span>
                            <svg
                              className={classNames(
                                open
                                  ? "text-gray-400 rotate-90"
                                  : "text-gray-300",
                                "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                d="M6 6L14 10L6 14V6Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {item.children.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={subItem.href}
                                activeClassName="border-solid text-primary bg-secondary"
                                className={
                                  "pl-5 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                }
                                exact
                              >
                                <subItem.icon
                                  className={classNames(
                                    "text-primary",
                                    "mr-3 h-6 w-6"
                                  )}
                                  aria-hidden="true"
                                />
                                {subItem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
