import { useState, useEffect } from "react";
import { getPatientBalance } from "../http/api/patient";

function usePatientBalance(patientId) {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    (async () => {
      await getBalance(patientId);
    })();
  }, [patientId]);

  const getBalance = async (patientId) => {
    const { data } = await getPatientBalance(patientId);
    setBalance(data);
  };

  return balance;
}

export default usePatientBalance;
