import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import BasicCard from "../components/common/BasicCard";
import PageTitle from "../components/common/PageTitle";
import { setLoaderStatus } from "../redux/loader/slice";
import useCheckPermission from "../hooks/useCheckPermission";
import GenericButton from "../components/common/GenericButton";
import SpecialtyForm from "../components/specialties/SpecialtyForm";
import CustomPagination from "../components/common/CustomPagination";
import CustomModalContent from "../components/common/CustomModalContent";
import SpecialtiesTable from "../components/specialties/SpecialtiesTable";
import { IdentificationIcon, PlusCircleIcon } from "@heroicons/react/solid";
import SpecialtiesFiltersForm from "../components/specialties/SpecialtiesFiltersForm";
import { successAlert } from "../helpers/alerts";
import {
  CREATE_SPECIALTY_PER,
  EDIT_SPECIALTY_PER,
} from "../constants/permissions";
import {
  createSpecialty,
  getSpecialtiesAdmin,
  updateSpecialty,
} from "../http/api/specialty";

const SpecialtiesPage = ({ location, history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [specialty, setSpecialty] = useState({});
  const [specialties, setSpecialties] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const createSpecialtyPermission = useCheckPermission(CREATE_SPECIALTY_PER);
  const editSpecialtyPermission = useCheckPermission(EDIT_SPECIALTY_PER);

  useEffect(() => {
    const getSpecialties = async () => {
      let params = queryString.parse(location.search);
      try {
        dispatch(setLoaderStatus(true));
        const {
          data: { data, per_page, current_page, total },
        } = await getSpecialtiesAdmin(params);

        setActivePage(current_page);
        setSpecialties(data);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    }
    
    getSpecialties();
  }, [dispatch, location.search, toggle]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setSpecialty({});
    setOpen(true);
  };
  const handleEdit = (specialty) => {
    setSpecialty(specialty);
    setOpen(true);
  };

  const filterSearch = (filterData) => {
    let params = queryString.parse(location.search);
    delete params.page;
    params.search_by_string = filterData["searchByString"];
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });

    setToggle((prevState) => !prevState);
  };

  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const saveSpecialty = async (formData) => {
    try {
      dispatch(setLoaderStatus(true));
      const data = {
        name: formData["name"],
        status: formData["status"],
      };

      if (!specialty.id) {
        const { message } = await createSpecialty(data);
        successAlert(message);
      } else {
        data.id = specialty.id;
        const { message } = await updateSpecialty(data, specialty.id);
        successAlert(message);
      }
      handleClose();
      setToggle((prevState) => !prevState);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Especialidades" Icon={IdentificationIcon} />
      <BasicCard>
        <div className="pb-4 grid grid-cols-1 md:grid-cols-3">
          <div className="self-end md:mb-0 mb-2 col-span-2">
            <SpecialtiesFiltersForm onSubmit={filterSearch} />
          </div>
          <div className="self-end flex justify-end">
            <GenericButton
              onClick={handleOpen}
              render={createSpecialtyPermission}
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Crear
            </GenericButton>
            <CustomModalContent
              title={
                specialty.id ? "Actualizar Especialidad" : "Crear Especialidad"
              }
              open={open}
              onClose={handleClose}
              closeOnOverlayClick={false}
            >
              <div className="w-full md:w-72">
                <SpecialtyForm
                  onSubmit={saveSpecialty}
                  editPermission={editSpecialtyPermission}
                  specialty={specialty}
                />
              </div>
            </CustomModalContent>
          </div>
        </div>

        <SpecialtiesTable specialties={specialties} onEdit={handleEdit} />
        <CustomPagination
          totalItemsCount={totalItemsCount}
          onChange={(page) => onChangePagination(page)}
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
        />
      </BasicCard>
    </div>
  );
};

export default withRouter(SpecialtiesPage);
