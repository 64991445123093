import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { FilterIcon } from "@heroicons/react/solid";

const UserFiltersForm = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-2">
        <div className="mb-2 md:mb-0">
          <label
            htmlFor="searchByString"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Nombre/Email
          </label>
          <div className="mt-1">
            <input
              {...register("searchByString")}
              type="text"
              name="searchByString"
              id="searchByString"
              placeholder="Digite el Nombre o Email"
              className="focus:ring-blue-500 focus:border-blue-500 shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="self-end mb-2 md:mb-0">
          <button type="submit" className="btn-secondary-dark">
            <FilterIcon className="-ml-0.5 mr-2 h-4 w-4" />
            Buscar
          </button>
        </div>
      </div>
    </form>
  );
};

UserFiltersForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default UserFiltersForm;
