import React from "react";
import PropTypes from "prop-types";

const OverlayLoadingSpinner = ({active}) => {
  if (!active) {
    return null;
  }
  return (
    <div className="absolute w-full h-full flex items-center justify-center z-10000 bg-gray-900 opacity-80">
      <div>
        <div className="w-16 h-16 border-b-2 border-white-900 rounded-full animate-spin"></div>
        <p className="text-white mt-2">Cargando ...</p>
      </div>
    </div>
  );
};

OverlayLoadingSpinner.propTypes = {
  active: PropTypes.bool,
};

OverlayLoadingSpinner.defaultProps = {
  active: false
}

export default OverlayLoadingSpinner;