import http from "./axiosInstance";

/**
 * Get all data for appointment report
 *
 * @param params
 * @returns {*}
 */
export const getAppointmentReport = (params = {}) => {
  return http().get("/report/appointments-report", { params });
};

/**
 * Get XLS appointment report
 *
 * @param params
 * @returns {*}
 */
 export const getAppointmentReportXls = (params = {}) => {
  const responseType = "blob"
  return http(responseType).get("/report/appointments-report-xls", { params });
};

/**
 * Send appointment resume pdf
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAppointmentResumePdf = (id, data) => {
  return http().post(`/report/appointment-resume/${id}`, data);
};

export const getPatientsReport = (params = {}) => {
  const responseType = "blob"
  return http(responseType).get(`/report/excel`, { params });
};

export const getMediaReport = (params = {}) => {
  return http().get(`/report/media`, { params });
};
