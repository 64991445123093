import React from "react";
import Table from "../common/Table";
import PropTypes from "prop-types";

const MediasTable = ({ medias, onEdit }) => {
  const columns = [
    {
      Header: "Medio",
      accessor: "md_name",
    },
    {
      Header: "Estado",
      accessor: "[md_status]",
      Cell: ({ row: { original } }) => (
        <span className={original.md_status ? "badge-green" : "badge-gray"}>
          {original.md_status ? "Activado" : "Desactivado"}
        </span>
      ),
    },
    {
      Header: "",
      accessor: "[editButton]",
      Cell: ({ row: { original } }) => (
        <div className="text-center">
          <button
            className="text-secondary-dark inline-block font-medium"
            onClick={() => onEdit(original)}
          >
            Editar
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={medias} />
    </div>
  );
};

MediasTable.propTypes = {
  medias: PropTypes.array,
  onEdit: PropTypes.func,
};

MediasTable.defaultProps = {
  medias: [],
};

export default MediasTable;
