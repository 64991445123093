import { useState, useEffect } from "react";
import { getAllSpecialists } from "../http/api/specialist";

function useGetAllSpecialists() {
  const [specialists, setSpecialists] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getAllSpecialists();
      setSpecialists(data);
    })();
  }, []);

  return specialists;
}

export default useGetAllSpecialists;
