import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import DatePicker from "react-datepicker";

import AppointmentForm from "../components/appointments/AppointmentForm";
import CustomModalContent from "../components/common/CustomModalContent";
import SpecialistSelect from "../components/appointments/schedule/SpecialistSelect";
import { statusInfo } from "../constants/appointments";
import { appointmentResumePdf } from "../components/reports/appointmentResumePdf";
import { getAppointmentResumePdf } from "../http/api/reports";
import HelpBoxSchedule from "../components/appointments/schedule/HelpBox";
import MessageBox from "../components/common/MessageBox";
import { getCurrentConfig } from "../redux/session/selectors";
import BasicCard from "../components/common/BasicCard";
import useGetAllSpecialists from "../hooks/useGetAllSpecialists";
import useGetAllAppointmentsStatus from "../hooks/useGetAllAppointmentsStatus";
import { setLoaderStatus } from "../redux/loader/slice";
import { successAlert } from "../helpers/alerts";
import {
  getAppointmentById,
  getAppointmentsSchedule,
  updateAppointmentStatus,
} from "../http/api/appointment";

function AppointmentsPage({ config }) {
  const dispatch = useDispatch();
  const [calendarRef] = useState(React.createRef());
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [appointment, setAppointment] = useState({});
  const [toggleSearch, setToggleSearch] = useState(false);
  const [optionsAppointments, setOptionsAppointments] = useState([]);
  const [currentSpecialist, setCurrentSpecialist] = useState({});
  const [currentAppointments, setCurrentAppointments] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const specialists = useGetAllSpecialists();
  const status = useGetAllAppointmentsStatus();

  useEffect(() => {
    const getAppointments = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const momentDate = moment(currentDate);
        const from_date = momentDate
          .clone()
          .startOf("week")
          .format("YYYY-MM-DD HH:mm");
        const to_date = momentDate
          .clone()
          .endOf("week")
          .format("YYYY-MM-DD HH:mm");
        const { data: appointments } = await getAppointmentsSchedule(
          currentSpecialist.id,
          from_date,
          to_date
        );
        const data = appointments.map((appointment) => {
          const status = appointment.status.aps_code;
          return {
            id: appointment.id,
            title: appointment.patient ? appointment.patient.pa_name : "Cita",
            start: appointment.ap_from,
            end: appointment.ap_to,
            color: statusInfo[status].color,
          };
        });
        setCurrentAppointments(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    if (currentSpecialist.id) {
      getAppointments();
    }
  }, [currentDate, currentSpecialist.id, dispatch, toggleSearch]);

  const onChange = (date) => {
    const formatDate = moment(date).format("YYYY-MM-DD");
    setCurrentDate(formatDate);
    setToggleSearch((prevState) => !prevState);
    calendarRef.current.getApi().gotoDate(formatDate);
  };

  const changeSpecialist = async (data) => {
    if (data) {
      setCurrentSpecialist(data);
    } else {
      setCurrentSpecialist({});
      setCurrentAppointments([]);
    }
    setToggleSearch((prevState) => !prevState);
  };

  const handleDateSelect = (selectInfo) => {
    const { startStr, endStr } = selectInfo;
    const info = appointmentFormat({
      specialistId: currentSpecialist.id,
      startStr: startStr,
      endStr: endStr,
    });
    setOptionsAppointments([]);
    setAppointment(info);
    handleOpen();
  };

  const getResumePdf = async (id, data = null) => {
    try {
      dispatch(setLoaderStatus(true));
      const { data: appointmentResume } = await getAppointmentResumePdf(
        id,
        data
      );
      appointmentResume.logo = config?.urlLogo;
      if (!data) appointmentResumePdf(appointmentResume);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  const onSaveAppointment = (data) => {
    setToggleSearch((prevState) => !prevState);
    handleClose();
  };

  const onAppointmentClick = async (dataAppointment) => {
    let options = [];
    const { event } = dataAppointment;
    try {
      dispatch(setLoaderStatus(true));
      const { data } = await getAppointmentById(event.id);
      let excludeStatus = ["CREATED", "RESERVED", data.status.aps_code];

      const info = appointmentFormat({
        specialistId: data.ap_specialist_id,
        startStr: data.ap_from,
        endStr: data.ap_to,
        smsNotifications: data.ap_sms_notifications,
        description: data.ap_description,
        updateAppointment: data,
        patient: data.patient,
      });

      if (data.status.aps_code !== "RESERVED") {
        options.push({
          text: "Imprimir",
          onClick: () => {
            getResumePdf(event.id);
          },
        });

        options.push({
          text: "Enviar por correo",
          onClick: () => {
            getResumePdf(event.id, { email: true });
          },
        });

        options.push({
          text: "Enviar por SMS",
          onClick: () => {
            getResumePdf(event.id, { sms: true });
          },
        });
      } else {
        excludeStatus.push("CONFIRMED");
      }

      status.filter((item) => {
        if (!excludeStatus.includes(item.aps_code)) {
          options.push({
            text: `Cambiar estado a ${item.aps_status}`,
            onClick: () => {
              changeAppointmentStatus(event.id, item.id);
            },
          });
        }
      });

      setOptionsAppointments(options);
      setAppointment(info);
      handleOpen();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  const appointmentFormat = ({
    specialistId,
    startStr,
    endStr,
    smsNotifications,
    description = null,
    updateAppointment = null,
    patient = null,
  }) => {
    const data = {
      id: updateAppointment?.id,
      reserved: updateAppointment?.status.aps_code === "RESERVED",
      patient,
      specialistId: specialistId,
      date: moment(startStr).format("MM/DD/YYYY"),
      from: moment(startStr).format("HH:mm"),
      to: moment(endStr).format("HH:mm"),
      description: description,
      smsNotifications,
    };
    return data;
  };

  const handleDatesSet = async (data) => {
    const date = moment(data.startStr).format("YYYY-MM-DD");
    setCurrentDate(date);
    setToggleSearch((prevState) => !prevState);
  };

  const changeAppointmentStatus = async (appointmentId, statusId) => {
    try {
      dispatch(setLoaderStatus(true));
      const data = { statusId };
      const { message } = await updateAppointmentStatus(data, appointmentId);
      successAlert(message);
      setToggleSearch((prevState) => !prevState);
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <div className="mb-6">
        <SpecialistSelect
          specialists={specialists}
          onChange={changeSpecialist}
        />
      </div>
      {!currentSpecialist.id && (
        <MessageBox message="Por favor seleccione un profesional para continuar" />
      )}
      {currentSpecialist.id && (
        <BasicCard>
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <aside className="hidden xl:flex xl:flex-col flex-shrink-0 w-72 border-r border-gray-200">
              <DatePicker
                onChange={onChange}
                placeholderText="Select date"
                popperPlacement="top-start"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                inline
              />
              <div className="mt-2">
                <HelpBoxSchedule status={status} />
              </div>
            </aside>
            <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                editable={true}
                selectable={true}
                select={handleDateSelect}
                locale="es"
                ref={calendarRef}
                allDaySlot={false}
                timeZone="local"
                initialDate={currentDate}
                events={currentAppointments}
                slotDuration="00:15:00"
                eventClick={onAppointmentClick}
                datesSet={handleDatesSet}
              />
              <CustomModalContent
                title={appointment.id ? "Actualizar Cita" : "Crear Cita"}
                open={open}
                onClose={handleClose}
                optionsMenu={optionsAppointments}
                closeOnOverlayClick={false}
              >
                <div className="w-full md:w-72">
                  <AppointmentForm
                    initialValues={appointment}
                    onSaveAppointment={onSaveAppointment}
                  />
                </div>
              </CustomModalContent>
            </div>
          </div>
        </BasicCard>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  config: getCurrentConfig(state),
});

export default connect(mapStateToProps)(AppointmentsPage);
