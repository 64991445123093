import React from 'react';
import PropTypes from "prop-types";
import Table from "../../common/Table";
import { SHOW_PAYMENT_PLAN_ORDER } from '../../../constants/permissions';
import useCheckPermission from '../../../hooks/useCheckPermission';

const PatientOrdersTable = ({orders, onEdit, onOpenPlan}) => {
  const show_plan = useCheckPermission(SHOW_PAYMENT_PLAN_ORDER);
  const columns = [
    {
      Header: 'Orden #',
      accessor: 'id',
    },
    {
      Header: 'Concepto',
      accessor: 'or_concept'
    },
    {
      Header: 'Convenio',
      accessor: 'agreement.ag_name'
    },
    {
      Header: '',
      accessor: '[editButton]',
      Cell: ({ row:{original} }) => <div className="text-center">
        <button
          className="text-secondary-dark inline-block font-medium"
          onClick={() => onEdit(original)}
        >
          Editar
        </button>
        {show_plan &&
          <button
            className="text-secondary-dark inline-block font-medium ml-8"
            onClick={() => onOpenPlan(original)}
          >
            Plan de pagos
          </button>
        }
      </div>
    }
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={orders} />
    </div>
  )
}

PatientOrdersTable.propTypes = {
  orders: PropTypes.array,
  onEdit: PropTypes.func,
  onOpenPlan: PropTypes.func
};

PatientOrdersTable.defaultProps = {
  orders: []
};

export default PatientOrdersTable;
