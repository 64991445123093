import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import CustomTextInput from "../common/CustomTextInput";
import CustomTextarea from "../common/CustomTextarea";
import { getProductByCode } from "../../http/api/products";
import NumberFormat from "react-number-format";

const ProductForm = ({ product, editPermission, onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    reset({
      code: product?.pr_code,
      name: product?.pr_name,
      formatValue: product?.pr_value,
      value: product?.pr_value,
      description: product?.pr_description,
      status: product?.pr_status,
    });
  }, [product, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <CustomTextInput
          {...register("code", {
            required: "Requerido",
            validate: {
              checkCode: async (value) => {
                if (value !== product.pr_code) {
                  const { data } = await getProductByCode(value);
                  return !data || "El código ya existe!";
                }
                return true;
              },
            },
          })}
          type="text"
          name="code"
          id="code"
          label="Código*"
          errors={errors}
        />
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register("name", { required: "Requerido" })}
          type="text"
          name="name"
          id="name"
          label="Nombre*"
          errors={errors}
        />
      </div>
      <div className="mb-2">
        <Controller
          render={({ field }) => (
            <NumberFormat
              {...field}
              thousandSeparator={true}
              customInput={CustomTextInput}
              label="Precio*"
              prefix={"$ "}
              onValueChange={(values) => {
                const { value } = values;
                setValue("value", value);
              }}
              errors={errors}
            />
          )}
          name="formatValue"
          id="formatValue"
          control={control}
          rules={{
            required: "Requerido"
          }}
        />
      </div>
      <div className="mb-2">
        <CustomTextarea
          {...register("description")}
          id="description"
          name="description"
          label="Descripción"
        />
      </div>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <input
            {...register("status")}
            type="checkbox"
            id="status"
            name="status"
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="status" className="ml-2 block text-sm text-gray-900">
            Activo
          </label>
        </div>
      </div>
      <div className="flex justify-end">
        {((editPermission && product?.id) || !product?.id) && (
          <button type="submit" className="btn-secondary-dark">
            Guardar
          </button>
        )}
      </div>
    </form>
  );
};

ProductForm.propTypes = {
  product: PropTypes.object,
  editPermission: PropTypes.bool,
  onSubmit: PropTypes.func,
};

ProductForm.defaultProps = {
  editPermission: true,
};

export default ProductForm;
