import React from "react";
import Table from "../common/Table";
import PropTypes from "prop-types";

const ProductsTable = ({ products, showEdit, onEdit }) => {
  const numberFormat = new Intl.NumberFormat();

  const columns = [
    {
      Header: "Código",
      accessor: "pr_code",
    },
    {
      Header: "Producto",
      accessor: "pr_name",
    },
    {
      Header: "Precio(Unidad)",
      accessor: "pr_value",
      Cell: ({ row: { original } }) =>
        `$ ${numberFormat.format(original.pr_value)}`,
    },
    {
      Header: "Estado",
      accessor: "pr_status",
      Cell: ({ row: { original } }) => (
        <span className={original.pr_status ? "badge-green" : "badge-gray"}>
          {original.pr_status ? "Activado" : "Desactivado"}
        </span>
      ),
    },
    {
      Header: "",
      accessor: "[editButton]",
      Cell: ({ row: { original } }) => (
        <div className="text-center">
          {showEdit && (
            <button
              onClick={() => onEdit(original)}
              className="text-secondary-dark inline-block font-medium"
            >
              Editar
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={products} />
    </div>
  );
};

ProductsTable.propTypes = {
  products: PropTypes.array,
  onEdit: PropTypes.func,
};

ProductsTable.defaultProps = {
  products: [],
};

export default ProductsTable;
