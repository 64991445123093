import React from "react";
import { useTable } from "react-table";
import PropTypes from "prop-types";

const Table = ({columns, data}) => {
  const columnsData = React.useMemo(
    () => columns,
    [columns]
  )

  const tableInstance = useTable({ columns: columnsData, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    // apply the table props
    <div className="inline-block min-w-full overflow-hidden border border-gray-300 rounded-xl">
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead className="bg-tertiary-dark">
        {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-gray-300">
              {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps()}
                      className="px-3 py-2 text-left text-sm font-bold text-primary capitalize tracking-wider border-none"
                  >
                    {// Render the header
                      column.render('Header')}
                  </th>
                ))}
            </tr>
          ))
        }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
        {// Loop over the table rows
          rows.map((row, index) => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr {...row.getRowProps()} className={index % 2 === 0 ? 'bg-tertiary' : 'bg-white'}>
                {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td {...cell.getCellProps()}
                          className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-500 border border-gray-300"
                      >
                        {// Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array
};

Table.defaultProps = {
  columns: [],
  data: []
}

export default Table;
