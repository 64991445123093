import React from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import {Redirect, withRouter} from "react-router-dom";

import ResetPasswordForm from "../components/session/ResetPasswordForm";
import {resetPassword} from "../http/api/session";
import { setLoaderStatus } from '../redux/loader/slice';
import { successAlert } from '../helpers/alerts';

const ResetPasswordPage = ({location}) => {
  const dispatch = useDispatch();
  let params = queryString.parse(location.search);
  if (!params.token) {
    return <Redirect to="/" />
  }

  const resetPasswordUser = async(formData) => {
    const data = {
      "email":formData['email'],
      "token":params.token,
      "password":formData['password'],
      "password_confirmation":formData['repeatPassword']
    }

    try {
      dispatch(setLoaderStatus(true));
      const { message } = await resetPassword(data);
      successAlert(message);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  }

  return(
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-32 xl:px-72 bg-tertiary">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex justify-center">
            <img
              className="w-3/4"
              src="/logo.png"
              alt="Luisa tu asistente"
            />
          </div>

          <div className="mt-8">
            <div className="justify-center text-center">
              <h1 className="text-primary text-2xl font-bold">Restablecer la contraseña</h1>
            </div>
            <div className="mt-6">
              <ResetPasswordForm onSubmit={resetPasswordUser} />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/login.png"
          alt="login"
        />
      </div>
    </div>
  )
}


export default withRouter(ResetPasswordPage);

