import {statusInfo} from "../../../constants/appointments";
import PropTypes from "prop-types";

const HelpBoxSchedule = ({status}) => {
  return (
    <div>
      <ul>
        {status && status.map((item) => {
          let status = '';
          if(item.aps_code !== 'CANCELLED') {
            status = (
              <li key={item.aps_code} className="pb-2"  style={{color: statusInfo[item.aps_code]?.color }}>
                <span className="px-2.5 mr-1 rounded-3xl" style={{backgroundColor: statusInfo[item.aps_code]?.color }} /> {item.aps_status}
              </li>
            )
          }
          return status;
        })}
      </ul>
    </div>
  )
}

HelpBoxSchedule.propTypes = {
  status: PropTypes.array
}

export default HelpBoxSchedule;
