import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getPatientClinicalHistory } from "../http/api/patient";
import ConsultationsResume from "../components/clinicalHistory/ConsultationsResume";
import { CalendarIcon } from "@heroicons/react/solid";
import PageTitle from "../components/common/PageTitle";
import { setLoaderStatus } from "../redux/loader/slice";

const ClinicalHistoryResumePage = () => {
  const { id } = useParams();
  const [patient, setPatient] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const getPatient = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getPatientClinicalHistory(id);
        setPatient(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatient();
  }, [dispatch, id]);

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Historia Clinica" Icon={CalendarIcon} />
      <ConsultationsResume
        consultations={patient.clinical_history?.consultations}
      />
    </div>
  );
};

export default ClinicalHistoryResumePage;
