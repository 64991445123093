import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { getOrderTotal } from "../../../http/api/orders";
import { getActivePlanByOrder } from "../../../http/api/orderPaymentPlan";
import PaymentPlanNew from "./PaymentPlanNew";
import PaymentPlanEdit from "./PaymentPlanEdit";
import { setLoaderStatus } from "../../../redux/loader/slice";

const PaymentPlan = ({ orderId, onPayQuota, onDeletePlan, onSavePlan }) => {
  const [plan, setPlan] = useState({});
  const [order, setOrder] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const getOrder = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data } = await getOrderTotal(orderId);
        setOrder(data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getOrder();
  }, [dispatch, orderId]);

  useEffect(() => {
    const getPlan = async () => {
      try {
        dispatch(setLoaderStatus(true));
        const { data: plan } = await getActivePlanByOrder(orderId);
        setPlan(plan);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPlan();
  }, [dispatch, orderId]);

  if (!order) {
    return null;
  }
  return (
    <>
      {!plan?.id ? (
        <PaymentPlanNew order={order} onSavePlan={() => onSavePlan()} />
      ) : (
        <PaymentPlanEdit
          order={order}
          plan={plan}
          onPayQuota={() => onPayQuota()}
          onDeletePlan={() => onDeletePlan()}
        />
      )}
    </>
  );
};

PaymentPlan.propTypes = {
  orderId: PropTypes.number.isRequired,
  onPayQuota: PropTypes.func,
  onDeletePlan: PropTypes.func,
  onSavePlan: PropTypes.func,
};

export default PaymentPlan;
