import React from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';

import CustomTextInput from "../common/CustomTextInput";
import {regExpEmail} from "../../helpers/regexp";

import { setLoaderStatus } from '../../redux/loader/slice';
import { forgotPassword } from '../../http/api/session';
import { successAlert } from '../../helpers/alerts';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState:{ errors } } = useForm();

  const forgotPasswordUser = async(formData) => {
    try {
      dispatch(setLoaderStatus(true));
      const { message } = await forgotPassword(formData);
      successAlert(message);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  }

  return(
    <form onSubmit={handleSubmit(forgotPasswordUser)} className="space-y-6">
      <div>
        <div className="mt-1">
          <CustomTextInput
            {...register('email', {
              required: 'Requerido',
              pattern: {value: regExpEmail, message: 'Email invalido'},
            })}
            type="text"
            name="email"
            id="email"
            label="Correo Eléctronico"
            placeholder="Correo Eléctronico"
            errors={errors}
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Enviar enlace de contraseña
        </button>
      </div>
    </form>
  )
}

export default ForgotPasswordForm;
