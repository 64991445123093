import React from "react";
import { Redirect, Route } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard/DashboardLayout";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPermissions, getToken } from "../redux/session/selectors";

const DashboardLayoutRoute = ({
  component: Component,
  permission,
  userPermissions,
  token,
  ...rest
}) => {
  let auth = !!token;
  let access = false;
  let redirect = "/login";

  if (auth) {
    if (
      permission === "" ||
      !permission ||
      userPermissions.includes(permission)
    ) {
      access = true;
    } else {
      redirect = "/";
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        access ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect to={redirect} />
        )
      }
    />
  );
};

DashboardLayoutRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  permission: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userPermissions: getPermissions(state),
  token: getToken(state),
});

export default connect(mapStateToProps)(DashboardLayoutRoute);
