import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import CustomTextInput from "../common/CustomTextInput";
import PropTypes from "prop-types";

const SpecialtyForm = ({specialty, editPermission, onSubmit}) => {
  const { register, handleSubmit, reset, formState:{ errors } } = useForm();

  useEffect(() => {
    reset();
  }, [specialty, reset]);

  return(
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <CustomTextInput
          {...register('name', {required: 'Requerido'})}
          type="text"
          name="name"
          id="name"
          label="Nombre*"
          errors={errors}
          defaultValue={specialty?.spc_name}
        />
      </div>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <input
            {...register('status')}
            type="checkbox"
            id="status"
            name="status"
            defaultChecked={specialty?.spc_status}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="status" className="ml-2 block text-sm text-gray-900">
            Activo
          </label>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex justify-end">
          {((editPermission && specialty?.id) || !specialty?.id) &&
            <button
              type="submit"
              className="btn-secondary-dark"
            >
              Guardar
            </button>
          }
        </div>
      </div>
    </form>
  );
}

SpecialtyForm.propTypes = {
  specialty: PropTypes.object,
  editPermission: PropTypes.bool,
  onSubmit: PropTypes.func
};

SpecialtyForm.defaultProps = {
  editPermission: true
}

export default SpecialtyForm;
