import React from "react";
import PropTypes from 'prop-types';
import {ErrorMessage} from "@hookform/error-message";

const CustomErrorMessage = ({ className, ...props}) => {
  return <ErrorMessage
    {...props}
    render={({message}) => <span className={`mt-1 text-sm text-red-600 ${className}`}>{message}</span>}
  />
}

CustomErrorMessage.propTypes = {
  className: PropTypes.string
}

export default CustomErrorMessage;
