import React from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";

import { DownloadIcon } from "@heroicons/react/solid";
import CustomErrorMessage from "../common/CustomErrorMessage";

const ReportAppointmentsForm = ({ specialists, generateReport }) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const from = watch("from");
  const to = watch("to");

  return (
    <form
      onSubmit={handleSubmit(generateReport)}
      noValidate
      className="space-y-8 divide-y divide-gray-200"
    >
      <div className="px-4 py-5 space-y-6 sm:p-6">
        <div>
          <label
            htmlFor="from"
            className="block text-sm font-medium text-gray-700"
          >
            Fecha Inicial *
          </label>
          <Controller
            name="from"
            control={control}
            rules={{ required: "Requerido" }}
            render={({ field }) => (
              <DatePicker
                onChange={(e) => {
                  field.onChange(e);
                }}
                selected={field.value}
                selectsStart
                startDate={from}
                endDate={to}
                maxDate={to}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className={`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md
                  ${
                    errors.to
                      ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                      : "focus:ring-blue-500 focus:border-blue-500"
                  }`}
              />
            )}
          />
          <CustomErrorMessage errors={errors} name="from" />
        </div>
        <div>
          <label
            htmlFor="to"
            className="block text-sm font-medium text-gray-700"
          >
            Fecha Final *
          </label>
          <Controller
            name="to"
            control={control}
            rules={{ required: "Requerido" }}
            render={({ field }) => (
              <DatePicker
                onChange={(e) => {
                  field.onChange(e);
                }}
                selected={field.value}
                selectsEnd
                startDate={from}
                endDate={to}
                minDate={from}
                maxDate={new Date(moment(from).add(1, "Y"))}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className={`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md
                  ${
                    errors.to
                      ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                      : "focus:ring-blue-500 focus:border-blue-500"
                  }`}
              />
            )}
          />
          <CustomErrorMessage errors={errors} name="to" />
        </div>
        <div>
          <label
            htmlFor="specialistId"
            className="block text-sm font-medium text-gray-700"
          >
            Especialista *
          </label>
          <div className="mt-1">
            <select
              id="specialistId"
              name="specialistId"
              {...register("specialistId")}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value="0">Todos</option>
              {specialists &&
                specialists.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.user.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="format"
            className="block text-sm font-medium text-gray-700"
          >
            Formato *
          </label>
          <div className="mt-1">
            <select
              id="format"
              name="format"
              {...register("format")}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value="pdf">PDF</option>
              <option value="xls">XLS</option>
            </select>
          </div>
        </div>
        <button type="submit" className="btn-secondary-dark">
          <DownloadIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Descargar
        </button>
      </div>
    </form>
  );
};

export default ReportAppointmentsForm;
