import React from 'react';
import PropTypes from "prop-types";
import Table from "../../../common/Table";

const PaymentPlanTable = ({quotas, onPaymentQuote}) => {
  const numberFormat = new Intl.NumberFormat();

  const columns = [
    {
      Header: 'Cuota Numero',
      accessor: 'quotaIndex',
    },
    {
      Header: 'Valor de la cuota',
      accessor: 'quotaValue',
      Cell: ({row: {original}}) => `$ ${numberFormat.format(original.quotaValue)}`
    },
    {
      Header: 'Saldo posterior pago',
      accessor: 'balance',
      Cell: ({row: {original}}) => `$ ${numberFormat.format(original.balance)}`
    },
    {
      Header: 'Pago Cuota',
      accessor: '[options]',
      Cell: ({row: {original}}) => {
        if(!original.payButton && original.quotaStatus === 1){
          return null;
        }
        return (
          <div className="text-center">
            {original.quotaStatus === 1
              ? <button type="button"
                        className="text-secondary-dark inline-block font-medium"
                        onClick={() => onPaymentQuote(original)}>Pagar</button>
              : <span>Cancelada</span>
            }
          </div>
        )
      }
    }
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={quotas}/>
    </div>
  )
}

PaymentPlanTable.propTypes = {
  quotas: PropTypes.array,
  onPaymentQuote: PropTypes.func
};

PaymentPlanTable.defaultProps = {
  quotas: []
};

export default PaymentPlanTable;
