import React from 'react';
import PropTypes from "prop-types";
import Table from "../../../common/Table";

const PaymentPlanNewTable = ({quotas, onRemove, onAdd}) => {
  const numberFormat = new Intl.NumberFormat();

  const columns = [
    {
      Header: 'Cuota Numero',
      accessor: 'quotaIndex',
    },
    {
      Header: 'Valor de la cuota',
      accessor: 'quotaValue',
      Cell: ({ row:{original} }) => `$ ${numberFormat.format(original.quotaValue)}`
    },
    {
      Header: 'Saldo porterior pago',
      accessor: 'balance',
      Cell: ({row: {original}}) => `$ ${numberFormat.format(original.balance)}`
    },
    {
      Header: '',
      accessor: '[options]',
      Cell: ({row: {original, index}}) => {
        return(
          <div className="text-center">
            <button
              className="text-secondary-dark inline-block font-medium"
              onClick={() => onAdd(index)}
            >
              Agregar
            </button>
            <button
              className="text-red-700 ml-4 inline-block font-medium"
              onClick={() => onRemove(original)}
            >
              Eliminar
            </button>
          </div>
        )
      }
    }
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={quotas} />
    </div>
  )
}

PaymentPlanNewTable.propTypes = {
  quotas: PropTypes.array,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func
};

PaymentPlanNewTable.defaultProps = {
  quotas: []
};

export default PaymentPlanNewTable;
