import ConsultationDetail from "./ConsultationDetails";
import moment from "moment";
import React from "react";

const ConsultationsResume = ({consultations}) => {
  return (
    <>
      <div className="py-2 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {consultations && consultations.map((consultation, index) => (
          <div key={index}
            className="mb-12 bg-white overflow-hidden shadow rounded-lg mt-4 col-span-8 px-4 py-5 sm:p-6 divide-primary overflow-y-auto h-full">
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"/>
              </div>
              <div className="relative flex justify-start">
              <span className="pr-2 bg-tertiary text-2xl font-medium text-gray-500">
                {moment(consultation.co_created_at).format('YYYY-MM-DD')}
              </span>
              </div>
            </div>
            <div className="ml-10 mt-4">
              <ConsultationDetail consultation={consultation}/>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ConsultationsResume;
