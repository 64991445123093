import React from "react";
import PropTypes from "prop-types";
import {InformationCircleIcon} from "@heroicons/react/solid";

const MessageBox = ({title, message}) => {
  return (
    <div className="flex justify-center">
      <div className="xl:w-1/3 md:w-1/2 p-4">
        <div className="border border-gray-200 p-6 rounded-lg bg-white text-center">
          <div className="w-10 h-10 inline-flex items-center justify-center rounded-full text-secondary-dark mb-2">
            <InformationCircleIcon />
          </div>
          <h3 className="text-2xl text-gray-900 font-medium title-font mb-2 text-secondary-dark">{title}</h3>
          <p className="leading-relaxed text-base">{message}</p>
        </div>
      </div>
    </div>
  )
}

MessageBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
}

MessageBox.defaultProps = {
  title: 'Atención',
  message: 'Mensaje'
}

export default  MessageBox;
