import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const appointmentResumePdf = ({
  patient,
  specialist: { user },
  date,
  time,
  length,
  ap_description,
  logo,
}) => {
  const appointmentResumeDoc = [];
  const currentDate = new Date();
  const options = { year: "numeric", month: "2-digit", day: "numeric" };

  appointmentResumeDoc.push({
    text: "SU PRÓXIMA CITA ES",
    style: "header",
    alignment: "center",
  });

  appointmentResumeDoc.push({
    text: [{ text: currentDate.toLocaleDateString("es-CO", options) }],
    style: "marginButtonHalfParagraph",
  });

  appointmentResumeDoc.push({
    table: {
      body: [
        [
          { text: "Paciente:", bold: true },
          { text: patient?.pa_name, colSpan: 3 },
          {},
          {},
        ],
        [
          { text: "Documento:", bold: true },
          patient?.pa_document,
          { text: "Profesional:", bold: true },
          user?.name,
        ],
        [
          { text: "Fecha:", bold: true },
          date,
          { text: "Hora:", bold: true },
          time,
        ],
        [
          { text: "Duración:", bold: true },
          { text: length, colSpan: 3 },
          {},
          {},
        ],
        [
          { text: "Descripción:", bold: true },
          { text: ap_description, colSpan: 3 },
          {},
          {},
        ],
      ],
      headerRows: 1,
    },
    style: "marginButtonParagraph",
    layout: "noBorders",
  });

  const docDefinition = {
    header: [
      {
        image: "logo",
        width: 120,
        height: 100,
        margin: [40, 10, 200, 0],
      },
    ],
    content: appointmentResumeDoc,
    images: {
      logo: logo,
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "justify",
        margin: [0, 30],
      },
      marginLeft: {
        margin: [8, 0, 0, 0],
      },
      marginConsultationParagraph: {
        margin: [8, 0, 0, 20],
      },
      marginButtonParagraph: {
        margin: [0, 0, 0, 20],
      },
      marginButtonHalfParagraph: {
        margin: [0, 0, 0, 5],
      },
    },
  };
  pdfMake.createPdf(docDefinition).download(`Cita ${patient?.pa_document}`);
};
