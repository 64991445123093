import React, { useState } from "react";import {useForm} from "react-hook-form";

import CustomTextarea from "../common/CustomTextarea";

import moment from "moment";




const FormNotes = ({ odontograms , setData ,setEdit, setDisableEdit, jsonEdit, onSubmit, odontoNotes, idPacient}) => {

    const { register, handleSubmit } = useForm();
    const [sltOdonto, setSltOdonto] = useState([]);
    const tab = '\u00A0';
    const onEdit=(data)=>{
        if (data) {
            try {
                let json = JSON.parse(data)
                let actualJson = jsonEdit;
                if (json) {
                    if (JSON.stringify(json) === JSON.stringify(actualJson)) {
                        setDisableEdit(false)
                    }else {
                        setDisableEdit(true)
                    }
                    setEdit(true)
                    setData(json)
                }else{
                    setEdit(false)
                }
            } catch (e) {
                setEdit(false)
                console.log(e);
            }
        }
    }

    return (
        <div>
            <div className="mb-3">
                <select
                    id="history"
                    onChange={(e) => setSltOdonto(e.target.value)}
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                    <option value="">-- Seleccionar --</option>
                    {odontograms[0] && odontograms.map((item) => (
                        <option key={item.id} value={item.json}>
                            {moment(item.created_at).format('DD-MM-YYYY H:m:s')}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-3">
                <button className="btn-secondary-dark" onClick={() => { onEdit(sltOdonto) }}>
                    Cargar Odontograma
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-1">
                    <div className="flex items-left">
                        <input
                            {...register('oc_odontograms_id')}
                            type="hidden"
                            id="oc_odontograms_id"
                            name="oc_odontograms_id"
                            defaultValue={odontograms[0] ? odontograms[0].id: 0}
                        />
                        <input
                            {...register('id')}
                            type="hidden"
                            id="notes_id"
                            name="id"
                            defaultValue={odontoNotes?.id}
                        />
                        <input
                            {...register('notes')}
                            type="checkbox"
                            id="status"
                            name="notes"
                            defaultChecked={odontoNotes?.notes}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="description" className="mb-1 block text-sm font-medium text-gray-700">
                            {tab}  Activar aviso notas importantes del paciente
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="status" className="ml-2 block text-sm text-gray-900">
                        Al marcar esta opción se activará un aviso en la parte superior informando que el paciente cuenta con notas importantes que deben ser tenidas en cuenta por el profesional
                    </label>
                </div>
                <div className="mb-3">
                    {<CustomTextarea
                        {...register("comment")}
                        id="description"
                        name="comment"
                        placeholder="Ingrese aqui las notas importantes a tener en cuenta"
                        defaultValue={odontoNotes?.comment}
                    />}
                </div>
                <div className="mb-3">
                    { odontograms[0] ? <button
                        type="submit"
                        className="btn-secondary-dark"
                    >
                        Guardar notas importantes
                    </button> : 'No existe odontograma'}
                </div>
            </form>
        </div>

    );
}
export default FormNotes;