import React from "react";
import { useForm } from "react-hook-form";
import {regExpEmail} from "../../helpers/regexp";
import {getUserByEmail} from "../../http/api/user";
import CustomTextInput from "../common/CustomTextInput";
import PropTypes from "prop-types";

const UserForm = ({user, specialties, roles, editPermission, onSubmit}) => {
  const { register, handleSubmit, watch, formState:{ errors } } = useForm({mode: "onBlur"});
  const watchShowSpecialty = watch('doctor', user.specialist);
  const password = watch("password", "");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <CustomTextInput
          {...register('name', {required: 'Requerido'})}
          type="text"
          name="name"
          id="name"
          label="Nombre*"
          errors={errors}
          defaultValue={user?.name}
        />
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register('email', {
            required: 'Requerido',
            pattern: {value: regExpEmail, message: 'Email invalido'},
            validate: {
              checkMail: async (value) => {
                if(value !== user.email){
                  const { data } = await getUserByEmail(value);
                  return !data || 'El correo ya existe!';
                }
                return true;
              }
            }
          })}
          type="text"
          name="email"
          id="email"
          label="Email*"
          errors={errors}
          defaultValue={user?.email}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="roleId" className="mb-1 block text-sm font-medium text-gray-700">
          Rol*
        </label>
        <select
          {...register('roleId')}
          defaultValue={user?.roles ? user?.roles[0]?.name : '' }
          id="roleId"
          name="roleId"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        >
          {roles && roles.map((role) =>
            <option key={role.id} value={role.name}>{role.name}</option>
          )}
        </select>
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register('password', {
            required: user.id ? false : 'Requerido',
            minLength: {
              value: 8,
              message: "Minimo 8 caracteres"
            }
          })}
          type="password"
          name="password"
          id="password"
          label="Contraseña*"
          errors={errors}
        />
      </div>
      <div className="mb-2">
        <CustomTextInput
          {...register('repeatPassword', {
            validate: value =>
              value === password || "Las contraseñas no coinciden."
          })}
          type="password"
          name="repeatPassword"
          id="repeatPassword"
          label="Repita la contraseña*"
          errors={errors}
        />
      </div>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <input
            {...register('doctor')}
            type="checkbox"
            id="doctor"
            name="doctor"
            defaultChecked={user?.specialist}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="doctor" className="ml-2 block text-sm text-gray-900">
            Marque si el usuario es del área de la salud
          </label>
        </div>
      </div>
      {watchShowSpecialty && (
        <div className="mb-2">
          <label htmlFor="specialtyId" className="mb-1 block text-sm font-medium text-gray-700">
            Especialidad*
          </label>
          <select
            {...register('specialtyId')}
            defaultValue={user?.specialist?.specialties[0]?.id}
            id="specialtyId"
            name="specialtyId"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {specialties && specialties.map((specialty) =>
              <option
                key={specialty.id}
                value={specialty.id}
              >{specialty.spc_name}</option>
            )}
          </select>
        </div>
      )}
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <input
            {...register('status')}
            type="checkbox"
            id="status"
            name="status"
            defaultChecked={user?.status}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="status" className="ml-2 block text-sm text-gray-900">
            Activo
          </label>
        </div>
      </div>
      <div className="flex justify-end">
        {((editPermission && user?.id) || !user?.id) &&
          <button
            type="submit"
            className="btn-secondary-dark"
          >
            Guardar
          </button>
        }
      </div>
    </form>
  );
}

UserForm.propTypes = {
  user: PropTypes.object,
  specialties: PropTypes.array,
  roles: PropTypes.array,
  editPermission: PropTypes.bool,
  onSubmit: PropTypes.func
}

UserForm.defaultProps = {
  editPermission: true
}

export default UserForm;
