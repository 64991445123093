import React from 'react';
import ForgotPasswordForm from "../components/session/ForgotPasswordForm";

const ForgotPasswordPage = () => {
  return(
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-32 xl:px-72 bg-tertiary">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex justify-center">
            <img
              className="w-3/4"
              src="/logo.png"
              alt="Luisa tu asistente"
            />
          </div>

          <div className="mt-8">
            <div className="justify-center text-center">
              <h1 className="text-primary text-2xl font-bold">Has olvidado tu contraseña</h1>
            </div>
            <div className="mt-6">
              <ForgotPasswordForm />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/login.png"
          alt="login"
        />
      </div>
    </div>
  )
}

export default ForgotPasswordPage;
