import axios from 'axios';
import {errorAlert} from "../../helpers/alerts";

export default function instance (responseType = null) {
  const configInstance = {
    headers: {
      'Content-type': 'application/json'
    }
  }

  if(responseType){
    configInstance.responseType = responseType;
  }
  const instance = axios.create(configInstance);

  /**
   * Change dinamic tenant url
   */
  const pathArray = window.location.pathname.split('/');
  const client = pathArray[1];
  instance.defaults.baseURL = `${process.env.REACT_APP_TENANT_API}/${client}/api`;

  /**
   * Calls an interceptor each time a request is called and
   * adds the authorization token to the request headers.
   **/
  instance.interceptors.request.use(async (config) => {
    const root = JSON.parse(window.localStorage.getItem('persist:root'));
    const session = JSON.parse(root.session);
    if(!['/login'].includes(config.url)){
      config.headers.Authorization = session.token ? `Bearer ${session.token}` : ''
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  })

  /**
   * Calls an interceptor each time a response is received and
   * and perform certain actions according to the answer
   **/
  instance.interceptors.response.use(function(response) {
    return response.data;
  }, function(error) {
    if (error && error.response && error.response.data) {
      const { message } = error.response.data;
      errorAlert('Error', `${message?.errorInfo ? message?.errorInfo : message}`);
    } else {
      errorAlert();
    }
    return Promise.reject(error);
  });

  /**
   * Calls an interceptor each time a response is received
   * and validate status code; if it's 401 (unauthorized) redirect to
   * login page.
   **/
    instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (error.response.status === 401 || error.response.status === 403) {
        if(error.response.config.url !== '/login'){
          //window.localStorage.clear()
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  )

  return instance
}
