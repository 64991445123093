import React from 'react';
import CustomErrorMessage from "./CustomErrorMessage";
import PropTypes from "prop-types";

const CustomTextarea = React.forwardRef(({name, id, label, errors, ...props}, ref) => {
  return (
    <>
      {label &&
        <label htmlFor={name} className="mb-1 block text-sm font-medium text-gray-700">
          {label}
        </label>
      }
      <textarea
        ref={ref}
        name={name}
        id={id}
        className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md
          ${errors && errors[name]
          ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
          : "focus:ring-blue-500 focus:border-blue-500"}`
        }
        {...props}
      />
      <CustomErrorMessage
        errors={errors}
        name={name}
      />
    </>
  )
});

CustomTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object
};

CustomTextarea.defaultProps = {
  errors: {}
}

export default CustomTextarea;
