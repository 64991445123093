import {
    Svg1,
    Svg2,
    Svg4,
    Svg5,
    Svg6,
    Svg7,
    Svg8,
    Svg9,
    Svg10,
    Svg11,
    Svg12,
    SvgCarillaColocada,
    SvgCarillaIndicada,
    SvgNucleoColocado,
    SvgNucleoIndicado, SvgFractura
} from './Svgs'

import {
    Svg01,
    Svg02,
    Svg03,
    Svg04,
    Svg05,
    Svg06,
    Svg07,
    Svg08,
    Svg09,
    Svg110,
    Svg111,
    Svg112,
    Svg113,
    Svg114,
    Svg115,
    Svg116,
    Svg117,
    Svg118,
    Svg119,
    Svg120, Svg169
} from './ConstLines'
/*
  allow: permited combined two botons
  example:  "Implante colocado" and  "Provisional colocado"
            inside of dents content, will see the I and P
*/

/*
  These do not combined with any,
  on the contrary overwrites all of the above
    1)  Healthy tooth
    2)  Anodontia
    3)  Missing tooth
    4)  Extracted tooth
    5)  Lost due to trauma
    6)  Not Erupted
*/

const basic = [
  "diente_sano",
  "anodoncia",
  "diente_ausente",
  "diente_extraido",
  "perdido_por_trauma",
  "sin_erupcionar",
]

const basic_secondary_bottoms = [
  "sector_sano",
  "obturacion",
  "caries",
  "recidiva",
  "resina_colocada",
  "resina_indicada",
  "paciente_clinicamente_sano",
  "erosion",
  "paciente_edentulo",
]

const dentsList=[
  {
    alias:"diente_sano",
    label:"Diente Sano",
    active:Svg01,
    ico:"",
    classNameSelect:"",
    className:"dentsSVGBlue",
    src:Svg1,
    allow:[],
    deny:"all",
    updateAt: new Date()
  },
  {
    alias:"anodoncia",
    label:"Anodoncia",
    active:Svg02,
    ico:"",
    classNameSelect:"",
    className:"dentsSVGGreen",
    src:Svg2,
    allow:[],
    deny:"all",
    updateAt: new Date()
  },
  {
    alias:"diente_ausente",
    label:"Diente para extraer",
    active:Svg03,
    ico:"",
    className:"dentsSVGRed",
    src:Svg12,
    allow:[],
    deny:"all",
    updateAt: new Date()
  },
  {
    alias:"diente_extraido",
    label:"Diente extraido",
    active:Svg04,
    ico:"",
    className:"dentsSVGBlack",
    src:Svg2,
    allow:[],
    deny:"all",
    updateAt: new Date()
  },
  {
    alias:"perdido_por_trauma",
    label:"Perdido por trauma",
    active:Svg05,
    ico:"",
    className:"dentsSVGBlack",
    src:Svg5,
    allow:[],
    deny:"all",
    updateAt: new Date()
  },
  {
    alias:"sin_erupcionar",
    label:"Sin Erupcionar",
    active:Svg06,
    ico:"",
    className:"dentsSVGBlack",
    src:Svg4,
    allow:[],
    deny:"all",
    updateAt: new Date()
  },
  {
    alias:"sellante_colocado",
    label:"Sellante colocado",
    active:Svg07,
    ico:"",
    className:"dentsSVGTextBlue",
    src:Svg6,
    allow:[...basic_secondary_bottoms,

              "sector_sano",
              "obturacion",
              "caries",
              "recidiva",
              "erosion",
              "resina_colocada",
              "resina_indicada"
              ],
    deny:[...basic,
          "carilla_colocada",
          "carilla_indicada",
          "endodoncia_indicada",
          "endodoncia_buena",
          "sellante_indicado",
          "provisional_indicado",
           "Provisional_colocado", "nucleo", "nucleo_colocado"
          ],
    updateAt: new Date().toLocaleString()
  },
  {
    alias:"sellante_indicado",
    label:"Sellante indicado",
    active:Svg08,
    ico:"",
    className:"dentsSVGTextRed",
    src:Svg6,
    allow:[...basic_secondary_bottoms,

                "sector_sano",
                "obturacion",
                "caries",
                "erosion",
                "recidiva",
                "resina_colocada",
                "resina_indicada",],
    deny:[...basic,
        "sellante_indicado",
                "carilla_colocada",
                "carilla_indicada",
                "endodoncia_indicada",
                "endodoncia_buena",
                "sellante_colocado",
                "provisional_indicado",
                "Provisional_colocado", "nucleo", "nucleo_colocado"],
    updateAt: new Date().toLocaleString()
  },
  {
    alias:"Provisional_colocado",
    label:"Provisional colocado",
    active:Svg09,
    ico:"",
    className:"dentsSVGBlue",
    src:Svg7,
    allow:[
            "endodoncia_buena",
            "endodoncia_indicada",
            "sector_sano",
            "obturacion",
            "caries",
            "erosion",
            "recidiva",
            "resina_indicada",
            "resina_colocada",
            "nucleo",
            "nucleo_indicado",
            "implante_colocado",
            "implante_indicado",
          ],
    deny:[  ...basic,"provisional_indicado","corona_colocada", "corona_indicada","carilla_indicada","carilla_colocada", "sellante_indicado", "sellante_colocado"],
      updateAt: new Date()
  },
  {
    alias:"provisional_indicado",
    label:"Provisional indicado",
    active:Svg110,
    ico:"",
    className:"dentsSVGTextRed",
    src:Svg7,
    allow:[ "implante_colocado",
            "implante_indicado",
            "nucleo",
            "nucleo_indicado",
            "endodoncia_buena",
            "endodoncia_indicada",
          ],
    deny:[...basic,"Provisional_colocado","corona_colocada", "corona_indicada","carilla_indicada","carilla_colocada", "sellante_indicado", "sellante_colocado"],
      updateAt: new Date()
  },
  {
    alias:"implante_colocado",
    label:"Implante colocado",
    active:Svg111,
    ico:"",
    className:"dentsSVGBlue",
    src:Svg8,
    allow:[
            "corona_colocada",
            "corona_indicada",
            "Provisional_colocado",
            "provisional_indicado",
            ],
    deny:[...basic,
          ...basic_secondary_bottoms, "implante_indicado","carilla_indicada","carilla_colocada", "endodoncia_indicada","endodoncia_buena","nucleo","nucleo_colocado"],
      updateAt: new Date()
  },
  {
    alias:"implante_indicado",
    label:"Implante indicado",
    active:Svg112,
    ico:"",
    className:"dentsSVGRed",
    src:Svg8,
    allow:[
            "corona_colocada",
            "corona_indicada",
            "Provisional_colocado",
            "provisional_indicado",
          ],
    deny:[...basic,
          ...basic_secondary_bottoms, "implante_colocado","carilla_indicada","carilla_colocada","endodoncia_indicada","endodoncia_buena","nucleo","nucleo_colocado"],
      updateAt: new Date()
  },
  {
    alias:"corona_colocada",
    label:"Corona colocada",
    active:Svg113,
    ico:"",
    className:"dentsSVGBlue",
    src:Svg9,
    allow:[ "implante_colocado",
            "implante_indicado",
            "nucleo",
            "nucleo_indicado",
            "endodoncia_indicada",
            "endodoncia_buena"
          ],
    deny:[...basic,"corona_indicada","provisional_indicado","Provisional_colocado","carilla_indicada","carilla_colocada"],
      updateAt: new Date()
  },
  {
    alias:"corona_indicada",
    label:"Corona indicada",
    active:Svg114,
    ico:"",
    className:"dentsSVGBlue",
    src:Svg10,
    allow:[...basic_secondary_bottoms,
              "nucleo",
              "nucleo_indicado",
              "implante_colocado",
              "implante_indicado",
              "endodoncia_indicada",
              "endodoncia_buena"
          ],
    deny:[...basic,"corona_colocada","provisional_indicado","Provisional_colocado","carilla_indicada","carilla_colocada"],
      updateAt: new Date()
  },
  {
    alias:"endodoncia_buena",
    label:"Endodoncia buena",
    active:Svg115,
    ico:"",
    className:"dentsSVGBlue",
    src:Svg11,
    allow:[...basic_secondary_bottoms,
              "nucleo",
              "nucleo_indicado",
              "provisional_indicado",
              "Provisional_colocado",
              "corona_indicada",
              "corona_colocada"
    ],
    deny:[...basic,"endodoncia_indicada","sellante_indicado", "sellante_colocado","carilla_indicada","carilla_colocada","implante_indicado", "implante_colocado"],
      updateAt: new Date()
  },
  {
    alias:"endodoncia_indicada",
    label:"Endodoncia indicada",
    active:Svg116,
    ico:"",
    className:"dentsSVGRed",
    src:Svg11,
    allow:[...basic_secondary_bottoms,
              "nucleo",
              "nucleo_indicado",
              "provisional_indicado",
              "Provisional_colocado",
              "corona_indicada",
              "corona_colocada"
          ],
    deny:[...basic,"endodoncia_buena","sellante_indicado", "sellante_colocado","carilla_indicada","carilla_colocada","implante_indicado", "implante_colocado"],
      updateAt: new Date()
  },
  {
    alias:"carilla_colocada",
    label:"Carilla colocada",
    active:Svg117,
    ico:"",
    className:"dentsSVGBlue",
    src:SvgCarillaColocada,
    allow:[...basic_secondary_bottoms],
    deny:[...basic,"carilla_indicada","sellante_indicado", "sellante_colocado","implante_indicado","implante_colocado","Provisional_colocado","provisional_indicado","nucleo_indicado","nucleo","corona_colocada", "corona_indicada"],
      updateAt: new Date()
  },
  {
    alias:"carilla_indicada",
    label:"Carilla indicada",
    active:Svg118,
    ico:"",
    className:"dentsSVGBlue",
    src:SvgCarillaIndicada,
    allow:[...basic_secondary_bottoms],
    deny:[...basic,"carilla_colocada","sellante_indicado", "sellante_colocado","implante_indicado","implante_colocado","Provisional_colocado","provisional_indicado","nucleo_indicado","nucleo","corona_colocada", "corona_indicada"],
      updateAt: new Date()
  },
  {
    alias:"nucleo",
    label:"Núcleo",
    active:Svg119,
    ico:"",
    className:"dentsSVGBlue",
    src:SvgNucleoColocado,
    allow:[...basic_secondary_bottoms,
              "provisional_indicado",
              "Provisional_colocado",
              "endodoncia_indicada",
              "endodoncia_buena",
              "corona_colocada",
              "corona_indicada",
            ],
    deny:[...basic,"nucleo_indicado","carilla_indicada","carilla_colocada","sellante_indicado", "sellante_colocado","implante_colocado",
        "implante_indicado"],
      updateAt: new Date()
  },
  {
    alias:"nucleo_indicado",
    label:"Núcleo indicado",
    active:Svg120,
    ico:"",
    className:"dentsSVGBlue",
    src:SvgNucleoIndicado,
    allow:[...basic_secondary_bottoms,
            "provisional_indicado",
            "Provisional_colocado",
            "endodoncia_indicada",
            "endodoncia_buena",
            "corona_colocada",
            "corona_indicada",
          ],
    deny:[...basic,"nucleo","carilla_indicada","carilla_colocada","sellante_indicado", "sellante_colocado" ,"implante_colocado",
        "implante_indicado"],
      updateAt: new Date()
  },
    {
        alias:"fractura",
        label:"Fractura",
        active:Svg169,
        ico:"",
        className:"dentsSVGBlue",
        src:SvgFractura,
        allow:[...basic_secondary_bottoms,
            "provisional_indicado",
            "Provisional_colocado",
            "endodoncia_indicada",
            "endodoncia_buena",
            "corona_colocada",
            "corona_indicada",
            "nucleo_indicado","carilla_indicada","carilla_colocada","sellante_indicado", "sellante_colocado","implante_colocado",
            "implante_indicado"
        ],
        deny:[...basic],
        updateAt: new Date()
    },

    {
        alias:"limpiar_diente",
        label:"Limpiar Diente",
        active:Svg1,
        ico:"",
        className:"dentsSVGBlue",
        src:"",
        allow:[],
        deny:"all",
        updateAt: new Date()
    },
]

export default dentsList
