import http from "./axiosInstance";

/**
 * Create Media
 *
 * @param data
 * @returns {Promise<any>}
 */
export const createMedia = (data) => {
  return http().post("/medias", data);
};

/**
 * Update Media by id
 *
 * @param data
 * @param specialty id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateMedia = (data, id) => {
  return http().put(`/medias/${id}`, data);
};

/**
 * Get all medias
 * @returns {Promise<any>}
 */
export const getAllMedias = () => {
  return http().get("/medias");
};

/**
 * Get Medias admin
 * @returns {Promise<any>}
 */
export const getMediasAdmin = (params = {}) => {
  return http().get("/medias/admin", { params });
};
