import React from "react";
import {useForm} from "react-hook-form";
import CustomErrorMessage from "../../common/CustomErrorMessage";
import CustomTextarea from "../../common/CustomTextarea";
import PropTypes from "prop-types";

const PaymentQuotaForm = ({paymentMethods, specialists, onSubmit}) => {
  const {register, handleSubmit, formState:{ errors }} = useForm();

  return(
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <div className="mb-2">
          <label htmlFor="specialistId" className="mb-1 block text-sm font-medium text-gray-700">
            Profesional
          </label>
          <select
            {...register("specialistId")}
            id="specialistId"
            name="specialistId"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">
              -- Seleccione --
            </option>
            {specialists && specialists.map((specialist) =>
              <option key={specialist?.id} value={specialist?.id}>
                {specialist?.user?.name}
              </option>
            )}
          </select>
          <CustomErrorMessage
            errors={errors}
            name="specialistId"
          />
        </div>
        <div className="mb-2">
          <label htmlFor="paymentMethodId" className="mb-1 block text-sm font-medium text-gray-700">
            Forma de pago*
          </label>
          <select
            {...register("paymentMethodId", {required: 'Requerido'})}
            id="paymentMethodId"
            name="paymentMethodId"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">
              -- Seleccione --
            </option>
            {paymentMethods && paymentMethods.map((method) =>
              <option key={method?.id} value={method?.id}>
                {method?.pm_name}
              </option>
            )}
          </select>
          <CustomErrorMessage
            errors={errors}
            name="paymentMethodId"
          />
        </div>
        <div className="mb-2">
          <CustomTextarea
            {...register("observations")}
            id="observations"
            name="observations"
            label="Observaciones"
          />
        </div>
        <div className="mb-2">
          <button
            type="submit"
            className="btn-secondary-dark"
          >
            Pagar
          </button>
        </div>
      </form>
    </>
  );
}

PaymentQuotaForm.propTypes = {
  paymentMethods: PropTypes.array,
  specialists: PropTypes.array,
  onSubmit: PropTypes.func
};

PaymentQuotaForm.defaultProps = {
  paymentMethods: [],
  specialists: []
}

export default PaymentQuotaForm;
