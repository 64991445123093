import React from "react";
import PropTypes from "prop-types";

const GenericButton = ({ onClick, render, children }) => {
  if (!render) {
    return null
  }
  return (
    <button
      type="button"
      className="btn-secondary-dark"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

GenericButton.propTypes = {
  onClick: PropTypes.func,
  render: PropTypes.bool
}

GenericButton.defaultProps = {
  render: true
}

export default GenericButton;
