import React from "react";

const BasicCard = (props) => {

  return(
    <div className="mt-4 py-4 overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        {props.children}
      </div>
    </div>
  )
}

export default BasicCard;
