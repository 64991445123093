export const CREATE_INSURER_PER = 'create insurer';
export const EDIT_INSURER_PER = 'edit insurer';
export const CREATE_SPECIALTY_PER = 'create specialty';
export const EDIT_SPECIALTY_PER = 'edit specialty';
export const CREATE_PRODUCT_PER = 'create product';
export const EDIT_PRODUCT_PER = 'edit product';
export const SHOW_PRODUCT_PER = 'show product';
export const CREATE_CONFIG_PER = 'create configuration';
export const EDIT_CONFIG_PER = 'edit configuration';
export const CREATE_USER_PER = 'create user';
export const EDIT_USER_PER = 'edit user';
export const SHOW_USER_PER = 'show user';
export const CREATE_MEDIA_PER = 'create media';
export const EDIT_MEDIA_PER = 'edit media';
export const CREATE_REASON_PER = 'create reason consultation';
export const EDIT_REASON_PER = 'edit reason consultation';
export const CREATE_APPOINTMENT = 'create appointment';
export const EDIT_APPOINTMENT = 'edit appointment';
export const SHOW_APPOINTMENTS_PATIENT = 'show appointments patient';
export const SHOW_CLINICAL_HISTORY = 'show clinical history';
export const CREATE_PATIENT = 'create patient';
export const EDIT_PATIENT = 'edit patient';
export const CREATE_CONSULTATION = 'create consultation';
export const EDIT_CONSULTATION = 'edit consultation';
export const CREATE_ORDER = 'create order';
export const EDIT_ORDER = 'edit order';
export const SHOW_PAYMENT_PLAN_ORDER = 'show payment plan order';
export const CREATE_PAYMENT_PLAN_ORDER = 'create payment plan order';
export const EDIT_PAYMENT_PLAN_ORDER = 'edit payment plan order';
export const CREATE_PAYMENT = 'create payment';
export const GENERATE_APPOINTMENTS_REPORT = 'generate appointments report';

export const ACCESS_INSURER_MODULE = 'access insurer module';
export const ACCESS_SCHEDULE_MODULE = 'access schedule module';
export const ACCESS_PATIENT_MODULE = 'access patient module';
export const ACCESS_USER_MODULE = 'access user module';
export const ACCESS_PRODUCT_MODULE = 'access product module';
export const ACCESS_CONFIGURATION_MODULE = 'access configuration module';
export const ACCESS_SPECIALTY_MODULE = 'access specialty module';
export const ACCESS_MEDIA_MODULE = 'access media module';
export const ACCESS_REASON_MODULE = 'access reason consultation module';
export const ACCESS_PATIENT_ORDERS_MODULE = 'access patient orders module';
export const ACCESS_PATIENT_PAYMENTS_MODULE = 'access patient payments module'
export const ACCESS_PATIENT_REPORTS_MODULE = 'access patient reports module';