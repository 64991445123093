import http from './axiosInstance';

/**
 * Create Product
 *
 * @param data
 * @returns {Promise<any>}
 */
export const createProduct = (data) => {
  return http().post('/products', data);
}

/**
 * Update Product by id
 *
 * @param data
 * @param product consultation id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateProduct = (data, id) => {
  return http().put(`/products/${id}`, data);
}

/**
 * Get all Products
 * @returns {Promise<any>}
 */
export const getAllProducts = () => {
  return http().get('/products')
}

/**
 * Get Products admin
 * @returns {Promise<any>}
 */
export const getProductsAdmin = (params = {}) => {
  return http().get('/products/admin', {params})
}

/**
 * Get Product by code
 * @returns {Promise<any>}
 */
export const getProductByCode = (code) => {
  return http().get(`/products/code/${code}`);
}

/**
 * Search products
 * @returns {Promise<any>}
 */
export const searchProducts = (params) => {
  return http().get('products/search', {params});
}
