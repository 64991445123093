import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Webcam from "react-webcam";
import PropTypes from "prop-types";
import moment from "moment";

import CustomErrorMessage from "../common/CustomErrorMessage";
import { patientValidations } from "./PatientUtils";
import TabsHeader from "../common/TabsHeader";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import CustomTextarea from "../common/CustomTextarea";
import CustomTextInput from "../common/CustomTextInput";
import usePatientBalance from "../../hooks/usePatientBalance";
import { getCitiesByState } from "../../http/api/cities";
import PermissionWrapper from "../common/PermissionWrapper";
import { CREATE_PATIENT, EDIT_PATIENT } from "../../constants/permissions";
import {
  ClipboardIcon,
  CreditCardIcon,
  PlusCircleIcon,
  UserIcon,
} from "@heroicons/react/solid";

const tabs = [
  { name: "Datos básicos", href: "#", icon: UserIcon, current: true },
  {
    name: "Información adicional",
    href: "#",
    icon: CreditCardIcon,
    current: false,
  },
  { name: "Antecedentes", href: "#", icon: ClipboardIcon, current: false },
];

const validations = patientValidations;

const PatientForm = (props) => {
  const {
    onSubmit,
    patient,
    patientPhoto,
    documentsTypes,
    states,
    maritalStatus,
    bloodTypes,
    bondingTypes,
    insurers,
    reasonsConsultation,
    medias,
    sports,
    familyPlaces,
  } = props;

  const [cities, setCities] = useState([]);
  const [photoStorage, setPhotoStorage] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm();

  let watchResidenceState = watch("residenceStateId");
  const balance = usePatientBalance(patient.id);

  useEffect(() => {
    setPhotoStorage(patientPhoto);
  }, [patientPhoto]);

  useEffect(() => {
    const getCities = async () => {
      const { data } = await getCitiesByState(watchResidenceState);
      setCities(data);
    };

    if (watchResidenceState) {
      getCities();
    }
  }, [watchResidenceState]);

  useEffect(() => {
    setValue("residenceCityId", patient.pa_residence_city_id);
  }, [cities, patient, setValue]);

  const age = watch("age")
    ? watch("age")
    : patient?.pa_birthday && moment().diff(patient.pa_birthday, "years");

  const photo = watch("photo") ? watch("photo") : photoStorage;
  const webcamRef = useRef(null);
  const capture = useCallback(
    (e) => {
      setValue(
        "photo",
        webcamRef.current ? webcamRef.current.getScreenshot() : null
      );
      e.preventDefault();
    },
    [setValue, webcamRef]
  );

  const changeImage = (e) => {
    setValue("photo", null);
    setPhotoStorage(null);
    e.preventDefault();
  };
  return (
    <>
      <TabsHeader tabs={tabs} current={"Información adicional"} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="grid grid-cols-12 gap-4">
          <div className="bg-white overflow-hidden shadow rounded-lg mt-4 col-span-12 sm:col-span-8 sm:order-first order-last px-4 py-5 sm:p-6 divide-primary overflow-y-auto h-full">
            <div className={tabs && tabs[0].current ? "block" : "hidden"}>
              <div className="pt-1">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-primary">
                    Datos personales
                  </h3>
                </div>
                <div className="mt-6 grid grid-cols-12 gap-y-6 gap-x-4">
                  <div className="col-span-12 md:col-span-6">
                    <label
                      htmlFor="documentTypeId"
                      className="mb-1 block text-sm font-medium text-gray-700"
                    >
                      Tipo de documento *
                    </label>
                    <select
                      id="documentTypeId"
                      name="documentTypeId"
                      {...register(
                        "documentTypeId",
                        validations.documentTypeId
                      )}
                      defaultValue={patient?.pa_document_type_id}
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="">-- Seleccionar --</option>
                      {documentsTypes &&
                        documentsTypes.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.dt_name}
                          </option>
                        ))}
                    </select>
                    <CustomErrorMessage errors={errors} name="documentTypeId" />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <CustomTextInput
                      {...register("document", validations.document)}
                      type="text"
                      name="document"
                      id="document"
                      label="Número de documento *"
                      errors={errors}
                      defaultValue={patient?.pa_document}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <CustomTextInput
                      {...register("name", validations.name)}
                      type="text"
                      name="name"
                      id="name"
                      label="Nombres y apellidos *"
                      errors={errors}
                      defaultValue={patient?.pa_name}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div>
                      <label
                        htmlFor="birthday"
                        className="mb-1 block text-sm font-medium text-gray-700"
                      >
                        Fecha nacimiento *
                      </label>
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            onChange={(e) => {
                              setValue("age", moment().diff(e, "years"));
                              field.onChange(e);
                            }}
                            selected={field.value}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            maxDate={new Date()}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        )}
                        defaultValue={
                          patient?.pa_birthday && new Date(patient?.pa_birthday)
                        }
                        rules={validations.birthday}
                      />
                    </div>
                    {age && (
                      <div className="text-xs text-gray-400 font-semibold pt-1">
                        {age && <span>Edad: {age} años</span>}
                      </div>
                    )}
                    <CustomErrorMessage errors={errors} name="birthday" />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label
                      htmlFor="maritalStatusId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Estado Civil *
                    </label>
                    <div className="mt-1">
                      <select
                        id="maritalStatusId"
                        name="maritalStatusId"
                        {...register(
                          "maritalStatusId",
                          validations.maritalStatusId
                        )}
                        defaultValue={patient?.pa_marital_status_id}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="">-- Seleccionar --</option>
                        {maritalStatus &&
                          maritalStatus.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.ms_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage
                        errors={errors}
                        name="maritalStatusId"
                      />
                    </div>
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      htmlFor="bloodTypeId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tipo de sangre
                    </label>
                    <div className="mt-1">
                      <select
                        id="bloodTypeId"
                        name="bloodTypeId"
                        {...register("bloodTypeId")}
                        defaultValue={patient?.pa_blood_type_id}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="">-- Seleccionar --</option>
                        {bloodTypes &&
                          bloodTypes.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.bt_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage errors={errors} name="bloodTypeId" />
                    </div>
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <CustomTextInput
                      {...register("phone", validations.phone)}
                      type="text"
                      name="phone"
                      id="phone"
                      label="Teléfono Celular"
                      errors={errors}
                      defaultValue={patient?.pa_phone}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register("email", validations.email)}
                      type="email"
                      name="email"
                      id="email"
                      label="Correo Eléctronico"
                      errors={errors}
                      defaultValue={patient?.pa_email}
                    />
                  </div>

                  <div className="col-span-12">
                    <CustomTextInput
                      {...register("occupation", validations.occupation)}
                      type="text"
                      name="occupation"
                      id="occupation"
                      label="Ocupación *"
                      errors={errors}
                      defaultValue={patient?.pa_occupation}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label
                      htmlFor="insurerId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      EPS / Aseguradora *
                    </label>
                    <div className="mt-1">
                      <select
                        id="insurerId"
                        name="insurerId"
                        {...register("insurerId", validations.insurerId)}
                        defaultValue={patient?.pa_insurer_id}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="">-- Seleccionar --</option>
                        {insurers &&
                          insurers.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.in_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage errors={errors} name="insurerId" />
                    </div>
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      htmlFor="bondingTypeId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tipo de vinculación *
                    </label>
                    <div className="mt-1">
                      <select
                        id="bondingTypeId"
                        name="bondingTypeId"
                        {...register(
                          "bondingTypeId",
                          validations.bondingTypeId
                        )}
                        defaultValue={patient?.pa_bonding_type_id}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="">-- Seleccionar --</option>
                        {bondingTypes &&
                          bondingTypes.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.bot_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage
                        errors={errors}
                        name="bondingTypeId"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-8">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-primary">
                    Domicilio
                  </h3>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "residencePhone",
                        validations.residencePhone
                      )}
                      type="text"
                      name="residencePhone"
                      id="residencePhone"
                      label="Teléfono de residencia"
                      errors={errors}
                      defaultValue={patient?.pa_residence_phone}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "residenceAddress",
                        validations.residenceAddress
                      )}
                      type="text"
                      name="residenceAddress"
                      id="residenceAddress"
                      label="Dirección de residencia *"
                      errors={errors}
                      defaultValue={patient?.pa_residence_address}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <div className="mt-1">
                      <label
                        htmlFor="residenceStateId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Departamento
                      </label>
                      <select
                        {...register(
                          "residenceStateId",
                          validations.residenceStateId
                        )}
                        id="residenceStateId"
                        name="residenceStateId"
                        defaultValue={patient?.residence_city?.cit_state_code}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="">-- Seleccionar --</option>
                        {states &&
                          states.map((item) => (
                            <option key={item.sta_code} value={item.sta_code}>
                              {item.sta_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage
                        errors={errors}
                        name="residenceStateId"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <div className="mt-1">
                      <label
                        htmlFor="residenceCityId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Ciudad
                      </label>
                      <select
                        {...register(
                          "residenceCityId",
                          validations.residenceCityId
                        )}
                        id="residenceCityId"
                        name="residenceCityId"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="">-- Seleccionar --</option>
                        {cities &&
                          cities.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.cit_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage
                        errors={errors}
                        name="residenceCityId"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "residenceNeighborhood",
                        validations.residenceNeighborhood
                      )}
                      type="text"
                      name="residenceNeighborhood"
                      id="residenceNeighborhood"
                      label="Barrio"
                      errors={errors}
                      defaultValue={patient?.pa_residence_neighborhood}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-8">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-primary">
                    Persona responsable / Acompañante
                  </h3>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "companionDocument",
                        validations.companionDocument
                      )}
                      type="text"
                      name="companionDocument"
                      id="companionDocument"
                      label="Documento de identidad"
                      errors={errors}
                      defaultValue={
                        patient?.companions?.length > 0
                          ? patient?.companions[0].pc_document
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register("companionName", validations.companionName)}
                      type="text"
                      name="companionName"
                      id="companionName"
                      label="Nombre"
                      errors={errors}
                      defaultValue={
                        patient?.companions?.length > 0
                          ? patient?.companions[0].pc_name
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "companionPhone",
                        validations.companionPhone
                      )}
                      type="text"
                      name="companionPhone"
                      id="companionPhone"
                      label="Teléfono"
                      errors={errors}
                      defaultValue={
                        patient?.companions?.length > 0
                          ? patient?.companions[0].pc_phone
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "companionAddress",
                        validations.companionAddress
                      )}
                      type="text"
                      name="companionAddress"
                      id="companionAddress"
                      label="Dirección"
                      errors={errors}
                      defaultValue={
                        patient?.companions?.length > 0
                          ? patient?.companions[0].pc_address
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "companionRelationship",
                        validations.companionRelationship
                      )}
                      type="text"
                      name="companionRelationship"
                      id="companionRelationship"
                      label="Parentesco"
                      errors={errors}
                      defaultValue={
                        patient?.companions?.length > 0
                          ? patient?.companions[0].pc_relationship
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={tabs && tabs[1].current ? "block" : "hidden"}>
              <div className="pt-1">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-primary">
                    Información Adicional
                  </h3>
                </div>
                <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "businessAddress",
                        validations.businessAddress
                      )}
                      type="text"
                      name="businessAddress"
                      id="businessAddress"
                      label="Dirección oficina"
                      errors={errors}
                      defaultValue={patient?.pa_business_address}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register("businessPhone", validations.businessPhone)}
                      type="text"
                      name="businessPhone"
                      id="businessPhone"
                      label="Teléfono oficina"
                      errors={errors}
                      defaultValue={patient?.pa_business_phone}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="reasonConsultationId"
                      className="mb-1 block text-sm font-medium text-gray-700"
                    >
                      Motivo consulta
                    </label>
                    <select
                      id="reasonConsultationId"
                      name="reasonConsultationId"
                      {...register(
                        "reasonConsultationId",
                        validations.reasonConsultationId
                      )}
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      defaultValue={patient?.pa_reason_consultation_id}
                    >
                      <option value="">-- Seleccionar --</option>
                      {reasonsConsultation &&
                        reasonsConsultation.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.rc_name}
                          </option>
                        ))}
                    </select>
                    <CustomErrorMessage
                      errors={errors}
                      name="reasonConsultationId"
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="mediaId"
                      className="mb-1 block text-sm font-medium text-gray-700"
                    >
                      ¿Cómo se entero?
                    </label>
                    <select
                      id="mediaId"
                      name="mediaId"
                      {...register("mediaId", validations.mediaId)}
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      defaultValue={patient?.pa_media_id}
                    >
                      <option value="">-- Seleccionar --</option>
                      {medias &&
                        medias.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.md_name}
                          </option>
                        ))}
                    </select>
                    <CustomErrorMessage errors={errors} name="mediaId" />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register("referredFrom", validations.referredFrom)}
                      type="text"
                      name="referredFrom"
                      id="referredFrom"
                      label="Referido por"
                      errors={errors}
                      defaultValue={patient?.pa_referred_from}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register("referredPhone", validations.referredPhone)}
                      type="text"
                      name="referredPhone"
                      id="referredPhone"
                      label="Teléfono"
                      errors={errors}
                      defaultValue={patient?.pa_referred_phone}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="familyPlaceId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Lugar que ocupa en la familia
                    </label>
                    <div className="mt-1">
                      <select
                        id="familyPlaceId"
                        name="familyPlaceId"
                        {...register(
                          "familyPlaceId",
                          validations.familyPlaceId
                        )}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        defaultValue={patient?.pa_family_place_id}
                      >
                        <option value="">-- Seleccionar --</option>
                        {familyPlaces &&
                          familyPlaces.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.fp_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage
                        errors={errors}
                        name="familyPlaceId"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="sportId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Deporte
                    </label>
                    <div className="mt-1">
                      <select
                        id="sportId"
                        name="sportId"
                        {...register("sportId", validations.sportId)}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        defaultValue={patient?.pa_sport_id}
                      >
                        <option value="">-- Seleccionar --</option>
                        {sports &&
                          sports.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.sp_name}
                            </option>
                          ))}
                      </select>
                      <CustomErrorMessage errors={errors} name="sportId" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-8">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-primary">
                    Para menores de edad
                  </h3>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-12">
                    <CustomTextInput
                      {...register("motherName", validations.motherName)}
                      type="text"
                      name="motherName"
                      id="motherName"
                      label="Nombre de la madre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 0
                          ? patient?.parents[0].pp_name
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "motherDocument",
                        validations.motherDocument
                      )}
                      type="text"
                      name="motherDocument"
                      id="motherDocument"
                      label="Número documento madre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 0
                          ? patient?.parents[0].pp_document
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register("motherPhone", validations.motherPhone)}
                      type="text"
                      name="motherPhone"
                      id="motherPhone"
                      label="Teléfono madre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 0
                          ? patient?.parents[0].pp_phone
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-12">
                    <CustomTextInput
                      {...register(
                        "motherOccupation",
                        validations.motherOccupation
                      )}
                      type="text"
                      name="motherOccupation"
                      id="motherOccupation"
                      label="Ocupación madre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 0
                          ? patient?.parents[0].pp_occupation
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-12">
                    <CustomTextInput
                      {...register("fatherName", validations.fatherName)}
                      type="text"
                      name="fatherName"
                      id="fatherName"
                      label="Nombre del padre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 1
                          ? patient?.parents[1].pp_name
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register(
                        "fatherDocument",
                        validations.fatherDocument
                      )}
                      type="text"
                      name="fatherDocument"
                      id="fatherDocument"
                      label="Número documento padre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 1
                          ? patient?.parents[1].pp_document
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <CustomTextInput
                      {...register("fatherPhone", validations.fatherPhone)}
                      type="text"
                      name="fatherPhone"
                      id="fatherPhone"
                      label="Teléfono padre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 1
                          ? patient?.parents[1].pp_phone
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:col-span-12">
                    <CustomTextInput
                      {...register(
                        "fatherOccupation",
                        validations.fatherOccupation
                      )}
                      type="text"
                      name="fatherOccupation"
                      id="fatherOccupation"
                      label="Ocupación del padre"
                      errors={errors}
                      defaultValue={
                        patient?.parents?.length > 1
                          ? patient?.parents[1].pp_occupation
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={tabs && tabs[2].current ? "block" : "hidden"}>
              <div>
                <h3 className="text-lg leading-6 font-medium text-primary">
                  Antecedentes
                </h3>
              </div>
              <div className="mt-6">
                <div className="mb-2">
                  <CustomTextarea
                    {...register("diseases")}
                    id="diseases"
                    name="diseases"
                    label="Enfermedades"
                    defaultValue={patient?.clinical_history?.ch_diseases}
                  />
                </div>
                <div className="mb-2">
                  <CustomTextarea
                    {...register("medicines")}
                    id="medicines"
                    name="medicines"
                    label="Medicinas"
                    defaultValue={patient?.clinical_history?.ch_medicines}
                  />
                </div>
                <div className="mb-2">
                  <CustomTextarea
                    {...register("observation")}
                    id="observation"
                    name="observation"
                    label="Notas"
                    defaultValue={patient?.clinical_history?.ch_observation}
                  />
                </div>
                <div className="mb-2">
                  <input
                    {...register("alert")}
                    type="checkbox"
                    id="alert"
                    name="alert"
                    defaultChecked={patient?.clinical_history?.ch_alert}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor="alert" className="ml-2 text-sm text-gray-900">
                    Alerta
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg mt-4 col-span-12 sm:col-span-4">
            <div className="bg-white p-4">
              <label
                htmlFor="balance"
                className="block text-sm font-medium text-gray-700 mt-2"
              >
                Saldo
              </label>
              <div className="mt-1">
                <NumberFormat
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  type="text"
                  name="balance"
                  id="balance"
                  {...register("balance", validations.balance)}
                  /*value={values.prPrice}
                  onValueChange={(values) => {
                    const { value } = values;
                    // setFieldValue('prPrice', value);
                  }}*/
                  placeholder="Saldo"
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={CustomTextInput}
                  value={balance}
                  readOnly={true}
                />
                <CustomErrorMessage errors={errors} name="status" />
              </div>
              <div className="mt-2">
                {photo ? (
                  <>
                    <img
                      src={photo}
                      alt="Foto usuario"
                      className="rounded-md"
                    />
                    <button
                      type="button"
                      className="px-3 py-1 border shadow-sm text-xs font-bold text-white bg-primary w-full rounded-md mt-2 justify-center"
                      onClick={changeImage}
                    >
                      Cambiar imagen
                    </button>
                  </>
                ) : (
                  <>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={250}
                      height={250}
                      className="rounded-md"
                    />
                    <CustomErrorMessage errors={errors} name="photo" />
                    <button
                      className="px-3 py-1 border shadow-sm text-xs font-bold text-white bg-primary w-full rounded-md mt-2 justify-center"
                      onClick={capture}
                    >
                      Tomar foto
                    </button>
                  </>
                )}
              </div>
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-700 mt-2"
              >
                Estado
              </label>
              <div className="mt-1">
                <select
                  id="status"
                  name="status"
                  {...register("status", validations.status)}
                  defaultValue={patient?.pa_status}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="ACTIVE">Activo</option>
                  <option value="DISABLED">Desactivado</option>
                </select>
                <CustomErrorMessage errors={errors} name="status" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center pt-4">
          <PermissionWrapper
            permission={patient?.id ? EDIT_PATIENT : CREATE_PATIENT}
          >
            <button type="submit" className="btn-secondary-dark">
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              {patient?.id ? "Actualizar" : "Crear"}
            </button>
          </PermissionWrapper>
        </div>
      </form>
    </>
  );
};

PatientForm.propTypes = {
  documentsTypes: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  maritalStatus: PropTypes.array.isRequired,
  bloodTypes: PropTypes.array.isRequired,
  bondingTypes: PropTypes.array.isRequired,
  insurers: PropTypes.array.isRequired,
};

export default PatientForm;
