import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { readFileAsync } from "../../helpers/utils";
import CustomTextInput from "../common/CustomTextInput";
import CustomTextarea from "../common/CustomTextarea";
import PropTypes from "prop-types";

const ConfigForm = ({ config, onSubmit, createPermission, editPermission }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [logoDataUrl, setLogoDataUrl] = useState("");

  useEffect(() => {
    reset();
  }, [config, reset]);

  const imageChange = async (e) => {
    if (e.target.files[0]) {
      const dataUrl = await readFileAsync(e.target.files[0]);
      setLogoDataUrl(dataUrl);
    }
  };

  const submit = (formData) => {
    formData["logoDataUrl"] = logoDataUrl;
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="px-4 py-5 space-y-6 sm:p-6">
        <div className="flex items-stretch space-x-6">
          <img
            src={logoDataUrl ? logoDataUrl : config?.urlLogo}
            className="w-40"
            alt=""
          />
          <div>
            <label
              htmlFor="logo"
              className="mb-1 block text-sm font-medium text-gray-700"
            >
              Logo*
            </label>
            <input
              {...register("logo")}
              type="file"
              name="logo"
              id="logo"
              onChange={imageChange}
              accept=".png,.jpg,.jpeg"
            />
          </div>
        </div>
        <div>
          <CustomTextInput
            {...register("name", { required: "Requerido" })}
            type="text"
            name="name"
            id="name"
            label="Nombre Consultorio*"
            errors={errors}
            defaultValue={config?.cf_name}
          />
        </div>
        <div>
          <CustomTextInput
            {...register("nit", { required: "Requerido" })}
            type="text"
            name="nit"
            id="nit"
            label="NIT*"
            errors={errors}
            defaultValue={config?.cf_nit}
          />
        </div>
        <div>
          <CustomTextInput
            {...register("phone")}
            type="text"
            name="phone"
            id="phone"
            label="Teléfono"
            errors={errors}
            defaultValue={config?.cf_phone}
          />
        </div>
        <div>
          <CustomTextInput
            {...register("address")}
            type="text"
            name="address"
            id="address"
            label="Dirección"
            errors={errors}
            defaultValue={config?.cf_address}
          />
        </div>
        <div>
          <CustomTextarea
            {...register("invoiceFooter")}
            id="invoiceFooter"
            name="invoiceFooter"
            label="Pie de pagina facturas"
            defaultValue={config?.cf_invoice_footer}
          />
        </div>
        <div className="flex justify-end">
          {((editPermission && config?.id) ||
            (!config?.id && createPermission)) && (
            <button type="submit" className="btn-secondary-dark">
              Guardar
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

ConfigForm.propTypes = {
  config: PropTypes.object,
  onSubmit: PropTypes.func,
  createPermission: PropTypes.bool,
  editPermission: PropTypes.bool,
};

ConfigForm.defaultProps = {
  editPermission: true,
  createPermission: true,
};

export default ConfigForm;
