import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import PageTitle from "../components/common/PageTitle";
import { ClipboardListIcon, PlusCircleIcon } from "@heroicons/react/solid";
import CustomModalContent from "../components/common/CustomModalContent";
import ProductsTable from "../components/products/ProductsTable";
import ProductForm from "../components/products/ProductForm";
import ProductsFiltersForm from "../components/products/ProductsFiltersForm";
import useCheckPermission from "../hooks/useCheckPermission";
import GenericButton from "../components/common/GenericButton";
import BasicCard from "../components/common/BasicCard";
import CustomPagination from "../components/common/CustomPagination";
import { useDispatch } from "react-redux";
import { setLoaderStatus } from "../redux/loader/slice";
import { successAlert } from "../helpers/alerts";
import {
  createProduct,
  getProductsAdmin,
  updateProduct,
} from "../http/api/products";
import {
  CREATE_PRODUCT_PER,
  EDIT_PRODUCT_PER,
  SHOW_PRODUCT_PER,
} from "../constants/permissions";

const ProductsPage = ({ location, history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [toggleSearch, setToggleSearch] = useState(false);
  const [products, setProducts] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const createProductPermission = useCheckPermission(CREATE_PRODUCT_PER);
  const editProductPermission = useCheckPermission(EDIT_PRODUCT_PER);
  const showProductPermission = useCheckPermission(SHOW_PRODUCT_PER);

  useEffect(() => {
    const getProducts = async () => {
      let params = queryString.parse(location.search);
      try {
        dispatch(setLoaderStatus(true));
        const {
          data: { data, per_page, current_page, total },
        } = await getProductsAdmin(params);

        setProducts(data);
        setActivePage(current_page);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getProducts();
  }, [dispatch, location.search, toggleSearch]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setProduct({});
    setOpen(true);
  };
  const handleEdit = (product) => {
    setProduct(product);
    setOpen(true);
  };

  const filterSearch = (formData) => {
    let params = queryString.parse(location.search);
    delete params["search_by_name"];
    delete params["search_by_code"];
    delete params["page"];

    if (formData["searchBy"] && formData["filter"]) {
      params[formData["searchBy"]] = formData["filter"];
    }
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });

    setToggleSearch((prevState) => !prevState);
  };

  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const saveProduct = async (formData) => {
    try {
      dispatch(setLoaderStatus(true));
      if (!product.id) {
        const { message } = await createProduct(formData);
        successAlert(message);
      } else {
        formData.id = product.id;
        const { message } = await updateProduct(formData, product.id);
        successAlert(message);
      }
      handleClose();
      setToggleSearch((prevState) => !prevState);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Productos" Icon={ClipboardListIcon} />
      <BasicCard>
        <div className="pb-4 grid grid-cols-1 md:grid-cols-3">
          <div className="self-end md:mb-0 mb-2 col-span-2">
            <ProductsFiltersForm
              onSubmit={filterSearch}
              schemaValidation={{ filter: {}, searchBy: {} }}
            />
          </div>
          <div className="self-end flex justify-end">
            <GenericButton
              onClick={handleOpen}
              render={createProductPermission}
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Crear
            </GenericButton>
            <CustomModalContent
              open={open}
              onClose={handleClose}
              title={product.id ? "Actualizar Producto" : "Crear Producto"}
              closeOnOverlayClick={false}
            >
              <div className="w-full md:w-72">
                <ProductForm
                  product={product}
                  editPermission={editProductPermission}
                  onSubmit={saveProduct}
                />
              </div>
            </CustomModalContent>
          </div>
        </div>
        <ProductsTable
          products={products}
          showEdit={showProductPermission}
          onEdit={handleEdit}
        />
        <CustomPagination
          totalItemsCount={totalItemsCount}
          onChange={(page) => onChangePagination(page)}
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
        />
      </BasicCard>
    </div>
  );
};

export default withRouter(ProductsPage);
