import Swal from 'sweetalert2'

export function loadingAlert() {
  return Swal.fire({
    background: 'transparent',
    showConfirmButton: false,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading()
    }
  })
}

export function successAlert (title = 'Exitoso') {
  return Swal.fire({
    title,
    icon: 'success',
    showConfirmButton: true
  })
}

export function errorAlert (title = 'Error', html = 'Estamos experimentando problemas, por favor comuníquese con el administrador.') {
  return Swal.fire({
    title,
    html,
    icon: 'error',
    allowOutsideClick: false,
    showConfirmButton: true,
  })
}

export function closeAlert() {
  Swal.close()
}
