import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setLoaderStatus } from "../redux/loader/slice";
import PageTitle from "../components/common/PageTitle";
import { IdentificationIcon, PlusCircleIcon } from "@heroicons/react/solid";
import CustomModalContent from "../components/common/CustomModalContent";
import MediasFiltersForm from "../components/medias/MediasFiltersForm";
import MediaForm from "../components/medias/MediaForm";
import MediasTable from "../components/medias/MediasTable";
import { createMedia, getMediasAdmin, updateMedia } from "../http/api/medias";
import useCheckPermission from "../hooks/useCheckPermission";
import { CREATE_MEDIA_PER, EDIT_MEDIA_PER } from "../constants/permissions";
import GenericButton from "../components/common/GenericButton";
import CustomPagination from "../components/common/CustomPagination";
import BasicCard from "../components/common/BasicCard";
import { successAlert } from "../helpers/alerts";

const MediasPage = ({ location, history }) => {
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState({});
  const [medias, setMedias] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);

  const dispatch = useDispatch();

  const createMediaPermission = useCheckPermission(CREATE_MEDIA_PER);
  const editMediaPermission = useCheckPermission(EDIT_MEDIA_PER);

  useEffect(() => {
    const getMedias = async () => {
      let params = queryString.parse(location.search);
      try {
        dispatch(setLoaderStatus(true));
        const {
          data: { data, per_page, current_page, total },
        } = await getMediasAdmin(params);

        setMedias(data);
        setActivePage(current_page);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getMedias();
  }, [dispatch, location.search, toggleSearch]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setMedia({});
    setOpen(true);
  };
  const handleEdit = (insurer) => {
    setMedia(insurer);
    setOpen(true);
  };

  const filterSearch = (filterData) => {
    let params = queryString.parse(location.search);
    delete params.page;
    params.search_by_string = filterData["searchByString"];
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });

    setToggleSearch((prevState) => !prevState);
  };

  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const saveMedia = async (formData) => {
    const data = {
      name: formData["name"],
      status: formData["status"],
    };

    try {
      dispatch(setLoaderStatus(true));
      if (!media.id) {
        const { message } = await createMedia(data);
        successAlert(message);
      } else {
        data.id = media.id;
        const { message } = await updateMedia(data, media.id);
        successAlert(message);
      }

      handleClose();
      setToggleSearch((prevState) => !prevState);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Medios de comunicación" Icon={IdentificationIcon} />
      <BasicCard>
        <div className="pb-4 grid grid-cols-1 md:grid-cols-3">
          <div className="self-end md:mb-0 mb-2 col-span-2">
            <MediasFiltersForm onSubmit={filterSearch} />
          </div>
          <div className="self-end flex justify-end">
            <GenericButton onClick={handleOpen} render={createMediaPermission}>
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Crear
            </GenericButton>
            <CustomModalContent
              open={open}
              onClose={handleClose}
              title={media.id ? "Actualizar Medio" : "Crear Medio"}
              closeOnOverlayClick={false}
            >
              <div className="w-full md:w-72">
                <MediaForm
                  media={media}
                  editPermission={editMediaPermission}
                  onSubmit={saveMedia}
                />
              </div>
            </CustomModalContent>
          </div>
        </div>

        <MediasTable medias={medias} onEdit={handleEdit} />
        <CustomPagination
          totalItemsCount={totalItemsCount}
          onChange={(page) => onChangePagination(page)}
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
        />
      </BasicCard>
    </div>
  );
};

export default withRouter(MediasPage);
