import React from 'react';
import { useForm } from "react-hook-form";
import {Link} from "react-router-dom";
import {regExpEmail} from "../../helpers/regexp";
import CustomTextInput from "../common/CustomTextInput";

const LoginForm = ({onSubmit}) => {
  const { register, handleSubmit, formState:{ errors } } = useForm();
  return(
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-primary text-center">
          Correo Eléctronico
        </label>
        <div className="mt-1">
          <CustomTextInput
            {...register('email', {
              required: 'Requerido',
              pattern: {value: regExpEmail, message: 'Email invalido'},
            })}
            type="email"
            name="email"
            id="email"
            placeholder="Correo Eléctronico"
            errors={errors}
          />
        </div>
      </div>

      <div className="space-y-1">
        <label htmlFor="email" className="block text-sm font-medium text-primary text-center">
          Contraseña
        </label>
        <div className="mt-1">
          <CustomTextInput
            {...register('password', {required: 'Requerido'})}
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            errors={errors}
          />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {/*<input
              id="remember_me"
              name="remember_me"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
            Recordarme
          </label>*/}
        </div>

        <div className="text-sm">
          <Link to="/forgot-password" className="font-medium text-secondary-dark hover:text-primary">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Iniciar sesión
        </button>
      </div>
    </form>
  )
}

export default LoginForm;
