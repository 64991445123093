import React from "react";
import PropTypes from 'prop-types';

const PageTitle = ({ title, Icon }) => {
  return (
    <div className="text-xl font-medium text-secondary-dark">
      <span className="border-b-2 border-secondary-dark py-2">
        <Icon className="mr-1 h-6 w-6 inline-block align-middle" />
        <h1 className="inline-block align-middle">{title}</h1>
      </span>
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired
}

export default PageTitle;
