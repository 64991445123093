import http from "./axiosInstance";

/**
 * Create Order
 */
export const createOrder = (data) => {
  return http().post("/orders", data);
};

/**
 * Update Order
 */
export const updateOrder = (orderId, data) => {
  return http().put(`/orders/${orderId}`, data);
};

/**
 * Get Patient Orders
 */
export const getPatientOrders = (patientId, params) => {
  return http().get(`/orders/patient/${patientId}`, { params });
};

/**
 * Get Patient Orders for payment
 */
export const getOrdersPayment = (patientId, params) => {
  return http().get(`/orders/payment/patient/${patientId}`, { params });
};

/**
 * Get payments by order id
 */
export const validatePaymentsByOrderId = (orderId) => {
  return http().get(`/orders/validate-payments/${orderId}`);
};

/**
 * Get Order by ID
 */
export const getOrderById = (id) => {
  return http().get(`/orders/${id}`);
};

/**
 * Get Order total by ID
 */
export const getOrderTotal = (id) => {
  return http().get(`/orders/total/${id}`);
};
