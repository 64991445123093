import React from 'react';
import PropTypes from "prop-types";
import moment from "moment";

import Table from "../../common/Table";

const PatientPaymentsTable = ({payments, onPrint}) => {
  if (payments.length < 1) {
    return null
  }

  const numberFormat = new Intl.NumberFormat();
  const columns = [
    {
      Header: 'Factura #',
      accessor: 'id',
    },
    {
      Header: 'Fecha',
      accessor: '[orp_created_at]',
      Cell: ({row: {original}}) => moment(original.orp_created_at).format('YYYY-MM-DD')
    },
    {
      Header: '# Orden',
      accessor: 'orp_order_id'
    },
    {
      Header: 'Concepto',
      accessor: 'payment_concept.pc_name'
    },
    {
      Header: 'Profesional',
      accessor: 'specialist.user.name'
    },
    {
      Header: 'Forma de pago',
      accessor: 'payment_method.pm_name'
    },
    {
      Header: 'Valor',
      accessor: '[orp_amount]',
      Cell: ({row: {original}}) => `$ ${numberFormat.format(original.orp_amount)}`
    },
    {
      Header: '',
      accessor: '[print]',
      Cell: ({row: {original}}) => <div className="text-center">
        <button onClick={() => onPrint(original)} className="text-secondary-dark inline-block font-medium">
          Imprimir
        </button>
      </div>
    }
  ];

  return (
    <div className="table-responsive">
      <Table columns={columns} data={payments}/>
    </div>
  )
}

PatientPaymentsTable.propTypes = {
  payments: PropTypes.array,
  onPrint: PropTypes.func
};

PatientPaymentsTable.defaultProps = {
  payments: []
};

export default PatientPaymentsTable;
