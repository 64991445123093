import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { PlusCircleIcon, UsersIcon } from "@heroicons/react/solid";

import CustomModalContent from "../components/common/CustomModalContent";
import PatientForm from "../components/patients/PatientForm";
import { formatPatientData } from "../components/patients/PatientUtils";
import PageTitle from "../components/common/PageTitle";
import PatientsTable from "../components/patients/PatientsTable";
import PatientFilterForm from "../components/patients/PatientFilterForm";
import { getListsPatient } from "../http/api/config";
import BasicCard from "../components/common/BasicCard";
import CustomPagination from "../components/common/CustomPagination";
import { setLoaderStatus } from "../redux/loader/slice";
import { successAlert } from "../helpers/alerts";
import {
  createPatient,
  getAllPatients,
  getPatientPhoto,
  updatePatient,
} from "../http/api/patient";
import { CREATE_PATIENT } from "../constants/permissions";
import PermissionWrapper from "../components/common/PermissionWrapper";

const PatientsPage = ({ location, history }) => {
  const [documentsTypes, setDocumentsTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [bloodTypes, setBloodTypes] = useState([]);
  const [bondingTypes, setBondingTypes] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [insurers, setInsurers] = useState([]);
  const [sports, setSports] = useState([]);
  const [familyPlaces, setFamilyPlaces] = useState([]);
  const [reasonsConsultation, setReasonsConsultation] = useState([]);
  const [medias, setMedias] = useState([]);
  const [patient, setPatient] = useState({});
  const [patientPhoto, setPatientPhoto] = useState(null);
  const [patients, setPatients] = useState([]);
  const [openModalPatient, setOpenModalPatient] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [toggleSearch, setToggleSearch] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getPatients = async () => {
      let params = queryString.parse(location.search);
      try {
        dispatch(setLoaderStatus(true));
        const {
          data: { data, per_page, current_page, total },
        } = await getAllPatients(params);
        setPatients(data);
        setActivePage(current_page);
        setItemsCountPerPage(per_page);
        setTotalItemsCount(total);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoaderStatus(false));
      }
    };

    getPatients();
  }, [dispatch, location.search, toggleSearch]);

  useEffect(() => {
    getListsData();
  }, []);

  const handleCloseModalPatient = () => setOpenModalPatient(false);
  const handleOpenModalPatient = () => {
    setPatient({});
    setOpenModalPatient(true);
  };

  const handleEdit = async (patientData) => {
    handleOpenModalPatient();
    setPatient(patientData);
    const { data } = await getPatientPhoto(patientData.id);
    setPatientPhoto(data);
  };

  const filterSearch = (formData) => {
    let params = queryString.parse(location.search);
    delete params["search_by_name"];
    delete params["search_by_document"];
    delete params["page"];

    if (formData["searchBy"] && formData["filter"]) {
      params[formData["searchBy"]] = formData["filter"];
    }
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });

    setToggleSearch((prevState) => !prevState);
  };

  const onChangePagination = (page) => {
    let params = queryString.parse(location.search);
    params.page = page;
    history.push({
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const getListsData = async () => {
    const {
      data: {
        documentsTypes,
        states,
        maritalStatus,
        bloodTypes,
        insurers,
        sports,
        familyPlaces,
        reasonsConsultations,
        medias,
        bondingTypes,
      },
    } = await getListsPatient();
    setDocumentsTypes(documentsTypes);
    setStates(states);
    setMaritalStatus(maritalStatus);
    setBloodTypes(bloodTypes);
    setInsurers(insurers);
    setSports(sports);
    setFamilyPlaces(familyPlaces);
    setReasonsConsultation(reasonsConsultations);
    setMedias(medias);
    setBondingTypes(bondingTypes);
  };

  const savePatient = async (formData) => {
    try {
      dispatch(setLoaderStatus(true));
      const data = formatPatientData(formData);
      if (!patient.id) {
        const { message } = await createPatient(data);
        successAlert(message);
      } else {
        const { message } = await updatePatient(data, patient.id);
        successAlert(message);
      }

      handleCloseModalPatient();
      setToggleSearch((prevState) => !prevState);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

  return (
    <div className="py-4 max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <PageTitle title="Pacientes" Icon={UsersIcon} />
      <BasicCard>
        <div className="pb-4 grid grid-cols-1 md:grid-cols-3">
          <div className="self-end md:mb-0 mb-2 col-span-2">
            <PatientFilterForm
              onSubmit={filterSearch}
              schemaValidation={{ filter: {}, searchBy: {} }}
            />
          </div>
          <div className="self-end flex justify-end">
            <PermissionWrapper permission={CREATE_PATIENT}>
              <button
                type="button"
                className="btn-secondary-dark"
                onClick={handleOpenModalPatient}
              >
                <PlusCircleIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Crear
              </button>
            </PermissionWrapper>
            <CustomModalContent
              open={openModalPatient}
              onClose={handleCloseModalPatient}
              title={patient?.id ? "Actualizar paciente" : "Nuevo paciente"}
              closeOnOverlayClick={false}
            >
              <PatientForm
                onSubmit={savePatient}
                patient={patient}
                patientPhoto={patientPhoto}
                states={states}
                documentsTypes={documentsTypes}
                maritalStatus={maritalStatus}
                bloodTypes={bloodTypes}
                bondingTypes={bondingTypes}
                insurers={insurers}
                reasonsConsultation={reasonsConsultation}
                sports={sports}
                familyPlaces={familyPlaces}
                medias={medias}
              />
            </CustomModalContent>
          </div>
        </div>
        <PatientsTable patients={patients} onEdit={handleEdit} />
        <CustomPagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onChange={(page) => onChangePagination(page)}
        />
      </BasicCard>
    </div>
  );
};

export default withRouter(PatientsPage);
