import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import CustomTextInput from "../../common/CustomTextInput";
import CustomErrorMessage from "../../common/CustomErrorMessage";
import { PlusCircleIcon, SearchIcon } from "@heroicons/react/solid";
import CustomModalContent from "../../common/CustomModalContent";
import ProductBrowser from "../../products/ProductBrowser";

const OrderItemForm = ({ discountTypes, onSubmit }) => {
  const [openBrowserModal, setOpenBrowserModal] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName: "",
      unitValue: 0,
      quantity: 0,
      subtotal: 0,
      discountType: "",
      formatDiscount: 0,
      total: 0,
    },
  });
  const watchDiscountType = watch("discountType", "");
  const watchUnitValue = watch("unitValue", 0);
  const watchQuantity = watch("quantity", 0);
  const watchSubtotal = watch("subtotal", 0);
  const watchDiscount = watch("formatDiscount", 0);
  const watchTotal = watch("total", 0);

  const handleOpenBrowserModal = () => setOpenBrowserModal(true);
  const handleCloseBrowserModal = () => setOpenBrowserModal(false);

  const onSelectProduct = (data) => {
    setValue("productId", data.id);
    setValue("productName", data.pr_name);
    setValue("unitValue", data.pr_value);
    setValue("quantity", 1);
    setValue("discount", 0);

    handleCloseBrowserModal();
  };

  useEffect(() => {
    const calculateSubTotal = () => {
      const { unitValue, quantity } = getValues();
      const subTotal = Number(unitValue) * Number(quantity);
      setValue("subtotal", subTotal ? subTotal : 0);
    };

    calculateSubTotal();
  }, [getValues, setValue, watchUnitValue, watchQuantity]);

  useEffect(() => {
    const calculateTotal = () => {
      const { discount, subtotal, discountType } = getValues();
      let discountValue = discount;
      let total = subtotal;
      if (total > 0 && discount > 0) {
        if (discountType === "porcentaje") {
          discountValue = (Number(subtotal) * Number(discount)) / 100;
          total = Number(subtotal) - discountValue;
        } else if (discountType === "valor") {
          total = Number(subtotal) - Number(discount);
        }
      }

      setValue("discountApplied", discountValue);
      setValue("total", total);
    };

    calculateTotal();
  }, [getValues, setValue, watchDiscountType, watchSubtotal, watchDiscount]);

  return (
    <>
      <CustomModalContent
        title="Buscar Producto"
        open={openBrowserModal}
        onClose={handleCloseBrowserModal}
        closeOnOverlayClick={false}
      >
        <div className="w-full md:max-w-7xl">
          <ProductBrowser onSelectProduct={onSelectProduct} />
        </div>
      </CustomModalContent>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <label
            htmlFor="name"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Producto
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              {...register("productName", { required: "Requerido" })}
              type="text"
              name="productName"
              id="productName"
              className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
              onClick={handleOpenBrowserModal}
              readOnly={true}
            />
            <span
              onClick={handleOpenBrowserModal}
              className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
            >
              <SearchIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </div>
          <CustomErrorMessage errors={errors} name="productName" />
        </div>
        <div className="mb-2">
          <Controller
            render={({ field }) => (
              <NumberFormat
                {...field}
                thousandSeparator={true}
                customInput={CustomTextInput}
                label="Valor Unitario"
                prefix={"$ "}
                readOnly={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue("unitValue", value);
                }}
              />
            )}
            name="unitValue"
            id="unitValue"
            control={control}
          />
        </div>
        <div className="mb-2">
          <Controller
            render={({ field }) => (
              <NumberFormat
                {...field}
                customInput={CustomTextInput}
                label="Cantidad*"
                onValueChange={(values) => {
                  const { value } = values;
                  setValue("quantity", value);
                }}
                errors={errors}
              />
            )}
            name="quantity"
            id="quantity"
            control={control}
            rules={{
              required: "Requerido",
              min: { value: 1, message: "Mínimo 1" },
            }}
          />
        </div>
        <div className="mb-2">
          <Controller
            render={({ field }) => (
              <NumberFormat
                {...field}
                thousandSeparator={true}
                customInput={CustomTextInput}
                label="Subtotal"
                prefix={"$ "}
                readOnly={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue("subtotal", value);
                }}
              />
            )}
            name="subtotal"
            id="subtotal"
            control={control}
          />
        </div>
        <div className="mb-2">
          <label
            htmlFor="discountType"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Tipo de descuento
          </label>
          <select
            {...register("discountType")}
            id="discountType"
            name="discountType"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option key="" value="">
              -- Ninguno --
            </option>
            {discountTypes &&
              discountTypes.map((type) => (
                <option key={type?.key} value={type?.key}>
                  {type?.name}
                </option>
              ))}
          </select>
          <CustomErrorMessage errors={errors} name="discountType" />
        </div>
        {watchDiscountType !== "" && (
          <div className="mb-2">
            <Controller
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  label="Valor"
                  thousandSeparator={true}
                  prefix={watchDiscountType !== "porcentaje" ? "$ " : ""}
                  suffix={watchDiscountType !== "porcentaje" ? "" : " %"}
                  customInput={CustomTextInput}
                  errors={errors}
                  onValueChange={(values) => {
                    const { value } = values;
                    setValue("discount", value);
                  }}
                />
              )}
              name="formatDiscount"
              id="formatDiscount"
              control={control}
              rules={{
                validate: {
                  checkLimit: (value) => {
                    return watchTotal >= 0 || "El descuento es muy alto!";
                  },
                },
              }}
            />
          </div>
        )}
        <div className="mb-2">
          <Controller
            render={({ field }) => (
              <NumberFormat
                {...field}
                thousandSeparator={true}
                customInput={CustomTextInput}
                label="Total"
                prefix={"$ "}
                readOnly={true}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue("total", value);
                }}
              />
            )}
            name="total"
            id="total"
            control={control}
          />
        </div>
        <div className="mb-2">
          <button type="submit" className="btn-secondary-dark">
            <PlusCircleIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            Agregar
          </button>
        </div>
      </form>
    </>
  );
};

OrderItemForm.propTypes = {
  onSubmit: PropTypes.func,
  discountTypes: PropTypes.array,
};

OrderItemForm.defaultProps = {
  discountTypes: [],
};

export default OrderItemForm;
