import http from "./axiosInstance";

/**
 * Create Reason Consultation
 *
 * @param data
 * @returns {Promise<any>}
 */
export const createReasonConsultation = (data) => {
  return http().post("/reasons-consultation", data);
};

/**
 * Update Reason Consultation by id
 *
 * @param data
 * @param reason consultation id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateReasonConsultation = (data, id) => {
  return http().put(`/reasons-consultation/${id}`, data);
};

/**
 * Get all Reason Consultation
 * @returns {Promise<any>}
 */
export const getAllReasonsConsultation = () => {
  return http().get("/reasons-consultation");
};

/**
 * Get Reason Consultation admin
 * @returns {Promise<any>}
 */
export const getReasonsAdmin = (params = {}) => {
  return http().get("/reasons-consultation/admin", { params });
};
